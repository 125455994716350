import React from "react";
import HtmlReady from "./HtmlReady";
import OpinionComponent from "./OpinionComponent";
import Markdown from "react-remarkable";
import { Opinion } from "./Opinion";
import { post } from "./post";
import {
    APIForBlockchain,
    supportedBlockchains,
    keychainForBlockchain,
    sitesForBlockchains,
} from "./blockchains";
import steem from "steem";
import hive from "@hiveio/hive-js";
import blurt from "@blurtfoundation/blurtjs";
import whaleshares from "@whaleshares/wlsjs";

import MarkdownViewer from "./MarkdownViewer";
import Remarkable from "remarkable";

import "./App.css";
import "./PostDisplayComponent.css";
import PostCompositionComponent from "./PostCompositionComponent";
import account from "./account";
const showReplies = true;

const remarkable = new Remarkable({
    html: true, // remarkable renders first then sanitize runs...
    breaks: true,
    linkify: false, // linkify is done locally
    typographer: false, // https://github.com/jonschlinkert/remarkable/issues/142#issuecomment-221546793

    quotes: "\u201c\u201d\u2018\u2019",
});

interface PostDisplayProps extends post {
    steem_username?: string;
    following: null | Array<any>;
    renderFollowingOpinion?: any;
    order?: string;
    onAddOpinion?: (any) => void;
    accounts: Array<account>;
    setURL: (url: string, title: string) => void;
}

enum view_type {
    BARE_VIEW,
    HTML_VIEW,
    RENDERED_VIEW,
}

interface PostDisplayState {
    view_markdown: view_type;
    blockchain_vote: number;
    transient_vote: number;
    error: string;
    vote_count: number;
    in_progress: boolean;
    replies: Array<post>;
    html: string;
    width: number;
    height: number;
    intervalId: any;
}

let count = 4;
class PostDisplayComponent extends React.Component<
    PostDisplayProps,
    PostDisplayState
> {
    private votingAreaRef = React.createRef<HTMLDivElement>();
    private commentsSectionRef = React.createRef<HTMLDivElement>();
    private order: string = "trending";
    private followingVotes: Array<Opinion> = [];
    private postImage: string = "";
    private usePostImage: boolean = false;
    private tags: Array<string> = [];

    private element = document.createElement("div");
    private postRef = React.createRef<HTMLDivElement>();

    calculateSize() {
        if (this.postRef && this.postRef.current) {
            const r = this.postRef.current.getBoundingClientRect();
            const ar = 9 / 16;
            const windowHeight = r.height;
            const proposedIframeWidth: number = Math.floor(r.width);
            const proposedIframeHeight: number = Math.floor(ar * r.width);

            const iframeHeight =
                proposedIframeHeight <= windowHeight
                    ? proposedIframeHeight
                    : windowHeight;
            const iframeWidth =
                (proposedIframeHeight === iframeHeight
                    ? proposedIframeWidth
                    : Math.floor(iframeHeight / ar)) - 5;
            return {
                width: iframeWidth < 0 ? 0 : iframeWidth,
                height: iframeHeight < 0 ? 0 : iframeHeight,
            };
        } else {
            throw new Error("Cannot get size");
        }
    }

    handleResize(e) {
        try {
            const s = this.calculateSize.bind(this)();
            //console.log("resize handler called.  Current state size is ", {width: this.state.width, height:this.state.height}, " calculated size is ", s);
            if (
                s.width !== this.state.width ||
                s.height !== this.state.height
            ) {
                console.log("updating to", s);
                const html = HtmlReady(this.state.html, {
                    mutate: true,
                    hideImages: false,
                    width: s.width,
                    height: s.height,
                }).html;
                this.setState({ ...s, html });
            } else {
                //console.log("No change in size.");
            }
        } catch (except) {}
    }

    handleReplies(bc, error, result) {
        if (error) {
            if (count) {
                --count;
                // happens normally when a reply is posted and this software tries to reload it.
                console.log("Error loading replies" + JSON.stringify(error));
            }
            return;
        }

        result = result.map(function (p) {
            p.blockchains = [bc];
            return p;
        });
        if (this.state.replies)
            this.setState({ replies: [...this.state.replies, ...result] });
        else this.setState({ replies: result });
    }

    static maybeUpdate() {
        steem.api.getAccountHistory("leprechaun", -1, 9, function (
            error,
            result
        ) {
            result = result.filter((x) => x[1].op[0] !== "vote");
            console.log("maybeUpdate:", result);
        });
    }

    constructor(props) {
        super(props);
        this.tags = [];
        try {
            const json_metadata = JSON.parse(props.json_metadata);
            if (json_metadata["image"]) {
                this.postImage = json_metadata["image"][0];
                this.usePostImage = true;
            }
            // const format = json_metadata['format'];
            // format would be == 'markdown' or "markdown+html" most of the time.
            // sometimes it is not even present.
            const tags = json_metadata["tags"];
            if (!props.order || props.order === "") this.order = "trending";
            else this.order = props.order;

            let your_vote: number = 0;
            if (props.active_votes) {
                for (const fd of props.active_votes) {
                    if (fd.voter === props.steem_username) {
                        your_vote = fd.percent;
                    }
                    if (props.following)
                        for (const follow of props.following) {
                            if (fd.voter === follow) {
                                this.followingVotes.push(fd);
                            }
                        } // if for
                } // for
            } // if

            if (props.active_votes === null) {
                console.log("Active votes could not be loaded...");
            }

            if (!props.following) {
                console.log("Following could not be loaded");
            }
            let HtmlReadyOutput = this.props.body;
            if (!this.props.body.startsWith("<html"))
                HtmlReadyOutput = remarkable.render(this.props.body);
            HtmlReadyOutput = HtmlReady(HtmlReadyOutput, {
                mutate: true,
                hideImages: false,
                width: 16,
                height: 9,
            }).html;

            this.state = {
                blockchain_vote: your_vote,
                transient_vote: your_vote,
                in_progress: false,
                error: "",
                replies: this.props.replies,
                view_markdown: view_type.RENDERED_VIEW,
                // see setBlockchainVoteCallBack for why I have vote_count.
                vote_count: props.active_votes.length,
                html: HtmlReadyOutput,
                width: Math.floor(window.innerWidth / 2),
                height: Math.floor(window.innerHeight / 2),
                intervalId: 0,
            };
            if (tags && tags.map) this.tags = tags;
        } catch (e) {
            console.log("Exception thrown!  " + e.message);
            this.state = {
                blockchain_vote: 0,
                transient_vote: 0,
                in_progress: false,
                error: "Could not load data from blockchain",
                replies: this.props.replies,
                view_markdown: view_type.RENDERED_VIEW,
                // see setBlockchainVoteCallBack for why I have vote_count.
                vote_count: props.active_votes.length,
                intervalId: 0,
                html: "",
                width: 16,
                height: 9,
            };
        }
        this.addTenPercent = this.addTenPercent.bind(this);
        this.subtractTenPercent = this.subtractTenPercent.bind(this);
        this.setBlockchainVoteCallBack = this.setBlockchainVoteCallBack.bind(
            this
        );
        this.setBlockchainVote = this.setBlockchainVote.bind(this);
        this.setTransientVote = this.setTransientVote.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    setBlockchainVoteCallBack(blockchain: string, error: {code: number, message: string, data: unknown}|string|null, result : null|{ id: string, block_num: number, trx_num: number, expired: boolean} ) {
        if (error) {
            console.log(error);
            if (typeof error !== 'string') {

              error = (error  as {code: number, message: string, data: unknown}).message;

            }
            if (
            	error['indexOf'] && (
                error.indexOf("Transaction is a duplicate") !== -1 ||
                error.indexOf("Duplicate transaction check failed") !== -1 ||
                error.indexOf(
                    "Your current vote on this comment is identical to this vote."
                ) !== -1)
            ) {
                // These error conditions are as good as a success but we wont
                // have a proper result value.
                this.setState({
                    error: "",
                    in_progress: false,
                    blockchain_vote: this.state.transient_vote,
                });
                return;
            }
            this.setState({
                error: error,
                in_progress: false,
                transient_vote: this.state.blockchain_vote,
            });
            console.log("setBlockchainVoteCallBack:", error);
        } else {
            const ops = this.props.active_votes.filter(
                (op) => op.voter === this.props.steem_username
            );
            if (result == null) {
                this.setState({
                    error: "",
                    in_progress: false,
                    transient_vote: this.state.blockchain_vote,
                });
                return;
            } else if (ops.length == 0 && result && result["id"]) {
                // Example result:
                // {id: "3ebf00d0cd32c2bc2f315ffaeb58cff0b1391aab", block_num: 53411426,
                // trx_num: 14, expired: false, ref_block_num: 65102, …}
                // block_num: 53411426
                // expiration: "2021-04-28T03:47:57"
                // expired: false
                // extensions: []
                // id: "3ebf00d0cd32c2bc2f315ffaeb58cff0b1391aab"
                // operations: [Array(2)]
                // ref_block_num: 65102
                // ref_block_prefix: 1407909127
                // signatures: ["204bc4e1ef6b3672044a01e3683408e3113a3e27901f48ea01…0fc1e083ab83f49f830b6c76e629f470ee7537cb10604acd9"]
                // trx_num: 14
                console.log(result);

                if (this.props.onAddOpinion) {
                    this.props.onAddOpinion(result);
                    //} else {
                    //	this.setState({
                    //		in_progress: false,
                    //		blockchain_vote: this.state.transient_vote,
                    //		error:
                    //			this.state.error +
                    //			" Vote on " +
                    //			blockchain +
                    //			" Transaction ID:" +
                    //			result.id,
                    //		vote_count: this.state.vote_count + 1,
                    //	});
                }
            } else {
                this.setState({
                    in_progress: false,
                    blockchain_vote: this.state.transient_vote,
                    error:
                        this.state.error +
                        " Vote " +
                        blockchain +
                        " Transaction ID:" +
                        result.id,
                });
            }
            //this.setState({'error': JSON.stringify(result)});
        }
    }

    setBlockchainVote(e): void {
        const { accounts } = this.props;

        if (this.state.transient_vote === this.state.blockchain_vote) {
          return;
        }
        const whalevault : unknown = window['whale_vault'] || window['whalevault'];

        try {
            let key;
            let id;
            this.setState({ in_progress: true });
            if (this.props.steem_username === undefined) {
                this.setState({ error: "Cannot vote username is not defined", in_progress: false });
                return;
            }
            console.log(
                "voter:",
                this.props.steem_username,
                "author:",
                this.props.author,
                "permlink:",
                this.props.permlink
            );
            for (const account of this.props.accounts) {
              const {keyStorageLocation, role, username, provider} = account;
              if (!this.props.blockchains.includes(provider)) {
                continue;
              }
              const handler = this.setBlockchainVoteCallBack.bind(
                  this,
                  provider
              );
              if ((keyStorageLocation === 'sessionStorage')) {
                key = sessionStorage.getItem(provider + "-private-key");
                APIForBlockchain(provider).broadcast.vote(
                  key,
                  username,
                  this.props.author,
                  this.props.permlink,
                  this.state.transient_vote,
                  handler
                );
              } else if ( keyStorageLocation === 'keychain' ) {
                keychainForBlockchain(provider).requestVote(
                  username, // account
                  this.props.permlink,
                  this.props.author,
                  this.state.transient_vote,
                  function (argument) {
                      if (argument["success"]) {
                          handler(argument["error"], argument["result"]);
                      } else {
                          handler("", null);
                      }
                  }
                );
            } else if (keyStorageLocation === 'whalevault') {
                const ops = [
                  ['vote',
                   { voter: username,
                     author: this.props.author,
                     permlink: this.props.permlink,
                     weight: this.state.transient_vote,
                   }
                  ]
                ];
                const bc = supportedBlockchains().find( bc => {
                    console.log(bc);
                    return (bc.name === provider);

                }
                );
                if (bc === null) {
                  console.log(bc);
                  throw {message: "internal error: in implementation"};
                }

                const url = (()=>{
                    if (provider === 'whaleshares') {
                      return 'https://pubrpc.whaleshares.io';
                    } else if (provider === 'steem') {
                      const rpcURLs = [
                        "https://api.steemit.com",
                        "https://steemyy.com/node/",
                        "https://api.dlike.io",
                        "https://api.steems.top",
                        "https://cn.steems.top",
                        "https://api.steemyy.com",
                        "https://api.justyy.com",
                        "https://api.steemitdev.com",
                        "https://fullsteem.3dkrender.com",
                        "https://api.cotina.org",
                        "https://api.steem.buzz",
                        "https://api.wherein.io",
                        "https://api.steemzzang.com",
                        "https://steem.61bts.com",
                        "https://api.steem.fans",
                        "https://steem.justyy.workers.dev",
                        "https://api.steem-fanbase.com",
                        "https://steemd.steemworld.org",
                        "https://steemapi.3dkrender.com",
                        "https://steemapi.boylikegirl.club",
                        "https://steem.ecosynthesizer.com",
                        "https://justyy.azurewebsites.net/api/steem"
                      ];
                      return rpcURLs[Math.trunc(Math.random()*rpcURLs.length)]
                    } else if (provider === 'hive') {
                      const rpcURLs = [
                          "https://api.hive.blog",
                          "https://api.openhive.network",
                          "https://anyx.io",
                          "https://hived.privex.io",
                          "https://rpc.ausbit.dev",
                          "https://techcoderx.com",
                          "https://rpc.ecency.com",
                          "https://hive.roelandp.nl",
                          "https://hived.emre.sh",
                          "https://api.deathwing.me",
                          "https://api.c0ff33a.uk",
                          "https://hive-api.arcange.eu",
                          "https://api.pharesim.me"];
                      return rpcURLs[Math.trunc(Math.random()*rpcURLs.length)]
                    } else if (provider === 'blurt') {
                      const rpcURLs = ["rpc.blurt.world",
                          "https://api.blurt.blog",
                          "https://blurtd.privex.io",
                          "https://rpc.blurt.buzz",
                          "https://rpc.blurtworld.com"];
                      return rpcURLs[Math.trunc(Math.random()*rpcURLs.length)];
                    }
                    return null;
                })();

                if (url === null) {
                  throw {message: "internal error:cannot get URL for RPC node"};
                }

                console.log({url});

                // @ts-ignore
                whalevault.requestSignBuffer('steemfiles', bc.username_prefix + ':' + username,
                                           { url, operations: ops },
                                           'Posting', 'vote', 'tx',
                                           function(response) {
                                             const {error, success, message, result} = response;
                                             console.log(response);
                                             handler(error ? message : "", result);
                                           }
                );
              }//  if keyStorageLocation ...
            } // for
        } catch (e) {
          console.log(e.message, {e});
          this.setState({ in_progress: false, error: e.message,
          transient_vote: this.state.blockchain_vote});
        }
    }

    addTenPercent(event: any): void {
        let temp: number;
        temp = this.state.transient_vote + 1000;
        if (this.state.transient_vote === 10000) return;
        if (temp > 10000) temp = 10000;
        if (temp < -10000) temp = -10000;
        this.setState({ transient_vote: temp });
    }

    prependReply(reply: post): void {
        let new_replies: Array<post> = [...this.state.replies];
        new_replies.unshift(reply);
        this.setState({ replies: new_replies });
        // Scroll to where the new comment will appear.
        if (this.commentsSectionRef && this.commentsSectionRef.current)
            this.commentsSectionRef.current.scrollIntoView(true);
    }

    subtractTenPercent(event: any): void {
        let temp: number;
        temp = this.state.transient_vote - 1000;
        if (this.state.transient_vote === -10000) return;
        if (temp > 10000) temp = 10000;
        if (temp < -10000) temp = -10000;
        this.setState({ transient_vote: temp });
    }

    setTransientVote(event): void {
        // It must be an event type
        console.log("Transient vote.  ", this.props.steem_username);
        if (event && event.target) {
            let new_value = event.target.value * 1; // make sure it is a number
            if (new_value !== this.state.blockchain_vote)
                this.setState({ transient_vote: new_value });
        }
    }

    sanitize(unsanitizedHTML: string): string {
        this.element.innerText = unsanitizedHTML;
        var sanitizedHTML = this.element.innerHTML;
        return sanitizedHTML;
    }

    toggleMarkdown(): void {
        this.setState({ view_markdown: (1 + this.state.view_markdown) % 3 });
    }

    setViewMode(z): void {
        this.setState({ view_markdown: z });
    }

    onLoadRepliesClick(e) {}

    onPostButtonClick(e) {}

    componentDidMount() {
        if (showReplies && this.props.children !== 0)
            for (const bc of this.props.blockchains) {
                APIForBlockchain(bc).api.getContentReplies(
                    this.props.author,
                    this.props.permlink,
                    this.handleReplies.bind(this, bc)
                );
            }
        window.addEventListener("resize", this.handleResize, false);
        this.setState({
            intervalId: setInterval(this.handleResize.bind(this, null), 1000),
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize, false);
    }

    componentDidUpdate(prevProps, prevState, foo) {
        try {
            const { width, height } = this.calculateSize.bind(this)();
            if (this.state.width !== width || this.state.height !== height) {
                const html = HtmlReady(this.state.html, {
                    mutate: true,
                    hideImages: false,
                    width: width,
                    height: height,
                }).html;
                this.setState({ html, width, height });
            }
        } catch (e) {
            // yeah whatever
        }
    }

    render() {
        const version_names = ["Primary", "Secondary", "Tertiary", "Quartiary"];
        let version_index: number = -1;

        const { accounts } = this.props;
        try {
            ++version_index;
            let now = new Date(); // localtime
            let last_update = new Date(this.props.last_update); // utc time
            // try {
            //     const metadata = JSON.parse(this.props.json_metadata);
            //     const format = metadata["format"];
            //     console.log({format});
            // } catch (e) {}
            // The age of the post in milliseconds.
            const diff =
                now.getTime() +
                now.getTimezoneOffset() * 60000 /*utc time */ -
                last_update.getTime();
            // one week in milliseconds
            const vote_window = 7 * 24 * 3600000;
            // if true, its too late to vote (on Hive it's never too late (HF24))
            const too_late = diff > vote_window && !this.props.blockchains.includes("hive");

            return (
                <div
                    key={
                        this.props.permlink +
                        "-" +
                        this.state.width +
                        "x" +
                        this.state.height
                    }
                    ref={this.postRef}
                    className={"PostDisplay"}
                >
                    <h1>{this.props.title}</h1>
                    {!this.props.depth && (
                        <div className="SideBySide">
                            {"blockchain" +
                                (this.props.blockchains.length === 1
                                    ? ""
                                    : "s") +
                                ": "}

                            {supportedBlockchains()
                                .filter((bc) =>
                                    this.props.blockchains.includes(bc.name)
                                )
                                .map((bc) => (
                                    <div key={bc.name} className="SideBySide">
                                        <img src={bc.logo} alt={bc.name} />
                                        {bc.name}
                                    </div>
                                ))}
                        </div>
                    )}
                    <p>
                        by{" "}
                        <span
                            onClick={(e) =>
                                this.props.setURL(
                                    "/@" + this.props.author + "/blog",
                                    "posts by @" + this.props.author
                                )
                            }
                        >
                            @{this.props.author}
                        </span>
                    </p>
                    {this.props.depth === 0 && (
                        <div className="Post-Tag-Row">
                            {this.tags.map((x) => (
                                <span
                                    key={x}
                                    className="Post-Tag-Button"
                                    onClick={(clickEvent) =>
                                        this.props.setURL(
                                            "/" + this.order + "/" + x,
                                            this.order + " posts in " + x
                                        )
                                    }
                                >
                                    {x}
                                </span>
                            ))}
                        </div>
                    )}
                    {this.props.depth === 0 && (
                        <div>
                            Using the {version_names[version_index]} View as{" "}
                            {this.state.view_markdown ===
                            view_type.RENDERED_VIEW
                                ? "Rendered Markdown"
                                : this.state.view_markdown ===
                                  view_type.BARE_VIEW
                                ? "Markdown Source"
                                : "HTML Code"}
                            {this.state.view_markdown !==
                                view_type.BARE_VIEW && (
                                <button
                                    onClick={this.setViewMode.bind(
                                        this,
                                        view_type.BARE_VIEW
                                    )}
                                >
                                    Bare view
                                </button>
                            )}
                            {this.state.view_markdown !==
                                view_type.RENDERED_VIEW && (
                                <button
                                    onClick={this.setViewMode.bind(
                                        this,
                                        view_type.RENDERED_VIEW
                                    )}
                                >
                                    Rendered view
                                </button>
                            )}
                            {this.state.view_markdown !==
                                view_type.HTML_VIEW && (
                                <button
                                    onClick={this.setViewMode.bind(
                                        this,
                                        view_type.HTML_VIEW
                                    )}
                                >
                                    HTML view
                                </button>
                            )}
                        </div>
                    )}
                    {this.state.view_markdown === view_type.RENDERED_VIEW ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.html,
                            }}
                        ></div>
                    ) : this.state.view_markdown === view_type.BARE_VIEW ? (
                        <div>
                            Meta Data: {this.props.json_metadata}
                            <br />
                            <textarea
                                className="post"
                                defaultValue={this.props.body}
                            ></textarea>
                        </div>
                    ) : (
                        <textarea>{this.state.html}</textarea>
                    )}
                    <hr />
                    {this.props.depth < 2 && (
                        <div>
                            {this.state.vote_count !== 1 ? (
                                <span>
                                    {this.state.vote_count} votes on this post.{" "}
                                </span>
                            ) : (
                                <span>One vote on this post. </span>
                            )}

                            <OpinionComponent
                                steem_username={this.props.steem_username}
                                active_votes={this.props.active_votes}
                                follows={this.props.following}
                                show_self_percent={false}
                            />

                            {this.props.children > 0 ? (
                                this.props.children === 1 ? (
                                    <span>There is 1 comment.</span>
                                ) : (
                                    <span>
                                        There are {this.props.children}{" "}
                                        comments.{" "}
                                    </span>
                                )
                            ) : (
                                "There are no comments.  "
                            )}
                        </div>
                    )}
                    {this.props.depth === 0 && (
                        <div className="Post-Tag-Row">
                            {this.tags.map((x) => (
                                <span
                                    key={x}
                                    className="Post-Tag-Button"
                                    onClick={(clickEvent) =>
                                        this.props.setURL(
                                            "/" + this.order + "/" + x,
                                            this.order + " posts in " + x
                                        )
                                    }
                                >
                                    {x}
                                </span>
                            ))}
                        </div>
                    )}
                    <label className="votingArea">
                        Your Vote value {": "}
                        {too_late ? (
                            this.state.transient_vote / 100 + " %"
                        ) : this.props.steem_username ? (
                            <div>
                                {this.state.in_progress && (
                                    <span>Working...{this.state.error}</span>
                                )}
                                {!this.state.in_progress && !too_late && (
                                    <div
                                        onMouseLeave={this.setBlockchainVote}
                                        className="votingArea"
                                        ref={this.votingAreaRef}
                                    >
                                        {this.props.depth === 0 && (
                                            <button
                                                className="TenPercentButton"
                                                onClick={
                                                    this.subtractTenPercent
                                                }
                                            >
                                                -10% Vote
                                            </button>
                                        )}
                                        <input
                                            className="voteSlider"
                                            type={"range"}
                                            step={100}
                                            min={
                                                too_late
                                                    ? this.state.transient_vote
                                                    : -10000
                                            }
                                            max={
                                                too_late
                                                    ? this.state.transient_vote
                                                    : 10000
                                            }
                                            defaultValue={this.state.transient_vote}
                                            onChange={this.setTransientVote}
                                        />
                                        <div className="PD">
                                            {this.state.transient_vote / 100 +
                                                " %"}
                                        </div>
                                        {this.props.depth === 0 && (
                                            <button
                                                className="TenPercentButton"
                                                onClick={this.addTenPercent}
                                            >
                                                +10% Vote
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            "unknown.  login"
                        )}
                    </label>

                    <PostCompositionComponent
                        key={
                            JSON.stringify(this.props.accounts) +
                            this.props.permlink +
                            this.props.author
                        }
                        blockchains={this.props.blockchains}
                        parent_author={this.props.author}
                        parent_permlink={this.props.permlink}
                        accounts={this.props.accounts}
                        adoptParent={this.prependReply.bind(this)}
                    />
                    {this.state.error}

                    {this.state.replies.length !== 0 && (
                        <div className="SideBySide">
                            <div className="replySlideColumn" />
                            <div ref={this.commentsSectionRef} className="App">
                                {showReplies &&
                                    this.state.replies.map((p) => (
                                        <div
                                            key={
                                                p.permlink +
                                                JSON.stringify(
                                                    this.props.accounts
                                                ) +
                                                p.author
                                            }
                                            className="relative"
                                        >
                                            <PostDisplayComponent

                                                setURL={this.props.setURL}
                                                onAddOpinion={
                                                    this.props.onAddOpinion
                                                }
                                                blockchains={p.blockchains}
                                                steem_username={
                                                    this.props.steem_username
                                                }
                                                renderFollowingOpinion={
                                                    this.props
                                                        .renderFollowingOpinion
                                                }
                                                id={p.id}
                                                order={
                                                    this.props.order ||
                                                    "created"
                                                }
                                                following={this.props.following}
                                                author={p.author}
                                                permlink={p.permlink}
                                                category={p.category}
                                                title={p.title}
                                                body={p.body}
                                                json_metadata={p.json_metadata}
                                                created={p.created}
                                                last_update={p.last_update}
                                                depth={p.depth}
                                                children={p.children}
                                                net_rshares={p.net_rshares}
                                                last_payout={p.last_payout}
                                                cashout_time={p.cashout_time}
                                                total_payout_value={
                                                    p.total_payout_value
                                                }
                                                curator_payout_value={
                                                    p.curator_payout_value
                                                }
                                                pending_payout_value={
                                                    p.pending_payout_value
                                                }
                                                promoted={p.promoted}
                                                replies={p.replies}
                                                body_length={p.body_length}
                                                active_votes={p.active_votes}
                                                author_reputation={
                                                    p.author_reputation
                                                }
                                                parent_author={
                                                    this.props.author
                                                }
                                                parent_permlink={
                                                    this.props.permlink
                                                }
                                                url={p.url}
                                                root_title={p.root_title}
                                                beneficiaries={p.beneficiaries}
                                                max_accepted_payout={
                                                    p.max_accepted_payout
                                                }
                                                percent_steem_dollars={
                                                    p.percent_steem_dollars
                                                }
                                                percent_hbd={p.percent_hbd}
                                                accounts={this.props.accounts}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            );
        } catch (e) {
            console.log(e.message);
            try {
                ++version_index;
                let now = new Date(); // localtime
                let last_update = new Date(this.props.last_update); // utc time

                // should never be negative.
                const diff =
                    now.getTime() +
                    now.getTimezoneOffset() * 60000 /*utc time */ -
                    last_update.getTime();
                const vote_window = 7 * 24 * 3600000;
                const too_late = diff > vote_window;

                const has_hive = this.props.blockchains.includes("hive");
                const has_steem = this.props.blockchains.includes("steem");
                return (
                    <div
                        className={"PostDisplay"}
                        style={{
                            borderColor:
                                has_steem === has_hive
                                    ? "white"
                                    : has_steem
                                    ? "navy"
                                    : "red",
                        }}
                    >
                        <h1>{this.props.title}</h1>
                        {!this.props.depth && (
                            <div className="SideBySide">
                                {"blockchain" +
                                    (this.props.blockchains.length === 1
                                        ? ""
                                        : "s") +
                                    ": "}

                                {supportedBlockchains()
                                    .filter((bc) =>
                                        this.props.blockchains.includes(bc.name)
                                    )
                                    .map((bc) => (
                                        <div className="SideBySide">
                                            <img
                                                src={bc.logo}
                                                alt={bc.name + " logo"}
                                            />
                                            {bc.name}
                                        </div>
                                    ))}
                            </div>
                        )}
                        <p>
                            by{" "}
                            <a href={"/@" + this.props.author}>
                                @{this.props.author}
                            </a>
                        </p>
                        {this.props.depth === 0 && (
                            <div className="Post-Tag-Row">
                                {this.tags.map((x) => (
                                    <a
                                        key={x}
                                        className="Post-Tag-Button"
                                        href={"/" + this.order + "/" + x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                        )}
                        {this.props.depth === 0 && (
                            <div className="AlternativeSites">
                                <b>See Also on:</b>
                                {sitesForBlockchains(
                                    this.props.blockchains
                                ).map((x) => (
                                    <a
                                        key={x}
                                        href={"https://" + x + this.props.url}
                                        target={x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                        )}
                        {this.props.depth === 0 && (
                            <div>
                                Using the {version_names[version_index]} View as{" "}
                                {this.state.view_markdown ===
                                view_type.RENDERED_VIEW
                                    ? "Rendered Markdown"
                                    : this.state.view_markdown ===
                                      view_type.BARE_VIEW
                                    ? "Markdown Source"
                                    : "HTML Code"}
                                {this.state.view_markdown !==
                                    view_type.BARE_VIEW && (
                                    <button
                                        onClick={this.setViewMode.bind(
                                            this,
                                            view_type.BARE_VIEW
                                        )}
                                    >
                                        Bare view
                                    </button>
                                )}
                                {this.state.view_markdown !==
                                    view_type.RENDERED_VIEW && (
                                    <button
                                        onClick={this.setViewMode.bind(
                                            this,
                                            view_type.RENDERED_VIEW
                                        )}
                                    >
                                        Rendered view
                                    </button>
                                )}
                                {this.state.view_markdown !==
                                    view_type.HTML_VIEW && (
                                    <button
                                        onClick={this.setViewMode.bind(
                                            this,
                                            view_type.HTML_VIEW
                                        )}
                                    >
                                        HTML view
                                    </button>
                                )}
                            </div>
                        )}
                        {this.state.view_markdown ===
                        view_type.RENDERED_VIEW ? (
                            <MarkdownViewer
                                className="post"
                                embedVideos={true}
                                text={this.props.body}
                                large={true}
                                highQualityPost={true}
                                noImage={false}
                                allowDangerousHTML={false}
                                hideImages={false}
                                breaks={true}
                            />
                        ) : this.state.view_markdown === view_type.BARE_VIEW ? (
                            <textarea className="post">
                                {this.props.body}
                            </textarea>
                        ) : (
                            <MarkdownViewer
                                className="post"
                                showHTMLSource={true}
                                embedVideos={true}
                                text={this.props.body}
                                large={true}
                                highQualityPost={true}
                                noImage={false}
                                allowDangerousHTML={false}
                                hideImages={false}
                                breaks={true}
                            />
                        )}
                        <hr />
                        <div className="voteBar">
                            {this.state.vote_count !== 1 ? (
                                <span>
                                    {this.state.vote_count} votes on this post.{" "}
                                </span>
                            ) : (
                                <span>One vote on this post. </span>
                            )}
                            {this.props.renderFollowingOpinion &&
                                this.props.renderFollowingOpinion(
                                    this.followingVotes
                                )}
                            {this.props.children > 0 ? (
                                this.props.children === 1 ? (
                                    <span>There is 1 comment.</span>
                                ) : (
                                    <span>
                                        There are {this.props.children}{" "}
                                        comments.{" "}
                                    </span>
                                )
                            ) : (
                                "There are no comments.  "
                            )}
                        </div>
                        {this.props.depth === 0 && (
                            <div className="Post-Tag-Row">
                                {this.tags.map((x) => (
                                    <a
                                        key={x}
                                        className="Post-Tag-Button"
                                        href={"/" + this.order + "/" + x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                        )}
                        <label className="votingArea">
                            Your Vote value
                            {too_late ? (
                                this.state.transient_vote / 100 + " %"
                            ) : this.props.steem_username ? (
                                <div>
                                    {this.state.in_progress && (
                                        <span>
                                            Working...{this.state.error}
                                        </span>
                                    )}
                                    {!this.state.in_progress && !too_late && (
                                        <div
                                            onMouseLeave={
                                                this.setBlockchainVote
                                            }
                                            id="votingArea"
                                            ref={this.votingAreaRef}
                                        >
                                            <button
                                                className="TenPercentButton"
                                                onClick={
                                                    this.subtractTenPercent
                                                }
                                            >
                                                -10% Vote
                                            </button>
                                            <input
                                                className="voteSlider"
                                                type={"range"}
                                                step={100}
                                                min={
                                                    too_late
                                                        ? this.state
                                                              .transient_vote
                                                        : -10000
                                                }
                                                max={
                                                    too_late
                                                        ? this.state
                                                              .transient_vote
                                                        : 10000
                                                }
                                                value={
                                                    this.state.transient_vote
                                                }
                                                onChange={this.setTransientVote}
                                            />
                                            <span className="PD">
                                                {this.state.transient_vote /
                                                    100 +
                                                    " %"}
                                            </span>
                                            <button
                                                className="TenPercentButton"
                                                onClick={this.addTenPercent}
                                            >
                                                +10% Vote
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                "unknown.  login"
                            )}
                        </label>

                        <div className="App">
                            {showReplies &&
                                this.state.replies.map((p) => (
                                    <div className="relative">
                                        <PostDisplayComponent
                                            key={p.permlink}
                                            setURL={this.props.setURL}
                                            onAddOpinion={
                                                this.props.onAddOpinion
                                            }
                                            blockchains={p.blockchains}
                                            steem_username={
                                                this.props.steem_username
                                            }
                                            renderFollowingOpinion={
                                                this.props
                                                    .renderFollowingOpinion
                                            }
                                            id={p.id}
                                            order={
                                                this.props.order || "created"
                                            }
                                            following={this.props.following}
                                            author={p.author}
                                            permlink={p.permlink}
                                            category={p.category}
                                            title={p.title}
                                            body={p.body}
                                            json_metadata={p.json_metadata}
                                            created={p.created}
                                            last_update={p.last_update}
                                            depth={p.depth}
                                            children={p.children}
                                            net_rshares={p.net_rshares}
                                            last_payout={p.last_payout}
                                            cashout_time={p.cashout_time}
                                            total_payout_value={
                                                p.total_payout_value
                                            }
                                            curator_payout_value={
                                                p.curator_payout_value
                                            }
                                            pending_payout_value={
                                                p.pending_payout_value
                                            }
                                            promoted={p.promoted}
                                            replies={p.replies}
                                            body_length={p.body_length}
                                            active_votes={p.active_votes}
                                            author_reputation={
                                                p.author_reputation
                                            }
                                            parent_author={this.props.author}
                                            parent_permlink={
                                                this.props.permlink
                                            }
                                            url={p.url}
                                            root_title={p.root_title}
                                            beneficiaries={p.beneficiaries}
                                            max_accepted_payout={
                                                p.max_accepted_payout
                                            }
                                            percent_steem_dollars={
                                                p.percent_steem_dollars
                                            }
                                            percent_hbd={p.percent_hbd}
                                            accounts={this.props.accounts}
                                        />
                                    </div>
                                ))}
                        </div>

                        {this.state.error}
                    </div>
                );
            } catch (e) {
                try {
                    ++version_index;
                    let now = new Date(); // localtime
                    let last_update = new Date(this.props.last_update); // utc time

                    // should never be negative.
                    const diff =
                        now.getTime() +
                        now.getTimezoneOffset() * 60000 /*utc time */ -
                        last_update.getTime();
                    const vote_window = 7 * 24 * 3600000;
                    const too_late = diff > vote_window;

                    const has_hive = this.props.blockchains.includes("hive");
                    const has_steem = this.props.blockchains.includes("steem");

                    return (
                        <div
                            style={{
                                borderStyle:
                                    has_hive && has_steem ? "double" : "solid",
                                borderColor:
                                    has_steem && has_hive
                                        ? "white"
                                        : has_hive
                                        ? "red"
                                        : has_steem
                                        ? "blue"
                                        : "black",
                            }}
                        >
                            <h1>{this.props.title}</h1>
                            {"blockchain" +
                                (this.props.blockchains.length === 1
                                    ? ""
                                    : "s") +
                                ": " +
                                this.props.blockchains.join(", ")}
                            <p>
                                by{" "}
                                <a href={"/@" + this.props.author}>
                                    @{this.props.author}
                                </a>
                            </p>
                            <div className="Post-Tag-Row">
                                {this.tags.map((x) => (
                                    <a
                                        key={x}
                                        className="Post-Tag-Button"
                                        href={"/" + this.order + "/" + x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                            <div className="AlternativeSiteBox">
                                <b>See Also on:</b>
                                {sitesForBlockchains(
                                    this.props.blockchains,
                                    "steemfiles.com"
                                ).map((x) => (
                                    <a
                                        key={x}
                                        href={"https://" + x + this.props.url}
                                        target={x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                            <div>
                                Using the {version_names[version_index]} View as{" "}
                                {this.state.view_markdown ===
                                view_type.RENDERED_VIEW
                                    ? "Rendered Markdown"
                                    : this.state.view_markdown ===
                                      view_type.BARE_VIEW
                                    ? "Markdown Source"
                                    : "HTML Code"}
                                {this.state.view_markdown !==
                                    view_type.BARE_VIEW && (
                                    <button
                                        onClick={this.setViewMode.bind(
                                            this,
                                            view_type.BARE_VIEW
                                        )}
                                    >
                                        Bare view
                                    </button>
                                )}
                                {this.state.view_markdown !==
                                    view_type.RENDERED_VIEW && (
                                    <button
                                        onClick={this.setViewMode.bind(
                                            this,
                                            view_type.RENDERED_VIEW
                                        )}
                                    >
                                        Rendered view
                                    </button>
                                )}
                                {this.state.view_markdown !==
                                    view_type.HTML_VIEW && (
                                    <button
                                        onClick={this.setViewMode.bind(
                                            this,
                                            view_type.HTML_VIEW
                                        )}
                                    >
                                        HTML view
                                    </button>
                                )}
                            </div>
                            {this.state.view_markdown ===
                            view_type.RENDERED_VIEW ? (
                                <MarkdownViewer
                                    embedVideos={true}
                                    text={this.props.body}
                                    large={true}
                                    highQualityPost={true}
                                    noImage={false}
                                    allowDangerousHTML={false}
                                    hideImages={false}
                                    breaks={true}
                                />
                            ) : this.state.view_markdown ===
                              view_type.BARE_VIEW ? (
                                <code>
                                    {this.props.body.replace(/\n/, "<br />\\n")}
                                </code>
                            ) : (
                                <MarkdownViewer
                                    showHTMLSource={true}
                                    embedVideos={true}
                                    text={this.props.body}
                                    large={true}
                                    highQualityPost={true}
                                    noImage={false}
                                    allowDangerousHTML={false}
                                    hideImages={false}
                                    breaks={true}
                                />
                            )}
                            <hr />
                            <div className="PostStats">
                                {this.state.vote_count !== 1 ? (
                                    <span>
                                        {this.state.vote_count} votes on this
                                        post.{" "}
                                    </span>
                                ) : (
                                    <span>One vote on this post. </span>
                                )}
                                {this.props.renderFollowingOpinion &&
                                    this.props.renderFollowingOpinion(
                                        this.followingVotes
                                    )}
                                {this.props.children > 0 ? (
                                    this.props.children === 1 ? (
                                        <span>There is 1 comment.</span>
                                    ) : (
                                        <span>
                                            There are {this.props.children}{" "}
                                            comments.{" "}
                                        </span>
                                    )
                                ) : (
                                    "There are no comments.  "
                                )}
                            </div>
                            <div className="Post-Tag-Row">
                                {this.tags.map((x) => (
                                    <a
                                        key={x}
                                        className="Post-Tag-Button"
                                        href={"/" + this.order + "/" + x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                            <label className="votingArea">
                                Your Vote value{" "}
                                {this.props.steem_username ? (
                                    <div>
                                        {this.state.in_progress && (
                                            <span>
                                                Working...{this.state.error}
                                            </span>
                                        )}
                                        {!this.state.in_progress && (
                                            <div
                                                onMouseLeave={
                                                    this.setBlockchainVote
                                                }
                                                id="votingArea"
                                                ref={this.votingAreaRef}
                                            >
                                                {too_late || (
                                                    <button
                                                        className="TenPercentButton"
                                                        onClick={
                                                            this
                                                                .subtractTenPercent
                                                        }
                                                    >
                                                        -10% Vote
                                                    </button>
                                                )}
                                                <input
                                                    className="voteSlider"
                                                    type={"range"}
                                                    step={100}
                                                    min={
                                                        too_late
                                                            ? this.state
                                                                  .transient_vote
                                                            : -10000
                                                    }
                                                    max={
                                                        too_late
                                                            ? this.state
                                                                  .transient_vote
                                                            : 10000
                                                    }
                                                    value={
                                                        this.state
                                                            .transient_vote
                                                    }
                                                    onChange={
                                                        this.setTransientVote
                                                    }
                                                />
                                                <span className="PD">
                                                    {this.state.transient_vote /
                                                        100 +
                                                        " %"}
                                                </span>
                                                {too_late || (
                                                    <button
                                                        className="TenPercentButton"
                                                        onClick={
                                                            this.addTenPercent
                                                        }
                                                    >
                                                        +10% Vote
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    "unknown.  login"
                                )}
                            </label>
                            {this.state.error}
                        </div>
                    );
                } catch (e) {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "white",
                            }}
                        >
                            <h1>{this.props.title}</h1>
                            <p>
                                by{" "}
                                <a href={"/@" + this.props.author}>
                                    @{this.props.author}
                                </a>
                            </p>
                            {this.tags && (
                                <div className="Post-Tag-Row">
                                    {this.tags.map((x) => (
                                        <a
                                            className="Post-Tag-Button"
                                            href={"/" + this.order + "/" + x}
                                        >
                                            {x}
                                        </a>
                                    ))}
                                </div>
                            )}
                            <div className="AlternativeSiteBox">
                                <b>See Also on:</b>
                                {[
                                    "steemit.com",
                                    "steempeak.com",
                                    "test.steemfiles.com",
                                    "www.steemfiles.com",
                                ].map((x) => (
                                    <a
                                        href={"https://" + x + this.props.url}
                                        target={x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                            <div>
                                Using the {version_names[++version_index]} View
                            </div>
                            Error: {JSON.stringify(e)}
                            <Markdown source={this.props.body} noImage={true} />
                            <hr />
                            <div className="voteBar">
                                {this.state.vote_count} votes on this post.{" "}
                                {this.props.renderFollowingOpinion &&
                                    this.props.renderFollowingOpinion(
                                        this.followingVotes
                                    )}
                            </div>
                            <div className="Post-Tag-Row">
                                {this.tags.map((x) => (
                                    <a
                                        className="Post-Tag-Button"
                                        href={"/" + this.order + "/" + x}
                                    >
                                        {x}
                                    </a>
                                ))}
                            </div>
                            <label className="votingArea">
                                Your Vote value{" "}
                                {this.props.steem_username ? (
                                    <div>
                                        {this.state.in_progress && (
                                            <span>
                                                Working...{this.state.error}
                                            </span>
                                        )}
                                        {!this.state.in_progress && (
                                            <div
                                                onMouseLeave={
                                                    this.setBlockchainVote
                                                }
                                                id="votingArea"
                                                ref={this.votingAreaRef}
                                            >
                                                <button
                                                    onClick={
                                                        this.subtractTenPercent
                                                    }
                                                >
                                                    -10% Vote
                                                </button>
                                                <input
                                                    type={"range"}
                                                    step={100}
                                                    min={-10000}
                                                    max={10000}
                                                    value={
                                                        this.state
                                                            .transient_vote
                                                    }
                                                    onChange={
                                                        this.setTransientVote
                                                    }
                                                />
                                                <span className="PD">
                                                    {this.state.transient_vote /
                                                        100 +
                                                        " %"}
                                                </span>
                                                <button
                                                    onClick={this.addTenPercent}
                                                >
                                                    +10% Vote
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    "unknown.  login"
                                )}
                            </label>
                            {this.state.error}
                        </div>
                    );
                }
            }
        }
    }
}

export default PostDisplayComponent;
