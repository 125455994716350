import React from "react";
import Remarkable from "remarkable";
import HtmlReady from "./HtmlReady";
import { embedVideos, iframestoURLs } from "./embedVideos";
import "./PostDisplayComponent.css";
const remarkable = new Remarkable({
    html: false, // remarkable renders first then sanitize runs...
    breaks: true,
    linkify: false, // linkify is done locally
    typographer: false, // https://github.com/jonschlinkert/remarkable/issues/142#issuecomment-221546793
    quotes: "“”‘’",
});

const remarkableToSpec = new Remarkable({
    html: false,
    breaks: false, // real markdown uses \n\n for paragraph breaks
    linkify: false,
    typographer: false,
    quotes: "“”‘’",
});

interface MarkDownViewerProps {
    text: string;
    className: string;
    large: boolean;
    highQualityPost: boolean;
    noImage: boolean;
    embedVideos: boolean;
    showHTMLSource: boolean | undefined;
    allowDangerousHTML: boolean;
    hideImages: boolean; // whether to replace images with just a span containing the src url
    breaks: boolean; // true to use bastardized markdown that cares about newlines
}

class MarkdownViewer extends React.Component<
    MarkDownViewerProps,
    { pure: boolean; allowNoImage: boolean; width: number; height: number }
> {
    static defaultProps = {
        allowDangerousHTML: false,
        showHTMLSource: false,
        breaks: true,
        className: "",
        hideImages: false,
        large: false,
    };

    private element: HTMLDivElement = document.createElement("div");
    private elementRef = React.createRef<HTMLDivElement>();

    constructor(props: MarkDownViewerProps) {
        super(props);
        this.state = { allowNoImage: true, pure: true, width: 0, height: 0 };
        this.setVideoEmbedding = this.setVideoEmbedding.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    calculateSize() {
        if (this.elementRef && this.elementRef.current) {
            const r = this.elementRef.current.getBoundingClientRect();
            const ar = 9 / 16;
            const windowHeight = r.height;
            const proposedIframeWidth: number = Math.floor(r.width);
            const proposedIframeHeight: number = Math.floor(ar * r.width);
            console.log(proposedIframeWidth, proposedIframeHeight);
            const iframeHeight =
                proposedIframeHeight <= windowHeight
                    ? proposedIframeHeight
                    : windowHeight;
            const iframeWidth =
                proposedIframeHeight === iframeHeight
                    ? proposedIframeWidth
                    : Math.floor(iframeHeight / ar);
            console.log({
                width: iframeWidth,
                height: iframeHeight,
            });
            return {
                width: iframeWidth,
                height: iframeHeight,
            };
        } else {
            return {
                width: this.state.width,
                height: this.state.height,
            };
        }
    }

    setVideoEmbedding(x: boolean) {
        this.setState({ pure: !x });
    }

    shouldComponentUpdate(np, ns) {
        return (
            np.text !== this.props.text ||
            np.large !== this.props.large ||
            ns.allowNoImage !== this.state.allowNoImage ||
            np.showHTMLSource !== this.props.showHTMLSource
        );
    }

    onAllowNoImage = () => {
        this.setState({ allowNoImage: false });
    };

    handleResize(e) {
        if (this.elementRef.current) {
            const bcr = this.elementRef.current.getBoundingClientRect();
            this.setState({
                width: bcr.width,
                height:
                    window.innerHeight < bcr.height
                        ? window.innerHeight
                        : bcr.height,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // This component may need to have its state updated after being displayed.  As other components will change the element's size, which is totally not within its control.
        if (this.elementRef.current) {
            const bcr = this.elementRef.current.getBoundingClientRect();
            if (bcr.width !== this.state.width) {
                this.setState({
                    width: bcr.width,
                    height: bcr.height,
                });
            }
        }
    }

    render() {
        let { text } = this.props;
        if (!text) text = ""; // text can be empty, still view the link meta data
        const { large } = this.props;

        let html = false;
        // See also ReplyEditor isHtmlTest
        const m = text.match(/^<html>([\S\s]*)<\/html>$/);
        if (m && m.length === 2) {
            html = true;
            text = m[1];
        } else {
            // See also ReplyEditor isHtmlTest
            html = /^<p>[\S\s]*<\/p>/.test(text);
        }

        console.log("This is " + (html === false ? "not" : "") + " HTML");
        // Strip out HTML comments. "JS-DOS" bug.
        text = text.replace(
            /<!--([\s\S]+?)(-->|$)/g,
            "(html comment removed: $1)"
        );
            text = text
                .replace(/(https:[.A-Za-z0-9/-]+\.(png|gif|webp|ico))/gm, "![]($1)")
                .replace(/<img src="([^>]+)"( [^>]+)?\/?>/gm, "![]($1)")
                .replace(/<img src=([^>]+)( [^>]+)?\/?>/gm, "![]($1)")
                .replace(/<[//]?div.*?>/g, "")
                .replace(/<[//]h[1-6]>/g, "")
                .replace(/<h1.*?>/g, "# ")
                .replace(/<h2.*?>/g, "## ")
                .replace(/<h3.*?>/g, "### ")
                .replace(/<h4.*?>/g, "#### ")
                .replace(/<h5.*?>/g, "##### ")
                .replace(/<h6.*?>/g, "###### ")
                .replace(/<\/?em.*?>/g, "*")
                .replace(/<\/?strong.*?>/g, "**")
                .replace(/<hr>/g, "-----")
                // replace iff not already part of a ![](url) pattern or img src tag
                //.replace(/([^'"(=])(https:[.a-z0-9/]+\.ico)/, "$1![]($2)")
                  ;

        let renderer = remarkableToSpec;
        if (this.props.breaks === true) {
            renderer = remarkable;
        }

        let renderedText = html ? text : renderer.render(text);

        // If content isn't wrapped with an html element at this point, add it.
        if (renderedText.indexOf("<html>") !== 0) {
            renderedText = "<html>" + renderedText + "</html>";
        }

        let HtmlReadyOutput = "";

        // I couldn't get this part to work.
        // // Embed videos, link mentions and hashtags, etc...
        if (renderedText) {
            HtmlReadyOutput = HtmlReady(renderedText, {
                hideImages: false,
                width: this.state.width,
                height: this.state.height,
            }).html;
        }

        console.log(`HtmlReady returned '${HtmlReadyOutput}'`);

        if (HtmlReadyOutput) {
            renderedText = HtmlReadyOutput.replace(
                /<html[^>]*>(.*?)<\/html>/,
                "$1"
            );
            renderedText = renderedText.slice(14);
            return (
                <div dangerouslySetInnerHTML={{ __html: renderedText }}></div>
            );
        } else {
            console.log("Converting HTML to MD");

            // Cleans up a lot of bastardized Markdown.
            // Convert html links to Markdown links

            text = iframestoURLs(text);

            // deencode some of the HTML code
            renderedText = renderedText.replace(
                /&lt; *(center|div|sup|br|p|img)( [^&]*?)?&gt;/gi,
                "<$1>"
            );
            renderedText = renderedText.replace(
                /&lt;\/(center|div|sup|br|p)&gt;/gi,
                "</$1>"
            );

            // remove all that HTML crap people like to put in posts.
            renderedText = renderedText.replace(/&lt; *(a[^&]*?)&gt;/gim, "");
            renderedText = renderedText.replace(/&lt; *\/(a)&gt;/gim, "");

            renderedText = renderedText.replace(/&lt;(a href=.*)&gt;/gim, "");
            renderedText = renderedText.replace(/&lt;img src=/gim, "");
            let cleanText = renderedText;
            if (this.props.allowDangerousHTML === true) {
                console.log(
                    "WARN\tMarkdownViewer rendering unsanitized content"
                );
            } else {
                /*
				cleanText = sanitize(
					renderedText,
					sanitizeConfig({
						large,
						highQualityPost,
						noImage: noImage && allowNoImage,
					})
				);*/
            }

            if (/<\s*script/gi.test(cleanText)) {
                // Not meant to be complete checking, just a secondary trap and red flag (code can change)
                console.error(
                    "Refusing to render script tag in post text",
                    cleanText
                );
                return <div ref={this.elementRef} />;
            }

            if (this.props.embedVideos && !HtmlReadyOutput)
                cleanText = embedVideos(
                    cleanText,
                    this.state.width,
                    this.state.height
                );
        }

        // Complete removal of javascript and other dangerous tags..
        // The must remain as close as possible to dangerouslySetInnerHTML
        let cleanText = renderedText;

        const noImageActive = false; // cleanText.indexOf(noImageText) !== -1;

        // In addition to inserting the youtube component, this allows
        // react to compare separately preventing excessive re-rendering.
        let idx: number = 0;
        const sections: Array<JSX.Element> = [];

        // HtmlReady inserts ~~~ embed:${id} type ~~~
        for (let section of cleanText.split("~~~ embed:")) {
            const match = section.match(
                /^([A-Za-z0-9?=_\-/.]+) (youtube|vimeo|twitch|dtube|threespeak)\s?(\d+)? ~~~/
            );
            if (match && match.length >= 3) {
                const id = match[1];
                const type = match[2];
                const startTime = match[3] ? parseInt(match[3]) : 0;

                if (type === "youtube") {
                    /*
                    sections.push(
                        <YoutubePreview
                            key={id}
                            width={w}
                            height={h}
                            youTubeId={id}
                            startTime={startTime}
                            frameBorder="0"
                            allowFullScreen="true"
                        />
                    );*/
                } else if (type === "threespeak") {
                    sections.push(<div />);
                } else if (type === "vimeo") {
                    sections.push(<div />);
                } else if (type === "twitch") {
                    sections.push(<div />);
                } else if (type === "dtube") {
                    sections.push(<div />);
                } else {
                    console.error("MarkdownViewer unknown embed type", type);
                }
                if (match[3]) {
                    section = section.substring(
                        `${id} ${type} ${startTime} ~~~`.length
                    );
                } else {
                    section = section.substring(`${id} ${type} ~~~`.length);
                }
                if (section === "") continue;
            }
            sections.push(
                <div
                    key={idx++}
                    dangerouslySetInnerHTML={{ __html: section }}
                />
            );
        }

        const allowNoImage = false;

        const cn = "";

        return (
            <div
                ref={this.elementRef}
                className={"MarkdownViewer " + cn}
                style={{ margin: "1ch" }}
            >
                {sections}
                {noImageActive && allowNoImage && (
                    <div
                        onClick={this.onAllowNoImage}
                        className="MarkdownViewer__negative_group"
                    >
                        {"images hidden due to low ratings"}
                        <button
                            style={{ marginBottom: 0 }}
                            className="button hollow tiny float-right"
                        >
                            {"show"}
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default MarkdownViewer;
