import React from "react";
import FileComponent from "./FileComponent";
import "./FilesListComponent.css";
import "./App.css";
interface FLCState {
    showDescriptions: boolean;
    maxPrice: number;
}

interface FileListComponentProps {
    logged_in_username: string;
    setOwner: (new_owner: string) => void;
    owner: string;
    onGotFiles: (file_data: any) => void;
    files: Array<any>;
    filesLoading: boolean;
}

class FileListComponent extends React.Component<
    FileListComponentProps,
    FLCState
> {
    constructor(props) {
        super(props);
        this.state = { showDescriptions: false, maxPrice: 1000 };
        this.handleNewFiles = this.handleNewFiles.bind(this);
    }

    handleNewFiles(files) {
        this.props.onGotFiles(files);
    }

    componentDidMount() {
        let p;
        if (this.props.logged_in_username) {
            p = fetch(
                "/cgi-bin/getnewdownloads.cgi?username=" +
                    this.props.logged_in_username
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            );
        } else {
            p = fetch("/cgi-bin/getnewdownloads.cgi").then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            );
        }
        p.then(this.handleNewFiles);
    }

    searchOwnerChanged(e) {
        this.props.setOwner(e.target.value);
    }

    maxPriceChanged(e) {
        let val = e.target.value;
        this.setState({ maxPrice: val });
    }

    toggleShowDescriptions(e) {
        this.setState({ showDescriptions: e.target.checked });
    }

    render() {
        try {
            return (
                <div>
                    <div>
                        <label>
                            maximum price:
                            <input
                                id="amountControl"
                                type="number"
                                placeholder="amount of money ex: 0.40"
                                onChange={this.maxPriceChanged.bind(this)}
                                defaultValue={this.state.maxPrice}
                            ></input>
                        </label>
                        <br />
                        <label>
                            search:
                            <input
                                id="searchDescriptionControl"
                                type="text"
                                placeholder="search descriptions"
                            ></input>
                        </label>
                        <br />
                        <div id="showDescriptionControl">
                            <input
                                type="checkbox"
                                defaultChecked={this.state.showDescriptions}
                                onChange={this.toggleShowDescriptions.bind(
                                    this
                                )}
                            />
                            <span className="nobr">Show Descriptions</span>
                        </div>
                    </div>

                    <div id="FileListDisplay">
                        {this.props.filesLoading && "Files loading..."}

                        {this.props.files
                            .filter(
                                (file) =>
                                    this.props.owner === "" ||
                                    this.props.owner === file.owner
                            )
                            .filter(
                                (file) =>
                                    this.state.maxPrice >= file.price_amount
                            )
                            .map((file) => (
                                <FileComponent
                                    key={file.id}
                                    {...file}
                                    setOwner={this.props.setOwner.bind(
                                        this,
                                        file.owner
                                    )}
                                    showDescription={
                                        this.state.showDescriptions
                                    }
                                />
                            ))}
                    </div>
                    <div id="ScreenTooThin">
                        Your screen is too thin. Come back with a wider browser
                        Window.
                    </div>
                </div>
            );
        } catch (e) {
            return (
                <div
                    style={{
                        backgroundColor: "black",
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                        }}
                    >
                        <label>
                            owner:
                            <input
                                type="text"
                                placeholder="owner username"
                                onChange={this.searchOwnerChanged.bind(this)}
                                defaultValue={this.props.owner}
                            ></input>
                        </label>
                        <label>
                            search:
                            <input
                                type="text"
                                placeholder="search descriptions"
                            ></input>
                        </label>
                        <label>
                            maximum price:
                            <input
                                type="number"
                                placeholder="amount of money ex: 0.40"
                                onChange={this.maxPriceChanged.bind(this)}
                                defaultValue={this.state.maxPrice}
                            ></input>
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.showDescriptions}
                                onClick={this.toggleShowDescriptions.bind(this)}
                            ></input>
                            Show Descriptions
                        </label>
                    </div>

                    <div style={{ backgroundColor: "#111111" }}>
                        {this.props.files
                            .filter(
                                (file) =>
                                    this.props.owner === "" ||
                                    this.props.owner === file.owner
                            )
                            .filter(
                                (file) =>
                                    this.state.maxPrice >= file.price_amount
                            )
                            .map((file) => (
                                <div
                                    key={file.id}
                                    style={{ borderStyle: "solid" }}
                                >
                                    {file.name}
                                    {file.extension}
                                    {file.mini_preview && (
                                        <img alt="" src={file.mini_preview} />
                                    )}
                                    {file.owner ? (
                                        <span>
                                            {" "}
                                            by{" "}
                                            <span
                                                onClick={this.props.setOwner.bind(
                                                    this,
                                                    file.owner
                                                )}
                                                style={{
                                                    color: "cyan",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                @{file.owner}
                                            </span>
                                        </span>
                                    ) : (
                                        <span>
                                            {" "}
                                            was uploaded by some user that had
                                            not logged in
                                        </span>
                                    )}
                                    {".  "}
                                    Price {file.price}{" "}
                                    {file.currency.length > 0 ? (
                                        <span>
                                            accepting {file.currency.join()}
                                        </span>
                                    ) : (
                                        "No payment methods for this file"
                                    )}
                                    {this.state.showDescriptions && (
                                        <div style={{ paddingLeft: "4em" }}>
                                            {file.description}
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            );
        }
    }
}

export default FileListComponent;
