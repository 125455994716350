import steem from "steem";
import hive from "@hiveio/hive-js";
import blurt from "@blurtfoundation/blurtjs";
import whaleshares from "@whaleshares/wlsjs";
import steem_logo from "./steem.png";
import hive_logo from "./hive.png";
import blurt_logo from "./blurt.png";
import whaleshares_logo from "./whaleshares.png";

const whalevault_blockchain_support_list = [
    "whaleshares",
    "bitshares",
    "eos",
    "steem",
    "hive",
    "blurt",
    "smoke",
    "telos",
    "worbli",
    "golos",
    "peerplays",
    "scorum",
];

blurt.api.setOptions({ url: "https://rpc.blurt.world", useAppbaseApi: true });
blurt.config.set("address_prefix", "BLT");
blurt.config.set(
    "chain_id",
    "cd8d90f29ae273abec3eaa7731e25934c63eb654d55080caff2ebb7f5df6381f"
);
blurt.config.set("alternative_api_endpoints", [
    "https://rpc.blurt.world",
    "https://rpc.blurt.world",
]);

// Title Case
export function titleCase(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

interface blockchainInfo {
    name: string;
    pubkey_prefix: string;
    username_prefix: string;
    logo: any;
}

export function supportedBlockchains(): Array<blockchainInfo> {
    return [
        { name: "blurt", pubkey_prefix: "BLT", username_prefix: 'blt', logo: blurt_logo },
        { name: "hive", pubkey_prefix: "STM", username_prefix: 'hiv',  logo: hive_logo },
        { name: "steem", pubkey_prefix: "STM", username_prefix: 'stm',  logo: steem_logo },
        { name: "whaleshares", pubkey_prefix: "WLS", username_prefix: 'wls',  logo: whaleshares_logo },
    ];
}

// Pass a single blockchain or an array of blockchains and get an array of
// websites that support at least one of these blockchains.  The second parameter
// is the name of the website you are using or '' if this is used in some kind of
// app running without a server.
export function sitesForBlockchains(
    blockchains_p:
        | blockchainInfo
        | Array<blockchainInfo>
        | string
        | Array<string>,
    remove_site: string = document.location.hostname
): Array<string> {
    let blockchains: Array<blockchainInfo | string>;
    if (blockchains_p instanceof Array) {
        // An array
        blockchains = blockchains_p;
    } else {
        blockchains = [blockchains_p];
    }
    let blockchain_name: string;
    let sites: Array<string> = [];
    for (const b of blockchains) {
        if (!!(b as blockchainInfo).name) {
            blockchain_name = (b as blockchainInfo).name;
        } else {
            blockchain_name = b as string;
        }
        switch (blockchain_name) {
            case "blurt":
                sites = [...sites, "blurt.world"];
                break;
            case "whaleshares":
                sites.push("whaleshares.io");
                break;
            case "steem":
                sites = [...sites, "steempeak.com", "steemit.com"];
                break;
            case "hive":
                // pure sites using Hive
                sites = [...sites, "peakd.com", "hive.blog", "ecency.com"];
                // alttoken sites using HiveEngine tokens
                sites = [
                    ...sites,
                    "leofinance.io",
                    "proofofbrain.io",
                    "ctptalk.com",
                    "stemgeeks.net",
                    "sportstalksocial.com",
                    "weedcash.network",
                ];
                // twitter like but on Hive... so...
                sites.push("d.buzz");
                break;
        } //switch
    } // for b

    // steemfiles uses all the blockchains
    if (blockchains.length) sites.push("steemfiles.com");

    return sites.filter((x) => x !== remove_site);
}

export function supported_blockchains() {
    return supportedBlockchains();
}

// Direct handling with Keys APIs Low level
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function nameForAPI(blockchain: string) {
    if (blockchain === "hive") return hive;
    else if (blockchain === "steem") return steem;
    else if (blockchain === "blurt") return blurt;
    else if (blockchain === "whaleshares") return whaleshares;
    throw new Error("Bad blockchain parameter for nameForAPI: " + blockchain);
}

// Direct handling with Keys APIs Low level
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function APIForBlockchain(blockchain: string) {
    if (blockchain === "hive") return hive;
    else if (blockchain === "steem") return steem;
    else if (blockchain === "blurt") return blurt;
    else if (blockchain === "whaleshares") return whaleshares;
    throw new Error("Bad blockchain parameter for nameForAPI: " + blockchain);
}

// Keychain Low Level
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function keyChainAvailable(blockchain: string): boolean {
    //  tslint:disable-next-line  no-restricted-globals
    return !!(
        (window["whalevault"] &&
            whalevault_blockchain_support_list.includes(blockchain)) ||
        (blockchain === "steem" && window["steem_keychain"]) ||
        (blockchain === "hive" && window["hive_keychain"])
    );
}

export function keychainForBlockchain(blockchain: string) {
    // returns some function object I don't know how to put in typescript terms or undefined.
    if (blockchain === "steem")
        return window["steem_keychain"] || window["whalevault"];
    else if (blockchain === "hive")
        return window["hive_keychain"] || window["whalevault"];
    else if (whalevault_blockchain_support_list.includes(blockchain))
        return window["whalevault"];
    throw new Error(
        "Bad blockchain parameter for keychainForBlockchain: " + blockchain
    );
}
