const imageURLPattern = /(?<pre>([^'"=]|^))(?<url>\bhttps??:\/\/(www\.)?[-a-z0-9._]{1,256}\.[-a-zA-Z0-9]{1,6}[#//][-A-Za-z0-9_&@%#._+~#=//?]+\.(gif|png|jpg|jpeg))/g;
const imageInlineSub = '$<pre><img src="$<url>" />';

// matches if there is any HTTPS URL anywhere
const genericURLPattern = /(?<pre>([^'"=]|^))(?<url>\bhttps?:\/\/[-a-z0-9._]{1,256}\.[a-z]{1,6}(:[0-9]{2,5})?([//][-$A-Za-z0-9_&@%#._+~#=//?!:]*)?(#[A-Za-z0-9]+)?)/gi;
const genericURLLink = "$<pre><a href='$<url>'>$<url></a>";
const genericEmbeddedPattern = /<iframe [^>]*src="(?<url>\bhttps?:\/\/[-a-z0-9._]{1,256}\.[a-z]{1,6}(:[0-9]{2,5})?([//][-$A-Za-z0-9_&@%#._+~#=//?!:]*)?(#[A-Za-z0-9]+)?)"[^>]*><\/iframe>/g;

/*
<iframe id="lbry-iframe" width="560" height="315" src="https://odysee.com/$/embed/Church-Is-Essential/ab9683609e9ca98fa2cb7cd46709e7f1fc68f2c9?r=8eDJkBbPDVofP7cE587G7x66RYNDp4nY" allowfullscreen></iframe>
*/
const lbryEmbeddedPattern = /<iframe id="lbry-iframe" width="560" height="315" [^>]*src="(?<url>https:\/\/odysee.com([//][-$:A-Za-z0-9_&@%#._+~#=//?!\$]*))"[^>]*><\/iframe>/g;

const videoURLPattern = /(?<pre>([^'"=]|^))(?<url>\bhttps??:\/\/(www\.)?[-a-z0-9._]{1,256}\.[-a-zA-Z0-9]{1,6}[#//][-A-Za-z_&@%#._+~#=//?]+\.(mp4|mp3))/g;
const videoInlineSub =
    '$<pre><video  class="inline-video" src="$<url>" /></video>';

const threeSpeakEmbeddedPattern = /<iframe [^>]+ src="(?<url>https?:\/\/3speak.co\/embed\?v=[a-z0-9-]+\/[a-z0-9-]+)" [^>]+>\s+<\/iframe>/g;
export const threeSpeakURLPattern = /(?<pre>([^'"=]|^))\bhttps?:\/\/3speak.(online|tv|co)\/watch\?(?<id>v=[a-z0-9-]+\/[a-z0-9-]+)(&[a-z=]+)*\/?/g;
export const threeSpeakEmbeddingSub =
    '$<pre><iframe  class="inline-video" src="https://3speak.co/embed?$<id>" frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

const bannedVideoURLPattern = /(?<pre>([^'"=]|^))\bhttps?:\/\/2020electioncenter.com\/watch\?id=(?<video>[a-f0-9]+)/g;
const bannedVideoEmbeddingSub =
    '$<pre><div class="ifw-player"  class="inline-video" data-video-id="$<video>"></div>';

// https://brandnewtube.com/embed/rXFlW2slmZ8sMcI
const brandnewtubeURLPattern = /https:\/\/brandnewtube.com\/embed\/(?<id>[a-zA-Z0-9]+)\/?/;
const brandnewtubeEmbededPattern = /&lt;iframe src="https:\/\/brandnewtube.com\/embed\/(?<id>[a-z0-9A-Z]+)" frameborder="0" allowfullscreen&gt;&lt;\/iframe&gt/g;
const brandnewtubeVideoEmbeddingSub =
    '<iframe src="https://brandnewtube.com/embed/$<id>" frameborder="0"  allowfullscreen></iframe>';

const brightreonURLPattern = /(?<pre>([^'"=]|^))\bhttps?:\/\/www.brighteon.com\/(?<id>[0-9a-f-]+)/g;
const brightreonVideoEmbeddingSub =
    "$<pre><iframe class='inline-video'  src='https://www.brighteon.com/embed/$<id>'  frameborder='0' allowfullscreen></iframe>";

const bitchuteVideoEmbeddedPattern = /(?<pre>\b)&lt;iframe class="inline-video" scrolling="no" frameborder="0" style="border: none;" ' + 		'src="https:\/\/www.bitchute.com\/embed\/(?<id>[a-zA-Z0-9-]+)\/"&gt;&lt;\/iframe&gt;/g;
const bitchuteURLPattern = /(?<pre>([^'"=]|^))\bhttps?:\/\/www.bitchute.com\/video\/(?<id>[A-Za-z0-9]+\/?)/g;
// eslint-disable-next-line no-useless-concat
const bitchuteVideoEmbeddingSub =
    '$<pre><iframe class="inline-video" scrolling="no" frameborder="0" width={} height={} style="border: none;" ' +
    'src="https://www.bitchute.com/embed/$<id>/"></iframe>';

const dtubeVideoEmbeddedPattern =
    '(?<pre>\b)&lt;iframe  class="inline-video" src="https://emb.d.tube/#!/$&lt;user&gt;/$&lt;video&gt;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen&gt;&lt;/iframe&gt;';
const dtubeURLPattern = /(?<pre>([^'"=]|^))\bhttps?:\/\/(?:emb\.)?d.tube\/(?:#!\/)?v\/(?<user>[a-z0-9_]+)\/(?<video>[a-z0-9]+)\/?/gi;
const dtubeVideoEmbeddingSub =
    '$<pre><iframe  class="inline-video" src="https://emb.d.tube/#!/$<user>/$<video>" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

// LBRY https://lbry.tv/$/embed/democrat-breaks-down-crying-after-losing/ee432313efd6ae7f2ebf8d37f46c909f53b90872?r=3u68cheAJPpbpL7Z7CyQqA8KHNSaYcEf
const lbryVideoEmbeddedPattern =
    '(?<pre>\b)&lt;iframe id="lbry-iframe"  class="inline-video" src="$&lt;url&gt;" allowfullscreen&gt;&lt;/iframe&gt;';
const lbryURLPattern = /(?<pre>([^'"=]|^))(?<url>\bhttps?:\/\/lbry.tv\/\$\/embed\/[/?a-z0-9@:=-]+)/g;
const lbryVideoEmbeddingSub =
    '$<pre><iframe id="lbry-iframe"  class="inline-video" src="$<url>" allowfullscreen></iframe>';

// this one too
const rumbleVideoEmbeddedPattern =
    '(?<pre>\b)&lt;iframe class="rumble"  src="https://rumble.com/embed/(?<id>[a-zA-Z0-9-]+)/?pub=4" frameborder="0" allowfullscreen&gt;&lt;/iframe&gt;';
const rumbleURLPattern = /(?<pre>([^'"=]|^))\bhttps?:\/\/rumble.com\/embed\/(?<videoid>[a-z0-9]+)(\/\?.*)/g;
const rumbleVideoEmbeddingSub =
    '$<pre><iframe class="rumble"  src="https://rumble.com/embed/$<videoid>/?pub=4" frameborder="0" allowfullscreen></iframe>';


const ultimediaVideoEmbeddedPattern =
    '&lt;iframe src="https://www.ultimedia.com/deliver/generic/iframe/mdtk/01601930/src/$&lt;vid&gt;/zone/1/showtitle/1/"' +
    ' frameborder="0" scrolling="no" marginwidth="0" marginheight="0" hspace="0" vspace="0" webkitallowfullscreen="true"' +
    ' mozallowfullscreen="true" allowfullscreen="true"  class="inline-video" allow="autoplay; fullscreen"&gt;&lt;/iframe&gt;';
const ultimediaURLPattern = /\bhttps?:\/\/www.ultimedia.com\/default\/index\/videogeneric\/id\/(?<vid>[a-z0-9]+)/g;
const ultimediaVideoEmbeddingSub =
    '<iframe src="https://www.ultimedia.com/deliver/generic/iframe/mdtk/01601930/src/$<vid>/zone/1/showtitle/1/"' +
    ' frameborder="0" scrolling="no" marginwidth="0" marginheight="0" hspace="0" vspace="0" webkitallowfullscreen="true"' +
    ' mozallowfullscreen="true" allowfullscreen="true"  class="inline-video" allow="fullscreen"></iframe>';

const vimeoURLPattern = /https:\/\/vimeo.com\/(?<videoid>[0-9]+)/g;
const vimeoEmbeddingSub =
    '<iframe src="https://player.vimeo.com/video/$<videoid>" allowfullscreen />';

// VibraVid doesn't seem to have embedding
//const vibravidURLPattern = /\bhttps?:\/\/vibravid.io\/guest\/watch\/5f7554501862505b5b33e87b/g;

//////////////////////// WATCH MOJO ////////////////////////////////////////////////
// There is no regex replace parameters that will change the watchMojo from a URL to an ebeded code.
// But maybe we could create video tags for URLs to mp4s.

// const watchMojo = /https://www.watchmojo.com/video/id/34992/
// Watch Mojo Embeded code
//<script type="text/javascript" src="//services.brid.tv/player/build/brid.min.js"></script><div id="myDiv" class="brid" itemprop="video" itemscope itemtype="http://schema.org/VideoObject"></div><script type="text/javascript"> $bp("myDiv", {"id":"18480","width":"1273","height":"716","video":{ "src": "https://d1w0hpjgs8j5kt.cloudfront.net/9d0c0dac6dc1abb9035cad55f743f938.mp4", "name": "Top 20 Ugly Duckling Transformations in Movies", "image": "/uploads/thumbs720/MM-Film-Top20-Ugly-Duckling-Transformations-in-Movies_S0F6K3-ALT-1.jpg"},"autoplay":0,"shared":true});</script>

const bitcoinAddressPattern = /\b(?<addr>[13][a-km-zA-NP-Z0-9]{32,33})\b/g;
const bitcionExplorerSub = '<a href="bitcoin:$<addr>">$<addr></a>';

const emailAddressPattern = /\b(?<addr>[a-z][a-z0-9.+]*@[a-z]+\.[a-z][a-z]{1,6})\b/gi;
const emailMailToSub = "<a href='mailto:$<addr>'>$<addr></a>";

export function iframestoURLs(markdown: string) {
    return markdown
        .replace(threeSpeakEmbeddedPattern, "$<url>")
        .replace(genericEmbeddedPattern, "$<url>");

    //.replace(lbryEmbeddedPattern, '$<url>');
}

export function embedVideos(cleanText: string, width: number, height: number) {
    // Supplied string should be a sanitized HTML code string.  Like what a markdown renderer may produce.

    // YouTube, Facebook, 3Speak, DTube, Brighteon, and Bitchute can have links to videos converted to embedded codes.
    // There i sno way to convert a URL link of Bitchute, Mojo, and Library (lbry) to an embed code.
    // BitTube doesn't have a way to embeed videos at all

    try {
        // change URLs to image files into inlined images.
        cleanText = cleanText.replace(imageURLPattern, imageInlineSub);
    } catch (e) {
        console.log("Image substitution failed.");
    }

    // Embed vaious video platforms.
    try {
        cleanText = cleanText.replace(emailAddressPattern, emailMailToSub);

        // bitcoin addresses...
        cleanText = cleanText.replace(
            bitcoinAddressPattern,
            bitcionExplorerSub
        );

        // 3Speak
        cleanText = cleanText.replace(
            threeSpeakURLPattern,
            threeSpeakEmbeddingSub
        );

        /* Please Alex Jones make an iframe embed method
		if (cleanText.match(bannedVideoURLPattern)) {
			// Forces a re-render: move to componentDidMount?
			let head_el = document.getElementsByTagName('head')[0];
			let script_els = document.getElementsByTagName('script');
			let load : boolean = true;
			// !!
			for (let script_index in script_els) {
				let script_el = script_els[script_index];
				if (script_el.src === "https://infowarsmedia.com/js/player.js") {
					load = false;
					break;
				}
			}
			if (load) {
				// Oops need a script now.
				var fileref = document.createElement('script');
				fileref.setAttribute("type", "text/javascript");
				fileref.setAttribute("src", "https://infowarsmedia.com/js/player.js");
				head_el.appendChild(fileref);

				// Banned.Video and Infowars.com
				cleanText =  cleanText.replace(bannedVideoURLPattern, bannedVideoEmbeddingSub);
			}
		} */

        // Bitchute
        cleanText = cleanText.replace(
            bitchuteURLPattern,
            bitchuteVideoEmbeddingSub
        );

        // No embedding codes in Bittube.

        // Brand new tube
        cleanText = cleanText.replace(
            brandnewtubeEmbededPattern,
            brandnewtubeVideoEmbeddingSub
        );
        cleanText = cleanText.replace(
            brandnewtubeURLPattern,
            brandnewtubeVideoEmbeddingSub
        );

        // Brighteon
        cleanText = cleanText.replace(
            brightreonURLPattern,
            brightreonVideoEmbeddingSub
        );

        // Dtube
        cleanText = cleanText.replace(dtubeURLPattern, dtubeVideoEmbeddingSub);

        // LBRY
        cleanText = cleanText.replace(lbryURLPattern, lbryVideoEmbeddingSub);

        // Rumble
        cleanText = cleanText.replace(
            rumbleURLPattern,
            rumbleVideoEmbeddingSub
        );

        // UltimateURL Pattern
        cleanText = cleanText.replace(
            ultimediaURLPattern,
            ultimediaVideoEmbeddingSub
        );

        // VideoURL Pattern
        cleanText = cleanText.replace(videoURLPattern, videoInlineSub);

        cleanText = cleanText.replace(vimeoURLPattern, vimeoEmbeddingSub);
    } catch (e) {
        console.log("Video embedding didn't work.");
    }

    // return remaining URLS into href links.
    try {
        return cleanText.replace(genericURLPattern, genericURLLink);
    } catch (e) {
        console.log("Error en the Link replacement");
        return cleanText;
    }
}
export default embedVideos;
