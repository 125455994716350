import React from "react";
import "./CryptoBeggingAd.css";

export class CryptoBeggingAd extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="CryptoAddressesList" className="Ad-column">
                <a href="bitcoincash:qqtes6cafexr00tzv9r360rd3g9l3zssuuj3mqzxpq">
                    <div className="adUnit">
                        <img src="/cca/BitcoinCash-QR.png" />
                        <div className="adTextUnit">Donate BCH</div>
                    </div>
                </a>
                <a href="simpleledger:qqtes6cafexr00tzv9r360rd3g9l3zssuu72smhxl7">
                    <div className="adUnit">
                        <img src="/cca/SLP-QR.png" />
                        <div className="adTextUnit">Donate SLP</div>
                    </div>
                </a>
                <a href="bitcoin:1LT2zLt4uooLfnTFfBJGzpTY4EY1SWZxoJ">
                    <div className="adUnit">
                        <img src="/cca/BitcoinCore-QR.png" />
                        <div className="adTextUnit">Donate BTC</div>
                    </div>
                </a>
                <a href="tron:TPicBhbwp1GtG9xGoUY6gzoTp6ssRQoxgP">
                    <div className="adUnit">
                        <img src="/cca/Tron-QR.png" />
                        <div className="adTextUnit">Donate TRC-20</div>
                    </div>
                </a>
            </div>
        );
    }
}

export default CryptoBeggingAd;
