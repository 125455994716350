import React, { MouseEvent, ChangeEvent } from "react";
import PostDisplayComponent from "./PostDisplayComponent";
import permission_image from "./no-entry-sign-1145042-638x422.jpg";
import "./StartPage.css";
// Photo by Philipp Meeh on Unsplash
import feed_image from "./philipp-meeh-Bu-fUI69fRo-unsplash.jpg";

// Photo by Green Chameleon on Unsplash
import blog_image from "./green-chameleon-s9CC2SKySJM-unsplash.jpg";
import CryptoBeggingAd from "./CryptoBeggingAd";

interface State {
    itemWidth: number;
    windowHeight: number;
}

interface Props {
    username: string;
}
export class StartPage extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { itemWidth: 0, windowHeight: window.innerHeight };
        this.handleResize = this.handleResize.bind(this);
    }
    private itemRefHQTreatment = React.createRef<HTMLLIElement>();
    private itemRefVaccineStudy = React.createRef();

    handleResize(e) {
        if (this.itemRefHQTreatment.current) {
            const bcr = this.itemRefHQTreatment.current.getBoundingClientRect();
            this.setState({
                itemWidth: bcr.width,
                windowHeight: window.innerHeight,
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize, false);
        this.handleResize(null);
    }

    componentDidUpdate(prevProps, prevState) {
        // This component may need to have its state updated after being displayed.  As other components will change the element's size, which is totally not within its control.
        if (this.itemRefHQTreatment.current) {
            const bcr = this.itemRefHQTreatment.current.getBoundingClientRect();
            if (bcr.width !== this.state.itemWidth) {
                this.setState({
                    itemWidth: bcr.width,
                });
            }
        }
        console.log("Component did update!", this.state, prevState);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize, false);
    }

    redirect(new_location, e) {
        e.preventDefault();

        if (this.props.username === "") {
            alert("username is set to empty string.");
            // eslint-disable-next-line no-restricted-globals
            window.scrollTo(0, 0);
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        document.location.href =
            "/@" + this.props.username + "/" + new_location;
    }

    render() {
        const ar = 36 / 64;
        const { itemWidth, windowHeight } = this.state;
        const proposedIframeWidth = itemWidth;
        const proposedIframeHeight = Math.floor(ar * proposedIframeWidth);
        const iframeHeight =
            proposedIframeHeight <= windowHeight
                ? proposedIframeHeight
                : windowHeight;
        const iframeWidth =
            proposedIframeHeight === iframeHeight
                ? proposedIframeWidth
                : Math.floor(iframeHeight / ar);

        return (
            <div>
                <div id="StartPageGrid">
                    {this.props.username && (
                        <button
                            className="tile"
                            onClick={this.redirect.bind(this, "permissions")}
                        >
                            <img className="tile" src={permission_image} />
                            <p className="tile">Go to Permissions</p>{" "}
                        </button>
                    )}
                    {this.props.username && (
                        <button
                            className="tile"
                            onClick={this.redirect.bind(this, "blog")}
                        >
                            <img src={blog_image} className="tile" />
                            <p className="tile">Go to Blog</p>{" "}
                        </button>
                    )}
                    {this.props.username && (
                        <button
                            className="tile"
                            onClick={this.redirect.bind(this, "feed")}
                        >
                            <img src={feed_image} className="tile" />
                            <p>Go to Feed</p>{" "}
                        </button>
                    )}

                    <p>Post of interest</p>
                    <ul>
                        <li>
                            <a href="/hive-150329/@leprechaun/vaccine-side-effects-1-out-of-225-gets-disabled">
                                New mRNA injuries at least 1 out of 225 people!
                                - Canadian Doctor
                            </a>
                            <br />

                            <iframe
                                width={iframeWidth}
                                height={iframeHeight}
                                scrolling={"no"}
                                frameBorder={0}
                                src="https://www.bitchute.com/embed/AotpcEAyufVy/"
                            ></iframe>

                            <a
                                href="https://www.bitchute.com/video/AotpcEAyufVy/"
                                target="bitchute"
                                className="nobr"
                            >
                                bitchute link
                            </a>
                        </li>

                        <li>
                            <a href="/health/@activistpost/dr-paul-thomas-targeted-by-medical-board-and-media-after-landmark-vaccine-study-interview">
                                Dr. Paul Thomas Targeted By Medicial Board &
                                Media After Landmark Vaccine Study
                            </a>
                            <iframe
                                scrolling={"no"}
                                frameBorder={0}
                                width={iframeWidth}
                                height={iframeHeight}
                                src="https://www.bitchute.com/embed/rBhtFuIZiA3x/"
                            />
                        </li>

                        <li ref={this.itemRefHQTreatment}>
                            <a href="https://www.bitchute.com/video/0rIIAs9UCMV6/">
                                DOCTOR'S ARE NOW MAKING THEMSELVES THE NEWS
                            </a>
                            <br />

                            <iframe
                                width={iframeWidth}
                                height={iframeHeight}
                                scrolling={"no"}
                                frameBorder={0}
                                src="https://www.bitchute.com/embed/0rIIAs9UCMV6/"
                            ></iframe>

                            <a
                                href="https://www.bitchute.com/video/0rIIAs9UCMV6/"
                                target="bitchute"
                                className="nobr"
                            >
                                bitchute link
                            </a>
                        </li>

                        <li>
                            <a href="/health/@activistpost/dr-paul-thomas-targeted-by-medical-board-and-media-after-landmark-vaccine-study-interview">
                                Dr. Paul Thomas Targeted By Medicial Board &
                                Media After Landmark Vaccine Study
                            </a>
                            <iframe
                                scrolling={"no"}
                                frameBorder={0}
                                width={iframeWidth}
                                height={iframeHeight}
                                src="https://www.bitchute.com/embed/rBhtFuIZiA3x/"
                            />
                        </li>

                        <li>
                            <a href="/health/@activistpost/it-s-here-first-court-case-against-mandatory-vaccination-attorney-interview">
                                First Court Case Against Mandatory Vaccination
                            </a>
                            <br />

                            <iframe
                                scrolling={"no"}
                                frameBorder={0}
                                width={iframeWidth}
                                height={iframeHeight}
                                src="https://www.bitchute.com/embed/ebHhNssHgQMd/"
                            />

                            <a
                                href="https://www.bitchute.com/video/ebHhNssHgQMd/"
                                target="bitchute"
                                className="nobr"
                            >
                                bitchute link
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default StartPage;
