import React from "react";
import {
    titleCase,
    APIForBlockchain,
    supportedBlockchains,
    keychainForBlockchain,
} from "./blockchains";

import "./permissions.css";
import steem_logo from "./steem.png";
import hive_logo from "./hive.png";
import blurt_logo from "./blurt.png";
import whaleshares_logo from "./whaleshares.png";

class UserPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showLogoutButtons: false, showOtherButtons: false };
    }

    openFeed() {
        document.location = "/@" + this.props.alias + "/feed";
    }

    openPermissions() {
        document.location = "/@" + this.props.alias + "/permissions";
    }

    toggleOtherButtons(e) {
        this.setState({ showOtherButtons: !this.state.showOtherButtons });
    }

    onClickShowLogouts() {
        this.setState({ showLogoutButtons: true });
    }

    onClickHideLogouts() {
        this.setState({ showLogoutButtons: false });
    }

    render() {
        if (this.props.alias)
            return (
                <span>
                    <span>
                        {this.props.alias ? (
                            <span>
                                <button
                                    onClick={this.toggleOtherButtons.bind(this)}
                                >
                                    {"@" + this.props.alias}
                                </button>
                                {this.state.showOtherButtons && (
                                    <span className="SideBySide">
                                        <button
                                            onClick={this.openFeed.bind(this)}
                                        >
                                            Feed
                                        </button>
                                        <button
                                            onClick={this.openPermissions.bind(
                                                this
                                            )}
                                        >
                                            Permissions
                                        </button>
                                    </span>
                                )}
                            </span>
                        ) : (
                            <span />
                        )}{" "}
                    </span>
                    {this.state.showLogoutButtons === false && (
                        <button onClick={this.onClickShowLogouts.bind(this)}>
                            Logout
                            {this.props.accounts.find(
                                (x) => x.provider === "steem"
                            ) && (
                                <img
                                    className="charlogo"
                                    alt="steem"
                                    src={steem_logo}
                                />
                            )}
                            {this.props.accounts.find(
                                (x) => x.provider === "hive"
                            ) && (
                                <img
                                    className="charlogo"
                                    alt="hive"
                                    src={hive_logo}
                                />
                            )}
                            {this.props.accounts.find(
                                (x) => x.provider === "blurt"
                            ) && (
                                <img
                                    className="charlogo"
                                    alt="blurt"
                                    src={blurt_logo}
                                />
                            )}
                            {this.props.accounts.find(
                                (x) => x.provider === "whaleshares"
                            ) && (
                                <img
                                    className="charlogo"
                                    alt="whaleshares"
                                    src={whaleshares_logo}
                                />
                            )}
                        </button>
                    )}
                    {this.state.showLogoutButtons && (
                        <button onClick={this.onClickHideLogouts.bind(this)}>
                            Hide these logout Buttons
                        </button>
                    )}
                    {this.state.showLogoutButtons &&
                        this.props.accounts.map((item) => {
                            return (
                                <button
                                    key={item.provider}
                                    data-provider={item.provider}
                                    onClick={this.props.onLogout}
                                >
                                    Logout for @{item.username}@{item.provider}
                                </button>
                            );
                        })}
                </span>
            );
        else return <span></span>;
    }
}
export default UserPane;
