import React, { MouseEvent, ChangeEvent, Fragment } from "react";
import "./App.css";
import StartPage from "./StartPage";
import RedBubbleAd from "./RedBubbleAd";
import UserSummary from "./UserSummary";
import SteemUserButton from "./SteemUserButton";
import UserPane from "./UserPane.js";
import CompletePostItemDisplayComponent from "./CompletePostItemDisplayComponent";
import Ticker from "./Ticker";
import { opinionComponent } from "./OpinionComponent";
import AgeWidget from "./AgeWidget";
import steem from "steem";
import hive from "@hiveio/hive-js";
import blurt from "@blurtfoundation/blurtjs";
import whaleshares from "@whaleshares/wlsjs";

import {
    titleCase,
    APIForBlockchain,
    supportedBlockchains,
    sitesForBlockchains,
} from "./blockchains";
import addCommas from "./addCommas";
import PostDisplayComponent from "./PostDisplayComponent";
import { Opinion } from "./Opinion";
import { post } from "./post";
import PermissionsComponent from "./PermissionsComponent";
import FilesListComponent from "./FilesListComponent";
import CryptoBeggingAd from "./CryptoBeggingAd";
import Account from "./account";
import moment from "moment";
const special_uris = ["introduction", "permissions", "files"];

// true iff to log all kinds of informational following information (other than errors)
// about following.
const logFollowing = true;

interface followDescription {
    follower: string;
    following: string;
    what: Array<string>;
}

interface FollowOp {
    follower: string;
    following: string;
    what: string;
}

interface AppState {
    page_profile_image: string|null;
    setCategoryCallCount: number;
    debug: boolean;

    loading: Array<string>;
    filesEnabled: boolean;
    uploadsEnabled: boolean;
    user_alias: string | null;

    user_accounts: Array<Account>;
    steem_username: null | string;
    user_space: any;
    last_update: number;
    tickerEnabled: boolean;

    showTicker: boolean; // show big full page ticker
    showPageList: boolean; // show pages as some list
    showSinglePage: boolean;
    showNotSupported: boolean;
    showPageUserMenu: boolean;

    // Get steem/hive posts according to some sorting mechanism
    // /sorting-order/topic

    // This page is about or by the user page_user
    page_user: string;
    page_user_edit: string;
    // empty array means empty. null means loading
    posts: Array<post> | null;
    followers: Array<string> | null;
    following: { [id: string]: Array<string> } | null;

    medianHistorySteemPrice: string | null;
    interval_ID: NodeJS.Timeout | null;
    error: null | string;
    //new_type_definition: string,
    new_dev_data: string;

    aspect: string;
    category: string;
    order: string;

    showFiles: boolean;
    files: Array<any>;
    loadingFiles: boolean;
    loggingIn: boolean;
    page_id: number|null
}

function filterToTickerState(old: AppState) {
    // first store the state we have for this URL.
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(old, document.title, document.location.pathname);
    const changes = {
        aspect: "ticker",
        showTicker: true,
        page_user: "",
        posts: null,
    };

    // eslint-disable-next-line no-restricted-globals
    history.pushState(Object.assign(old, changes), "Main Ticker", "/ticker");
    return changes;
}

// steem.api poo poos when you send 101 or more to the limit value.
const post_display_limit = 25;
// light-yellow const backgroundColor = '#ffff3f'
// lighter yellow... ;)

function toDateString(ISOdateString: string): string {
    const nowDate = new Date();
    const dateDate = new Date(ISOdateString + "Z");
    let humanReadableDateString: string;

    const interval: number = nowDate.valueOf() - dateDate.valueOf();
    if (interval >= 365 * 24 * 3600 * 1000) {
        // at least a year
        humanReadableDateString =
            "on " + moment(dateDate).format("MMMM D, YYYY");
    } else if (interval > 80 * 24 * 3600 * 1000) {
        // less than a year but more than 80 days.
        humanReadableDateString = moment(dateDate).format("on dddd MMMM D");
    } else if (
        nowDate.getMonth() != dateDate.getMonth() ||
        nowDate.getDate() > dateDate.getDate() + 1
    ) {
        humanReadableDateString =
            moment(dateDate).format("on dddd MMMM D") +
            " at " +
            moment(dateDate).format("HH:MM:SS");
    } else if (dateDate.getDate() + 1 === nowDate.getDate()) {
        humanReadableDateString =
            "Yesterday on " +
            moment(dateDate).format("dddd") +
            " at " +
            moment(dateDate).format("HH:MM:SS");
    } else {
        humanReadableDateString =
            "Today on " +
            moment(dateDate).format("dddd") +
            " at " +
            moment(dateDate).format("HH:MM:SS");
    }
    return humanReadableDateString;
}

class App extends React.Component<any, AppState> {
    // In session webstorage we will have a steem-login-transaction, which has a user's public key,
    // and is signed with its private key and expires ten minutes after it is created.
    // A new such transaction should be generated every five minutes.

    private browserPaneRef = React.createRef<HTMLDivElement>();
    private linksPaneRef = React.createRef<HTMLDivElement>();
    private uploadPaneRef = React.createRef<HTMLDivElement>();
    private userPaneRef = React.createRef<HTMLDivElement>();
    private navigationBarRef = React.createRef<HTMLElement>();
    private steem_keychain;
    private group_size = 1000;
    private call_count = 0;

    addOpinion(result) {
        // {id: "3ebf00d0cd32c2bc2f315ffaeb58cff0b1391aab", block_num: 53411426,
        // trx_num: 14, expired: false, ref_block_num: 65102, …}
        // block_num: 53411426
        // expiration: "2021-04-28T03:47:57"
        // expired: false
        // extensions: []
        // id: "3ebf00d0cd32c2bc2f315ffaeb58cff0b1391aab"
        // operations: [Array(2)]
        // ref_block_num: 65102
        // ref_block_prefix: 1407909127
        // signatures: ["204bc4e1ef6b3672044a01e3683408e3113a3e27901f48ea01…0fc1e083ab83f49f830b6c76e629f470ee7537cb10604acd9"]
        // trx_num: 14
        const {posts} = this.state;
        if (posts === null) {
          this.setState({error: 'internal error: adding opinion to null post'});
          return;
        }
        const first_post: post|undefined = posts[0];
        if (!first_post) {
          return;
        }
        const p = {...first_post};
        try {
            const op = result.operations[0];
            const optype: string = (op[0] as unknown) as string;
            const objdata: any = op[1] as unknown;
            if (optype === "vote") {
                // not quite right.  rshares, and reputation are not always 0.
            } // if
            let opinion: Opinion = {
                voter: objdata.voter,
                rshares: 0,
                percent: objdata.weight,
                reputation: 0,
            };
            let { active_votes } = p;
            active_votes = [opinion,
                            ...active_votes.filter(
                                (vote) => objdata.voter != vote.voter
                            )];
            p.active_votes = active_votes;
            p.last_update =  new Date().toISOString();
            posts[0] = p;
            this.setState({
                posts
            });
        } catch (e) {
            this.setState({
                debug: true,
            });
        }
    }

    callInputChangeLater(
        f: (e: ChangeEvent<HTMLInputElement>) => void,
        e: ChangeEvent<Element>
    ) {
        if (e.target instanceof HTMLInputElement) {
            const old_value: string = (e.target as HTMLInputElement).value;
            // E must be copied here b/c its first generation members will be overwritten.
            setTimeout(
                this.lateInputChangeCall.bind(
                    this,
                    old_value,
                    f,
                    Object.assign({}, e) as ChangeEvent<HTMLInputElement>
                ),
                3000
            );
        }
    }

    lateInputChangeCall(
        old_value: string,
        f: (e: ChangeEvent<HTMLInputElement>) => void,
        e: ChangeEvent<HTMLInputElement>
    ) {
        if (old_value === e.target.value) f(e);
    }

    constructor(props: any) {
        super(props);

        // leverage the history API
        window.addEventListener("popstate", this.restoreState.bind(this));

        const accounts_str = sessionStorage.getItem("accounts");
        let alias = sessionStorage.getItem("alias");
        let accounts: Array<any> = [];
        if (accounts_str !== null) {
            try {
                accounts = JSON.parse(accounts_str);
                if (alias === null && accounts.length) {
                    alias = accounts[0].username;
                }
            } catch (e) {
                console.log("Unable to parse '", accounts_str, "'");
            }
        }

        const steem_accounts = accounts.filter((x) => x.provider === "steem");
        const steem_username =
            steem_accounts.length === 0 ? null : steem_accounts[0].username;

        const base = {
            page_profile_image: null,
            setCategoryCallCount: 0,
            debug: false,
            loading: ["loading user data"],
            filesEnabled: false,
            uploadsEnabled: false,
            user_alias: accounts.length ? accounts[0].username : "",
            user_accounts: accounts,
            steem_username: steem_username,
            user_space: null,

            showTicker: false,
            showPageList: false,
            showSinglePage: false,

            last_update: 1585256014,
            tickerEnabled: true,
            page_user: "",
            page_user_edit: "",

            posts: null,
            followers: null,
            following: this.loadFollowing.bind(this)(accounts),

            new_dev_data: "",

            medianHistorySteemPrice: null,
            interval_ID: null,
            error: "",

            aspect: "",
            category: "",
            order: "trending",

            showNotSupported: false,
            showFiles: false,
            showPageUserMenu: false,
            files: [],
            loadingFiles: false,
            loggingIn: false,
            page_id: null,

        };

        this.state = Object.assign(
            base,
            this.setStateFromURI(document.location.pathname, base)
        );
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.showBrowser = this.showBrowser.bind(this);
        this.showUserPane = this.showUserPane.bind(this);
        this.showUpload = this.showUpload.bind(this);
        this.showLinks = this.showLinks.bind(this);
        this.handleGetCurrentMedianHistoryPrice = this.handleGetCurrentMedianHistoryPrice.bind(
            this
        );
        this.handleDumpAPIData = this.handleDumpAPIData.bind(this);
        this.renderPostListItem = this.renderPostListItem.bind(this);
        this.handleGetFollowers = this.handleGetFollowers.bind(this);
        this.renderFollowingOpinion = this.renderFollowingOpinion.bind(this);
        this.setTickerState = this.setTickerState.bind(this);
        this.setIntroState = this.setIntroState.bind(this);
        this.debugHandler = this.debugHandler.bind(this);
        this.toggleDebug = this.toggleDebug.bind(this);
        this.tick = this.tick.bind(this);
        this.getFeed = this.getFeed.bind(this);
        this.popStateHandler = this.popStateHandler.bind(this);
        this.getBlog = this.getBlog.bind(this);
        this.request_posts = this.request_posts.bind(this);
        this.setCategoryWatchDog = this.setCategoryWatchDog.bind(this);
        this.setPageUser = this.setPageUser.bind(this);
        this.pageUserEditChanged = this.pageUserEditChanged.bind(this);
        this.setStateFromURI = this.setStateFromURI.bind(this);
        this.setError = this.setError.bind(this);
        window.onpopstate = this.popStateHandler;

        if (logFollowing) console.log("Loading in followers  in constructor");

        if (this.state.steem_username) {
            steem.api.getFollowers(
                this.state.steem_username,
                null,
                "blog",
                this.group_size,
                this.handleGetFollowers
            );
        }
    }

    // careful.  called from constructor
    loadFollowing(
        accounts: Array<Account>
    ): { [id: string]: Array<string> } | null {
        let following: { [id: string]: Array<string> } | null;
        try {
            if (logFollowing)
                console.log("Loading following from localStorage");
            following = JSON.parse(
                window.localStorage.getItem("following") as string
            );
            if (logFollowing && !following) console.log("It's empty.");
        } catch {
            if (logFollowing) console.log("Loading failed.");
            following = null;
        }
        for (const account of accounts) {
            const handleFollowing = this.handleGetFollowing.bind(
                this,
                account.provider,
                account.username,
                []
            );

            const last = window.localStorage.getItem(
                account.provider + "_last_followed"
            );
            if (logFollowing) {
                console.log(
                    "Attempting to look up followers on",
                    account.provider,
                    "starting with ",
                    last
                );
            }
            APIForBlockchain(account.provider).api.getFollowing(
                account.username,
                last,
                "blog",
                this.group_size,
                handleFollowing
            );
        } // for;
        return following;
    }

    setStateFromURI(uri: string, old_state: AppState) {
        const leg = "([a-z0-9_\\.-]+)";
        const order_leg =
            "(active|trending|promoted|hot|created|payout|payout_comments|muted)";
        const user_url_pattern = new RegExp("/@" + leg + "(/(.*))?");
        const post_url_pattern = new RegExp("/" + leg + "/@" + leg + "/" + leg);
        const ordered_with_tags_url_pattern = new RegExp(
            "/" + order_leg + "(/" + leg + "?)?"
        );
        // hack when below doesn't work:

        const ordered_url_pattern = new RegExp("/(?<order>" + order_leg + ")");
        const pathname: string = uri;
        let user_to_view: string = "";
        let aspect: string = "";
        let page_id: null | number = null;
        // null for n/a, 0 or loading, -1 for invalid, some
        // positive number for a real account
        let showTicker: boolean = false;
        let showPageList: boolean = false;
        let showSinglePage: boolean = false;
        let showFiles: boolean = false;
        let category: string = "";
        let order: string = old_state.order || "trending";
        let match_results;
        let posts: Array<post> | null = null;
        const logBranch = true;
        if (uri === "/ticker" || uri === "/ticker.html") {
            aspect = "ticker";
            showTicker = true;
            posts = [];
        } else if (
            (match_results = pathname.match(ordered_with_tags_url_pattern)) !=
            null
        ) {
            order = match_results[1];
            // tag
            category = match_results[3];
            showPageList = true;
            if (logBranch) console.log("ordered with tags");
        } else if ((match_results = pathname.match(post_url_pattern)) != null) {
            if (logBranch) console.log("post_url_pattern");
            aspect = match_results[3];
            user_to_view = match_results[2];
            category = match_results[1];
            showSinglePage = true;
        } else if ((match_results = pathname.match(user_url_pattern)) != null) {
            // a page about user_to_view or some aspect thereof
            if (logBranch) console.log("user url pattern");
            user_to_view = match_results[1];
            page_id = 0; // loading
            if (match_results.length > 2) aspect = match_results[3];
            showPageList = true;
            if (special_uris.includes(aspect)) posts = [];
        } else if (pathname === "/" || pathname === "/introduction") {
            user_to_view = "";
            showPageList = false;
            posts = null;
            category = "";
            aspect = "";
            showTicker = false;
            showPageList = false;
            showSinglePage = false;
            showFiles = false;
        } else if (
            (match_results = pathname.match(ordered_url_pattern)) != null
        ) {
            order = match_results.groups["order"];
        } else if (pathname === "/files") {
            aspect = "files";
        } else {
            aspect = pathname.slice(1);
            order = "error";
        }

        let error: string | null = null;
        if (order === "error") {
            error = "Unknown URL scheme";
        }

        if (aspect === undefined) {
            aspect = "";
        }
        if (order === undefined) {
            order = "";
        }
        if (category === undefined) {
            category = "";
        }
        const accounts_str = sessionStorage.getItem("accounts");
        let alias = sessionStorage.getItem("alias");
        let accounts: Array<any> = [];
        if (accounts_str !== null) {
            try {
                accounts = JSON.parse(accounts_str);
                if (alias === null && accounts.length) {
                    alias = accounts[0].username;
                }
            } catch (e) {
                console.log("Unable to parse '", accounts_str, "'");
            }
        }
        const steem_accounts = accounts.filter((x) => x.provider === "steem");
        const steem_username =
            steem_accounts.length === 0 ? null : steem_accounts[0].username;
        return {
            setCategoryCallCount: 0,
            user_space: null,

            user_alias: alias,
            user_accounts: accounts,
            steem_username: steem_username,

            showSinglePage: showSinglePage,
            showTicker: showTicker,
            showPageList: showPageList,
            tickerEnabled: true,
            page_user: user_to_view,
            posts: posts,
            new_dev_data: "",
            aspect: aspect,
            category: category,
            order: order,
            showFiles: aspect == "files",
        };
    }

    popStateHandler(e: PopStateEvent): void {
        console.log("popStateHandler(", e, ") called.");
        // Let the login aspects of state follow the user backward
        const user_accounts: Array<Account> = this.state.user_accounts;
        const steem_username: null | string = this.state.steem_username;
        const followers = this.state.followers;
        const following = this.state.following;
        const user_alias = this.state.user_alias;
        const { user_space } = this.state;
        const loading = this.state.loading;
        if (e === null) {
            return;
        }
        const new_stuff = {
            user_accounts,
            steem_username,
            followers,
            following,
            user_alias,
            user_space,
            loading,
        };
        if (e.state === null) {
            this.setState(new_stuff);

            return;
        }

        if (
            JSON.stringify([
                e.state.user_accounts,
                e.state.steem_username,
                e.state.followers,
                e.state.following,
                e.state.user_alias,
                e.state.user_space,
            ]) !=
            JSON.stringify([
                this.state.user_accounts,
                this.state.steem_username,
                this.state.followers,
                this.state.following,
                this.state.user_alias,
                this.state.user_space,
            ])
        ) {
            console.log(document.location.href);
            this.setURL(document.location.href, document.title);
        } else {
            const old_state = { ...e.state };
            const state = Object.assign(old_state, new_stuff);

            this.setState(state);
        }
    }

    restoreState(event) {
      // Filter what gets restored so that logged in users
      // don't get logged out by clicking the back button.
        const prev_state: AppState = event.state;
        this.setState({
    setCategoryCallCount: prev_state.setCategoryCallCount,

    filesEnabled: prev_state.filesEnabled,
    uploadsEnabled: prev_state.uploadsEnabled,
    user_space: prev_state.user_space,
    last_update: prev_state.last_update,
    tickerEnabled: prev_state.tickerEnabled,

    showTicker: prev_state.showTicker,
    showPageList: prev_state.showPageList,
    showSinglePage: prev_state.showSinglePage,
    showNotSupported: prev_state.showNotSupported,
    showPageUserMenu: prev_state.showPageUserMenu,

    // Get steem/hive posts according to some sorting mechanism
    // /sorting-order/topic

    // This page is about or by the user page_user
    page_user: prev_state.page_user,
    page_user_edit: prev_state.page_user_edit,
    // empty array means empty. null means loading
    posts: prev_state.posts,
    followers: prev_state.followers,
    following: prev_state.following,

    medianHistorySteemPrice: prev_state.medianHistorySteemPrice,
    interval_ID: prev_state.interval_ID,
    error: prev_state.error,
    //new_type_definition: prev_state.new_type_definition,
    new_dev_data: prev_state.new_dev_data,

    aspect: prev_state.aspect,
    category: prev_state.category,
    order: prev_state.order,

    showFiles: prev_state.showFiles,
    files: prev_state.files,
    loadingFiles: false,
    page_id: prev_state.page_id,


        });

    }

    getBlog(username: string, e: MouseEvent): void {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
            this.state,
            "Steemfiles - @" + username + "'s blog",
            "/@" + username + "/blog"
        );
        this.setState(
            this.setStateFromURI.bind(this, "/@" + username + "/" + "blog")
        );
        this.request_posts(username, "blog", this.state.category, []);
    }

    getPermissions(username: string, e: MouseEvent): void {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(
            this.state,
            document.title,
            document.location.href
        );

        // Now set a new State
        this.setState({
            posts: null,
            page_user: username,
            aspect: "permissions",
            category: "",
        });
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
            this.state,
            username + "'s permissions",
            "/@" + username + "/permissions"
        );
    }

    getFeed(username: string, e: MouseEvent): void {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
            this.state,
            "Steemfiles - @" + username + "'s feed",
            "/@" + username + "/feed"
        );
        this.setState(
            this.setStateFromURI.bind(this, "/@" + username + "/feed")
        );
        this.request_posts(username, "feed", this.state.category, []);
    }

    handleGetFollowing(
        blockchain: string,
        username: string,
        new_following: Array<string>,
        error: any,
        fds: Array<followDescription>
    ) {
        this.setState({ new_dev_data: "handleGetFollowing called" });
        if (error !== null || fds === null) {
            this.setState({
                new_dev_data:
                    "handleGetFollowing error:" + JSON.stringify(error),
                loading: this.state.loading.filter(function (x) {
                    return x !== "loading user data";
                }),
            });

            console.log("handleGetFollowing Error:" + error);
            return;
        }
        if (error === undefined || fds === undefined) {
            console.log("typecheck failed");
            alert("Typecheck failed.");
        }
        var last: string = "";
        new_following = [...new_following, ...fds.map((fd) => fd.following)];
        if (logFollowing)
            console.log(
                "Blockchain: " +
                    blockchain +
                    " (new following loaded:=" +
                    fds.length +
                    ") + (following already loaded=" +
                    (this.state.following !== null
                        ? Object.keys(this.state.following).length
                        : "null")
            );
        if (fds.length < this.group_size) {
            let merged_following = {};
            if (this.state.following)
                merged_following = { ...this.state.following };

            for (const following of new_following) {
                if (merged_following[following]) {
                    // defined
                    if (merged_following[following].includes(blockchain)) {
                        continue;
                    }
                    merged_following[following].push(blockchain);
                } else {
                    merged_following[following] = [blockchain];
                }
            }
            // eslint-disable-next-line no-restricted-globals
            window.localStorage.setItem(
                blockchain + "_last_followed",
                new_following[0]
            );
            // eslint-disable-next-line no-restricted-globals
            window.localStorage.setItem(
                "following",
                JSON.stringify(merged_following)
            );
            this.setState({
                loading: this.state.loading.filter(function (x) {
                    return x !== "loading user data";
                }),
                following: merged_following,
            });
            // we are done.  Don't get any more.
            if (logFollowing) console.log("following now:", merged_following);
        } else {
            last = fds[fds.length - 1].following;
            APIForBlockchain(blockchain).api.getFollowing(
                username,
                last,
                "blog",
                this.group_size,
                this.handleGetFollowing.bind(
                    this,
                    blockchain,
                    username,
                    new_following
                )
            );
        }
    }

    handleGetFollowers(error: any, fds: Array<followDescription>) {
        if (error !== null || fds === null) {
            this.setState({
                loading: this.state.loading.filter(function (x) {
                    return x !== "loading user data";
                }),
            });
            console.log("handleGetFollowers Error:", error);
            return;
        }

        let follower_list = fds.map((x) => x.follower).sort();
        var username: string = "";
        var last: string = "";
        var state_first: string = "";
        try {
            username = fds[0].following;
            last = fds[fds.length - 1].follower;
            /*
            this.setState({
                new_dev_data:
                    "handleGetFollowers() got: " +
                    fds[0].follower +
                    ".." +
                    last +
                    " from API",
            });*/
            if (fds.length < 2) {
                // eslint-disable-next-line no-restricted-globals
                history.replaceState(
                    this.state,
                    document.title,
                    document.location.href
                );
                sessionStorage.setItem(
                    "followers",
                    JSON.stringify(this.state.followers)
                );
                this.setState({
                    loading: this.state.loading.filter(function (x) {
                        return x !== "loading user data";
                    }),
                });
                // we are done.  Don't get any more.
                return;
            }
        } catch (e) {
            return;
        }

        if (this.state.followers != null && this.state.followers.length) {
            state_first = this.state.followers[0];
            follower_list = [...this.state.followers].concat(follower_list);
        }
        this.setState({
            /*            new_dev_data:
                "follower state will now have:" + state_first + " to " + last,*/
            followers: follower_list,
        });

        for (const bc of supportedBlockchains()) {
            APIForBlockchain(bc.name).api.getFollowers(
                username,
                last,
                "blog",
                this.group_size,
                this.handleGetFollowers
            );
        }
    }

    pageUserEditChanged(e: ChangeEvent) {
        this.setState({
            page_user: (e.target as HTMLInputElement).value,
            page_user_edit: (e.target as HTMLInputElement).value,
        });
    }

    mergePosts(
        pathname: string,
        blockchain: string,
        error: {
            name?: string;
            code?: number;
            data?: {
                code: number;
                name: string;
                message: string;
                stack: Array<{
                    context: {
                        level: string;
                        file: string;
                        line: number;
                        method: string;
                        hostname: string;
                        timestamp: string;
                    };
                    format: string;
                    data: any;
                }>;
            };
        },
        result: Array<any>
    ): void {
        console.log("MergePosts(" + pathname + ", " + blockchain + "...");
        if (
            pathname.length > 1 &&
            pathname[0] == pathname[1] &&
            pathname[1] == "/"
        ) {
            console.log(
                "MergePosts incorrectly called with double slash:",
                pathname,
                "!=",
                document.location.pathname
            );
            pathname = pathname.slice(1);
        }
        if (pathname !== document.location.pathname) {
            // not relevant to us now.
            console.log(pathname, "!=", document.location.pathname);
            return;
        }
        if (result === null) {
            result = [];
        }
        if (error) {
            console.log(this.describe(error));
            console.log(
                "Error trying to pull posts for " + blockchain,
                JSON.stringify(error)
            );

            if (error && error.data && error.data.message)
              this.setState({
                  error: (blockchain + ': ' + error.data.message),
                  loading: this.state.loading.filter(function (x) {
                      return x !== "loading posts";
                  }),
              });
            else if (error && error.name)
              this.setState({
                  error: blockchain + ': ' + error.name,
                  loading: this.state.loading.filter(function (x) {
                      return x !== "loading posts";
                  }),
              });
            else
              this.setState({
                  error: '',
                  loading: this.state.loading.filter(function (x) {
                      return x !== "loading posts";
                  }),
              });

            return;
        } else {
            console.log("Attempting to merging posts_map from " + blockchain);
        }

        this.setState(function (old_state) {
            if (!old_state) {
                // shouldn't happen but it does
                return null;
            }
            let post_map = new Map<string, post>();
            let loading: Array<string> = old_state.loading.filter(
                (x) => x !== "loading posts"
            );
            if (old_state.posts !== null) {
                for (const post of old_state.posts) {
                    if (!post.permlink)
                        alert("Post doens't have a non-zero permlink");
                    post_map.set(post.permlink, post);
                }
            }
            for (let r of result) {
                r.active_votes = r.active_votes.map(function (o) {
                    o.voter += "@" + blockchain;
                    return o;
                });
                if (!r.permlink) alert("Post has a falsy permlink");
                let n;
                if ((n = post_map.get(r.permlink))) {
                    if (n.blockchains.includes(blockchain)) {
                        // we did this post before on this blockchain skip
                        continue;
                    }
                    r.active_votes = n.active_votes.concat(r.active_votes);
                    n.active_votes = r.active_votes;
                    r.blockchains = n.blockchains;
                    if (r.last_update > n.last_update) {
                        // r is newer than n
                        // replace n with r
                        post_map.set(r.permlink, r);
                    } else {
                        // leave n where it is in post_map
                    }
                } else {
                    r.blockchains = [];
                    post_map.set(r.permlink, r);
                }
                r.blockchains.push(blockchain);
            }
            let post_array: Array<post> = Array.from(
                post_map,
                ([key, value]) => value
            );
            if (
                old_state &&
                (old_state.order === "created" ||
                    (old_state.page_user != "" && old_state.aspect === "feed"))
            ) {
                post_array = post_array.sort(function (a, b) {
                    if (a.created > b.created) {
                        return -1;
                    } else if (a.created < b.created) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (old_state.order === "trending") {
                post_array = post_array.sort(function (a, b) {
                    if (a.net_rshares > b.net_rshares) {
                        return -1;
                    } else if (a.net_rshares < b.net_rshares) {
                        return 1;
                    }
                    return 0;
                });
            }
            loading = loading.filter((x) => x !== "loading posts");
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(
                Object.assign(Object.assign({}, old_state as any), {
                    posts: post_array,
                    loading: loading,
                }),
                document.title,
                document.location.href
            );

            return { posts: post_array, loading: loading };
        });
    }

    handleDumpAPIData(error, result) {
        console.log("data dump", error, result);
        return (
            <div>
                {error}
                {result}
            </div>
        );
    }

    handleGetCurrentMedianHistoryPrice(
        error,
        result: { base: string; quote: string } | null
    ) {
        if (error) {
            console.log("handleGetCurrentMedianHistoryPrice:", error);
            this.setState(
                {loading: this.state.loading.filter(function (x) {
                    return x === "loading prices";
                })}
            );
            return;
        }

        if (result === null || result === undefined) return;
        this.setState({
            loading: this.state.loading.filter(function (x) {
                return x === "loading prices";
            }),
            medianHistorySteemPrice: result.base,
        });

        // eslint-disable-next-line no-restricted-globals
        history.replaceState(
            this.state,
            document.title,
            document.location.href
        );
    }

    debugHandler(err, res) {
        if (res === undefined) {
            console.log(this.describe(err));
            this.setState({ new_dev_data: "error:" + JSON.stringify(err) });
            return;
        }
        console.log(this.describe(res));
        this.setState({
            new_dev_data: "res:" + JSON.stringify(res).slice(0, 200),
        });
    }

    // Handles content into reuslts (which is a post).
    //
    //
    handleContent(
        blockchain: string,
        page_user: string,
        aspect: string,
        error: any,
        results: post | null
    ): void {
        // If the call itself succeeds but there is no post to return,
        // it returns a post that is full of empty strings and id=0.
        if (!this || !blockchain || !page_user || !aspect)
            throw Error("Passed w/o bind error");
        const posts: Array<post> | null = this.state.posts;

        // if truthy, a post with the same permlink as this one is going to be has been loaded.
        const post_with_same_permlink_already_loaded =
            posts && posts.length && posts[0].permlink.endsWith(aspect);

        const loading = this.state.loading.filter((x) => x !== "loading post");

        if (error) {
            if (!post_with_same_permlink_already_loaded) {
                this.setState({
                    posts: [],
                    error: JSON.stringify(error),
                    loading: loading,
                });
            }
        } else if (results) {
            if (results.id !== 0) {
                let blockchains: Array<string> = [];
                let new_post: post = results;
                if (posts && post_with_same_permlink_already_loaded) {
                    blockchains = posts[0].blockchains;
                    if (posts[0].last_update > results.last_update) {
                        new_post = posts[0];
                    }
                    blockchains.push(blockchain);
                } else {
                    blockchains = [blockchain];
                }
                new_post["blockchains"] = blockchains;
                this.setState({
                    showNotSupported: false,
                    showPageList: false,
                    showTicker: false,
                    showSinglePage: true,
                    posts: [new_post],
                    page_user: results["author"],
                    error: "",
                    loading: loading,
                });
                // eslint-disable-next-line no-restricted-globals
                history.replaceState(
                    this.state,
                    "Steemfiles - " + new_post.title,
                    document.location.href
                );
            }
        } else {
            this.setState({
                error: "Invalid values passed to handler",
                loading: loading,
            });
        }
    } // handleContent

    describe(result: any): string {
        let out: string = "";
        if (typeof result === "object") {
            if (result.length === undefined) {
                out = "{\n";
                for (const key in result) {
                    out += key + " : " + this.describe(result[key]) + ",\n";
                }
                out += "}\n";
                return out;
            }
            try {
                if (result.length === 0) return "Array<any>";
                if (result[0] !== undefined)
                    return "Array<" + this.describe(result[0]) + ">";
            } catch (e) {}
        }

        return typeof result;
    }
    //         if (this.state.posts && this.state.posts.length) {
    //    	start_author="steemit", start_permlink="firstpost"
    //    }
    tick(): void {
        console.log("tick() called: " + document.location.href);
        this.request_posts(
            this.state.page_user,
            this.state.aspect,
            this.state.category,
            this.state.posts ?? []
        );
    }

    onMouseOverPostItem(pi, e) {
        if (this.state.posts === null || 4 * pi > 3 * this.state.posts.length)
            this.request_posts(
                this.state.page_user,
                this.state.aspect,
                this.state.category,
                this.state.posts ?? []
            );
    }

    request_posts(page_user, aspect, category, posts: Array<post>) {
        let query: {
            tag: any | undefined;
            limit: number;
            start_author?: string;
            start_permlink?: string;
        };
        if (category === "") query = { tag: null, limit: post_display_limit };
        else query = { tag: category, limit: post_display_limit };
        if (posts.length) {
            query["start_author"] = posts[posts.length - 1].author;
            query["start_permlink"] = posts[posts.length - 1].permlink;
        }
        console.log("request_posts:", query);
        let loading: Array<string> = this.state.loading;
        console.log(
            "Request Posts called with '" +
                page_user +
                "', '" +
                aspect +
                "' , '" +
                category +
                "'.  Order is '" +
                this.state.order +
                "'"
        );
        if (special_uris.includes(aspect)) return;

        if (page_user !== "") {
            if (aspect === "blog" || aspect === "") {
                console.log("getting discussions by Author....");
                for (const bc of supportedBlockchains()) {
                    APIForBlockchain(
                        bc.name
                    ).api.getDiscussionsByAuthorBeforeDate(
                        page_user,
                        null,
                        null,
                        post_display_limit,
                        this.mergePosts.bind(
                            this,
                            document.location.pathname,
                            bc.name
                        )
                    );
                }
                loading.push("loading posts");
            } else if (aspect === "feed") {
                console.log("getting feed....");
                for (const bc of supportedBlockchains()) {
                    APIForBlockchain(bc.name).api.getDiscussionsByFeed(
                        { tag: page_user, limit: post_display_limit },
                        this.mergePosts.bind(
                            this,
                            document.location.pathname,
                            bc.name
                        )
                    );
                }
                loading.push("loading posts");
            } else if (
                category !== "" &&
                aspect !== "" &&
                !this.state.loading.includes("loading post")
            ) {
                console.log("getting content...");
                for (const bc of supportedBlockchains()) {
                    APIForBlockchain(bc.name).api.getContent(
                        page_user,
                        aspect,
                        this.handleContent.bind(
                            this,
                            bc.name,
                            page_user,
                            aspect
                        )
                    );
                }
                loading.push("loading post");
            } else {
                console.log("URL form not supported.");
                this.setState({
                    posts: [],
                    showNotSupported: true,
                });
            }
        } else if (this.state.order === "created") {
            for (const bc of supportedBlockchains()) {
                APIForBlockchain(bc.name).api.getDiscussionsByCreated(
                    query,
                    this.mergePosts.bind(
                        this,
                        document.location.pathname,
                        bc.name
                    )
                );
            }
            loading.push("loading posts");
        } else if (this.state.order === "hot") {
            for (const bc of supportedBlockchains()) {
                APIForBlockchain(bc.name).api.getDiscussionsByHot(
                    query,
                    this.mergePosts.bind(
                        this,
                        document.location.pathname,
                        bc.name
                    )
                );
            }
            loading.push("loading posts");
        } else if (this.state.order === "promoted") {
            for (const bc of supportedBlockchains()) {
                APIForBlockchain(bc.name).api.getDiscussionsByPromoted(
                    query,
                    this.mergePosts.bind(
                        this,
                        document.location.pathname,
                        bc.name
                    )
                );
            }
            loading.push("loading posts");
        } else if (this.state.order === "trending") {
            console.log("Attempting to get all trending posts");
            for (const bc of supportedBlockchains()) {
                APIForBlockchain(bc.name).api.getDiscussionsByTrending(
                    query,
                    this.mergePosts.bind(
                        this,
                        document.location.pathname,
                        bc.name
                    )
                );
            }
            loading.push("loading posts");
        } else if (this.state.order === "payout") {
            for (const bc of supportedBlockchains()) {
                APIForBlockchain(bc.name).api.getDiscussionsByCashout(
                    query,
                    this.mergePosts.bind(
                        this,
                        document.location.pathname,
                        bc.name
                    )
                );
            }
            loading.push("loading posts");
        } else if (this.state.order === "payout_comments") {
            for (const bc of supportedBlockchains()) {
                APIForBlockchain(bc.name).api.getDiscussionsByComments(
                    query,
                    this.mergePosts.bind(
                        this,
                        document.location.pathname,
                        bc.name
                    )
                );
            }
            loading.push("loading posts");
        } else if (category !== "" && this.state.order === "muted") {
            for (const bc of supportedBlockchains()) {
                if (APIForBlockchain(bc.name).api.getDiscussionsByMuted) {
                    APIForBlockchain(bc.name).api.getDiscussionsByMuted(
                        query,
                        this.mergePosts.bind(
                            this,
                            document.location.pathname,
                            bc.name
                        )
                    );
                    loading.push("loading posts");
                }
            }
            if (
                !steem.api.getDiscussionsByMuted &&
                !hive.api.getDiscussionsByMuted
            ) {
                this.setState({ error: "Getting Muted is not supported." });
            }
        } else if (this.state.order === "active") {
            for (const bc of supportedBlockchains()) {
                if (APIForBlockchain(bc.name).api.getDiscussionsByActive) {
                    APIForBlockchain(bc.name).api.getDiscussionsByActive(
                        query,
                        this.mergePosts.bind(
                            this,
                            document.location.pathname,
                            bc.name
                        )
                    );
                    loading.push("loading posts");
                }
            }
            loading.push("loading posts");
        } else {
            this.setState({
                posts: [],
                error: "This kind of URL is not supported",
                new_dev_data: "Error in componentDidMount(): case not handled",
            });
        }
    }

    componentDidMount() {
        let loading: Array<string> = this.state.loading;

        this.request_posts(
            this.state.page_user,
            this.state.aspect,
            this.state.category,
            []
        );

        if (this.state.showFiles) {
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        history.replaceState(
            this.state,
            document.title,
            document.location.href
        );
    }

    handleFileList(file_data) {
        this.setState({ files: file_data["files"], loadingFiles: false });
    }

    componentWillUnmount() {
        if (this.state.interval_ID) clearInterval(this.state.interval_ID);
    }

    scroll(ref) {
        // Disabled.

        if (this.navigationBarRef.current == null) {
            console.log("No navbar! Cannot scroll!");
            return;
        }
        if (ref.current == null) {
            console.log("Cannot find where we are scrolling to!");
            return;
        }
        var rect = ref.current.getBoundingClientRect();
        console.log(rect);
    }

    showBrowser() {
        if (this.browserPaneRef.current != null) {
            this.browserPaneRef.current.scrollIntoView(true);
            this.scroll(this.browserPaneRef);
        }
    }

    showLinks() {
        this.scroll(this.linksPaneRef);
    }

    showUserPane() {
        this.scroll(this.userPaneRef);
    }

    showUpload() {
        this.scroll(this.uploadPaneRef);
    }

    handleLogin(
        providers_p: string | Array<string>,
        user: string,
        role: string,
        keyStorageLocation: 'sessionStorage' | 'keychain' | 'whalevault'
    ) {
        console.log("Logging in", providers_p, user);
        if (this.call_count > 10) {
          console.log("Returning early due to recursion limit.");
          return;
        }
        ++this.call_count;
        let providers: Array<string>;
        try {
            let state = this.state;
            let user_accounts = state.user_accounts;
            if (typeof providers_p === "object") {
                providers = providers_p as Array<string>;
            } else {
                providers = [providers_p as string];
            }
            console.log({user_accounts, providers});
            const group_size = this.group_size;
            const handleFollowers = this.handleGetFollowers.bind(this);
            for (const provider of providers) {
                if ((() => {
                    for (const acc of user_accounts) {
                      // maybe this isn't such a bad idea.
                      if (acc.provider === provider) {
                          // already logged in for this provider...
                          return true;
                      }
                    } // for
                    return false;
                })()) {
                  continue;
                }

                // make a copy so it registers with setState...
                user_accounts = [...user_accounts];
                user_accounts.push({
                    provider: provider,
                    username: user,
                    role: role,
                    keyStorageLocation,
                });
            } // for

            sessionStorage.setItem("accounts", JSON.stringify(user_accounts));
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(
                this.state,
                document.title,
                document.location.href
            );
            if (providers.includes("steem")) {
                steem.api.getFollowers(
                    user,
                    null,
                    "blog",
                    group_size,
                    handleFollowers
                );
            }

            console.log({user_accounts});

            this.setState({
                user_accounts,
                steem_username: user,
                user_alias: user,
                following: this.loadFollowing.bind(this, user_accounts)(),
            });
        } finally {
            --this.call_count;
        }
    }

    handleLogout(event) {
        let target = event.target;
        let provider = target.getAttribute("data-provider");
        sessionStorage.removeItem(provider + "-id");
        sessionStorage.removeItem(provider + "-private-key");
        sessionStorage.removeItem(provider + "-public-key");
        sessionStorage.removeItem(provider + "-role");

        this.setState(function (old_state) {
            let alias = old_state.user_alias;
            let accounts = old_state.user_accounts;
            accounts = accounts.filter(function (value, index, a) {
                return value["provider"] !== provider;
            });
            accounts = [...accounts];
            if (accounts.length === 0) {
                alias = null;
            }

            sessionStorage.setItem("accounts", JSON.stringify(accounts));
            sessionStorage.removeItem("following");
            sessionStorage.removeItem("follows");

            let state = Object.assign({}, old_state);
            let new_following = old_state.following;
            for (const username in new_following) {
                if (
                    (new_following[username] = new_following[username].filter(
                        (bc) => bc != provider
                    )).length === 0
                ) {
                    delete new_following[username];
                }
            }

            return {
                user_alias: alias,
                user_accounts: accounts,
                steem_username: null,
                user_space: null,
                followers: null,
                following: new_following,
            };
        });
    }

    toggleDebug() {
        this.setState({ debug: !this.state.debug });
    }

    setTickerState() {
        this.setState(filterToTickerState);
    }

    setIntroState(ev: any) {
        ev.preventDefault();
        if (this.state.page_user) {
            this.setState({
                posts: [],
                category: "",
                aspect: "introduction",
                showNotSupported: false,
                showTicker: false,
                showFiles: false,
                showPageList: false, // show pages as some list
                showSinglePage: true,
            });
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(
                this.state,
                "Introduction",
                "/@" + this.state.page_user + "/introduction"
            );
        } else {
            this.setState({
                posts: null,
                category: "",
                aspect: "",
                showTicker: false,
                showPageList: false, // show pages as some list
                showSinglePage: false,
                showFiles: false,
            });
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(this.state, "Introduction", "/introduction");
        }
    }

    renderSomething(something) {
        return false;
    }

    renderPostListItemNerds(p: any) {
        return (
            <CompletePostItemDisplayComponent
                post_id={p.post_id}
                author={p.author}
                permlink={p.permlink}
                category={p.category}
                title={p.title}
                body={p.body}
                json_metadata={p.json_metadata}
                created={p.created}
                last_update={p.last_update}
                depth={p.depth}
                children={p.children}
                net_rshares={p.net_rshares}
                last_payout={p.last_payout}
                cashout_time={p.cashout_time}
                total_payout_value={p.total_payout_value}
                curator_payout_value={p.curator_payout_value}
                pending_payout_value={p.pending_payout_value}
                promoted={p.promoted}
                replies={p.replies}
                body_length={p.body_length}
                active_votes={p.active_votes}
                author_reputation={p.author_reputation}
                parent_author={p.parent_author}
                parent_permlink={p.parent_permlink}
                url={p.url}
                root_title={p.root_title}
                beneficiaries={p.beneficiaries}
                max_accepted_payout={p.max_accepted_payout}
                percent_steem_dollars={p.percent_steem_dollars || 0}
                percent_hbd={p.percent_hbd || 0}
            />
        );
        /*    return <ul>
<li key='post_id'>post_id => {p.post_id}</li>
<li key='author'>author => {p.author}</li>
<li key='permlink'>permlink => {p.permlink}</li>
<li key='category'>category => {p.category}</li>
<li key='title'>title => {p.title}</li>
<li key='body'>body => {p.body}</li>
<li key='json_metadata'>json_metadata => {p.json_metadata}</li>
<li key='created'>created => {p.created}</li>
<li key='last_update'>last_update => {p.last_update}</li>
<li key='depth'>depth => {p.depth}</li>
<li key='children'>children => {p.children}</li>
<li key='net_rshares'>net_rshares => {p.net_rshares}</li>
<li key='last_payout'>last_payout => {p.last_payout}</li>
<li key='cashout_time'>cashout_time => {p.cashout_time}</li>
<li key='total_payout_value'>total_payout_value => {p.total_payout_value}</li>
<li key='curator_payout_value'>curator_payout_value => {p.curator_payout_value}</li>
<li key='pending_payout_value'>pending_payout_value => {p.pending_payout_value}</li>
<li key='promoted'>promoted => {p.promoted}</li>
<li key='replies'>replies => {p.replies}</li>
<li key='body_length'>body_length => {p.body_length}</li>
<li key='active_votes'>active_votes =>
	<ol>
		{p.active_votes.map(
			(x) => (<li>(voter:{x.voter}, rshares:{x.rshares}, percent:{x.percent}, reputation:{x.reputation})</li>)
			)
		}
	</ol>
</li>
<li key='author_reputation'>author_reputation => {p.author_reputation}</li>
<li key='parent_author'>parent_author => {p.parent_author}</li>
<li key='parent_permlink'>parent_permlink => {p.parent_permlink}</li>
<li key='url'>url => {p.url}</li>
<li key='root_title'>root_title => {p.root_title}</li>
<li key='beneficiaries'>beneficiaries =>
	<ol>
		{p.beneficiaries.map(
			(x) => (<li key='{x.account}'>{x.account}: {x.weight}</li>)
		)}
	</ol>
</li>
<li key='max_accepted_payout'>max_accepted_payout => {p.max_accepted_payout}</li>
<li>percent_steem_dollars     => {p.percent_steem_dollars    }</li>
</ul>;*/
    }

    renderFollowingOpinion(op: Array<Opinion>) {
        return opinionComponent({
            steem_username: this.props.steem_username,
            active_votes: op,
            follows: this.state.following,
            show_self_percent: false,
        });
    }

    renderPostListItem(p: post, pi: number, ps: Array<post>) {
        let selfVoteList: Array<any> = [];
        let selfOpinion: Opinion | undefined = undefined;
        let Per20000DString: string | undefined;
        let Per20000DN: number;
        const as = this.state.user_accounts;
        const username = as.length ? as[0].username : null;
        try {
            const authorOnOtherChains = p.blockchains.map(
                (x) => p.author + "@" + x
            );
            for (const op of p.active_votes) {
                if (authorOnOtherChains.includes(op.voter)) {
                    let bare_voter: string;
                    let blockchain: string;

                    [bare_voter, blockchain] = op.voter.split(/@/);
                    selfVoteList.push([blockchain, op.percent]);
                }
            }
            selfOpinion = (p.active_votes as Array<Opinion>).find((x) =>
                authorOnOtherChains.includes(x.voter)
            );
            const title = p.title;

            let usePostImage: boolean = false;
            let postImage: string = "";
            let tags: Array<string> = [];
            let followingVotesString: string | null = null;
            try {
                const json_metadata = JSON.parse(p.json_metadata);
                if (json_metadata["image"]) {
                    postImage = json_metadata["image"][0];
                    usePostImage = true;
                }
                // format would be == 'markdown' most of the time.
                tags = json_metadata["tags"];
            } catch (e) {
                tags = [];
            }
            if (!(tags instanceof Array)) tags = [];
            let following_votes: Array<Opinion> = [];
            if (this.state.following !== null) {
                const following: { [id: string]: Array<string> } = this.state
                    .following;
                for (const fd of p.active_votes) {
                    let voter_a = fd.voter.split(/@/);
                    let voter = voter_a[0];
                    if (following[voter]) {
                        following_votes.push(fd);
                    }
                } // for
            } else if (p.active_votes) {
                followingVotesString = "";
            }
            let className = "Post-Listing";
            if (p.blockchains.length === 1) {
                className += "-" + p.blockchains[0];
            }
            const humanReadableCreationDate: string = toDateString(p.created);
            const humanReadableUpdateDate = toDateString(p.last_update);
            return (
                <div
                    key={
                        p.permlink +
                        "+" +
                        p.blockchains.join("+") +
                        JSON.stringify(this.state.following) +
                        this.state.steem_username
                    }
                    className={className}
                    onMouseOver={this.onMouseOverPostItem.bind(this, pi)}
                >
                    <div
                        className="Post-Listing-Box"
                        onClick={this.setURL.bind(
                            this,
                            "/" +
                                p.category +
                                "/@" +
                                p.author +
                                "/" +
                                p.permlink,
                            p.title
                        )}
                    >
                        <div>
                            {usePostImage && (
                                <img
                                    className="Post-Listing-Image"
                                    src={postImage}
                                    alt=""
                                />
                            )}
                        </div>
                        <div>
                            <h2>{title}</h2>
                            <br />{" "}
                            <span className="nobr">
                                created: {humanReadableCreationDate + ", "}
                                <AgeWidget timestamp={p.created} />
                            </span>{" "}
                            <br />{" "}
                            {p.last_update != p.created && (
                                <Fragment>
                                    <span className="nobr">
                                        updated: {humanReadableUpdateDate + ","}
                                    </span>
                                    <AgeWidget timestamp={p.last_update} />
                                    <br />
                                </Fragment>
                            )}
                            {selfVoteList.length !== 0 && (
                                <b>
                                    <span className="nobr">Self-Voted </span>
                                    {selfVoteList.map((k) => (
                                        <span>
                                            {k[1] / 100}% on {k[0]}
                                        </span>
                                    ))}

                                    <br />
                                </b>
                            )}
                            {p.beneficiaries.length !== 0 && (
                                <div>
                                    beneficiaries:{" "}
                                    <ul>
                                        {p.beneficiaries.map((beneficiary) => (
                                            <li key={beneficiary.account}>
                                                @{beneficiary.account}{" "}
                                                {beneficiary.weight / 100}%
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {![
                                "1000000.000 HBD",
                                "1000000.000 SBD",
                                "1000000.000 WLS",
                                "1000000.000 BLT",
                            ].includes(p.max_accepted_payout) &&
                                <b>maximum accepted payout: ${addCommas(p.max_accepted_payout)}</b>}
                            {((Per20000DString = p.percent_steem_dollars) !==
                                undefined ||
                                (Per20000DString = p.percent_hbd) !==
                                    undefined) &&
                                Per20000DString != "10000" &&
                                ((Per20000DN = parseInt(Per20000DString)),
                                true) && (
                                    <span>
                                        Author Reward Breakdown:
                                        {Per20000DN / 200}% Dollars
                                    </span>
                                )}
                            <br />
                            <span className="nobr">
                                {p.active_votes.length} votes
                            </span>
                            <br />
                            {this.state.following &&
                                opinionComponent({
                                    steem_username: username,
                                    follows: this.state.following,
                                    active_votes: p.active_votes,
                                    show_self_percent: true,
                                })}
                            {followingVotesString}
                        </div>
                    </div>
                    {
                        false /*Any container that needs justify-content must have the
					flex, flexDirection, and justifyContent style
					set here instead of or as well as the CSS file for it to work*/
                    }
                    <div className="Post-Tag-Row">
                        {tags &&
                            tags.map((x) => (
                                <a
                                    key={x}
                                    className="Post-Tag-Button"
                                    href={"/trending/" + x}
                                >
                                    {x}
                                </a>
                            ))}
                    </div>
                </div>
            );
        } catch (e) {
            return <div>Unable to display this post {e.message}</div>;
        }
    }

    renderPostList() {
        try {
            return (
                <div
                    key={
                        this.state.order +
                        this.state.category +
                        this.state.page_user +
                        JSON.stringify(this.state.user_accounts) +
                        JSON.stringify(this.state.following)
                    }
                    className="Post-List"
                >
                    {this.state.posts === null ? (
                        <div>Loading Posts...</div>
                    ) : this.state.posts.length === 0 ? (
                        <div>No posts</div>
                    ) : (
                        this.state.posts.map(this.renderPostListItem)
                    )}
                </div>
            );
        } catch (e) {
            return <div>Error displaying this post list</div>;
        }
    }

    render404() {
        return (
            <div>
                <p>
                    404: This kind of URL is not yet supported for this
                    interface.
                </p>
                {this.state.page_user && (
                    <div>
                        <UserSummary
                            username={this.state.page_user as string}
                            image_url={
                                this.state.page_profile_image === null
                                    ? undefined
                                    : this.state.page_profile_image
                            }
                        >
                            The blog of @{this.state.page_user}
                        </UserSummary>
                    </div>
                )}
                <p>
                    <a href="/">The main page</a>
                </p>

                <p>
                    Perhaps you could find what you are looking for on one of
                    these sites:
                    {sitesForBlockchains(
                        supportedBlockchains(),
                        "steemfiles.com"
                    ).map((x) => (
                        <span>
                            <a
                                href={
                                    "https://" + x + document.location.pathname
                                }
                                target={x}
                            >
                                {x}
                            </a>
                            ,{" "}
                        </span>
                    ))}
                </p>
            </div>
        );
    }

    renderMain() {
        const following =
            this.state.following && Object.keys(this.state.following);
        try {
            if (this.state.aspect === "ticker") {
                return <div>
			<Ticker />
		</div>;
            }
            if (this.state.showNotSupported) return this.render404();
            if (this.state.showFiles) {
                try {
                  let username = null;
                  for (const u of this.state.user_accounts) {

                  }
                  if (username !== null && this.state.page_user)
                    return (
                        <FilesListComponent
                            key={this.state.page_user}
                            setOwner={this.setPageUser}
                            logged_in_username={username}
                            owner={this.state.page_user}
                            files={this.state.files}
                            filesLoading={this.state.loadingFiles}
                            onGotFiles={this.handleFileList.bind(this)}
                        />
                    );
                    else return <b>no user specified</b>;
                } catch (e) {
                    return (
                        <div className="SideBySide">
                            <input type="text" placeholder="search" />
                            {this.state.files.map((file) => (
                                <p key={file.id}>
                                    {file.name}
                                    {file.extension}{" "}
                                    {file.owner && "by @" + file.owner}
                                </p>
                            ))}
                        </div>
                    );
                }
            }

            if (
                this.state.category === "" &&
                ("" === this.state.aspect ||
                    "introduction" === this.state.aspect)
            )
                return (
                    <StartPage
                        key={this.state.page_user}
                        username={this.state.page_user || ""}
                    />
                );

            if (this.state.page_user !== "") {
                if (this.state.aspect !== "" && this.state.category !== "") {
                    if (this.state.posts === null) return "Loading post...";
                    else if (this.state.posts.length === 0) {
                        if (this.state.error) {
                            try {
                                // Error can be for ex: {"name":"RPCError","code":-32602,"data":"post was not found in cache"}
                                try {
                                    const es = JSON.parse(this.state.error);
                                    const data = es.find("data");
                                    if (data) {
                                        return (
                                            <p>Error loading post... {data}</p>
                                        );
                                    }
                                } catch (e) {
                                    // fall thru
                                }

                                return (
                                    <p>
                                        Error loading post... {this.state.error}{" "}
                                        {typeof this.state.error}
                                    </p>
                                );
                            } catch (e) {
                                return (
                                    <p>
                                        Error loading post... Error code hidden.
                                    </p>
                                );
                            }
                        }
                        return "Error loading post... No error code";
                    } else {
                        let steem_username: string | undefined = undefined;
                        let accounts_for_post: Array<any> = (this.state.user_accounts || []).filter(
                            (x) =>
                                this.state.posts && this.state.posts[0].blockchains.includes(
                                    x.provider
                                )
                        );
                        if (accounts_for_post.length > 0) {
                            steem_username = accounts_for_post[0].username;
                        }
                        return (
                            <PostDisplayComponent
                                setURL={this.setURL.bind(this)}
                                key={
                                    this.state.posts[0].permlink +
                                    JSON.stringify(this.state.following) +
                                    JSON.stringify(this.state.user_accounts) +
                                    this.state.posts[0].last_update
                                }
                                onAddOpinion={this.addOpinion.bind(this)}
                                blockchains={this.state.posts[0].blockchains}
                                steem_username={steem_username}
                                renderFollowingOpinion={
                                    this.renderFollowingOpinion
                                }
                                id={this.state.posts[0].id}
                                order={this.state.order}
                                following={following}
                                author={this.state.posts[0].author}
                                permlink={this.state.posts[0].permlink}
                                category={this.state.posts[0].category}
                                title={this.state.posts[0].title}
                                body={this.state.posts[0].body}
                                json_metadata={
                                    this.state.posts[0].json_metadata
                                }
                                created={this.state.posts[0].created}
                                last_update={this.state.posts[0].last_update}
                                depth={this.state.posts[0].depth}
                                children={this.state.posts[0].children}
                                net_rshares={this.state.posts[0].net_rshares}
                                last_payout={this.state.posts[0].last_payout}
                                cashout_time={this.state.posts[0].cashout_time}
                                total_payout_value={
                                    this.state.posts[0].total_payout_value
                                }
                                curator_payout_value={
                                    this.state.posts[0].curator_payout_value
                                }
                                pending_payout_value={
                                    this.state.posts[0].pending_payout_value
                                }
                                promoted={this.state.posts[0].promoted}
                                replies={this.state.posts[0].replies}
                                body_length={this.state.posts[0].body_length}
                                active_votes={this.state.posts[0].active_votes}
                                author_reputation={
                                    this.state.posts[0].author_reputation
                                }
                                parent_author={
                                    this.state.posts[0].parent_author
                                }
                                parent_permlink={
                                    this.state.posts[0].parent_permlink
                                }
                                url={this.state.posts[0].url}
                                root_title={this.state.posts[0].root_title}
                                beneficiaries={
                                    this.state.posts[0].beneficiaries
                                }
                                max_accepted_payout={
                                    this.state.posts[0].max_accepted_payout
                                }
                                percent_steem_dollars={
                                    this.state.posts[0].percent_steem_dollars
                                }
                                percent_hbd={this.state.posts[0].percent_hbd}
                                accounts={this.state.user_accounts}
                            />
                        );
                    }
                } else if (
                    this.state.aspect === "permissions" &&
                    this.state.category === ""
                ) {
                    return (
                        <PermissionsComponent
                            key={
                                JSON.stringify(this.state.user_accounts) +
                                this.state.page_user
                            }
                            setURL={this.setURL.bind(this)}
                            username={this.state.page_user || ""}
                            accounts={this.state.user_accounts}
                        />
                    );
                } else
                    return (
                        <div>
                            <div>
                                {" "}
                                {this.state.page_user + "'s "}
                                {["blog", ""].includes(this.state.aspect)
                                    ? " blog"
                                    : this.state.aspect}
                                <br />
                                {this.renderPostList()}
                                <br />
                                {this.state.error && (
                                    <span>{this.state.error}</span>
                                )}
                            </div>
                        </div>
                    );
            }
            if (this.state.order !== "") {
                return (
                    <div>
                        {this.renderPostList()}
                        {this.state.error && <span>{this.state.error}</span>}
                    </div>
                );
            }
        } catch (e) {
            return false;
        }
    }

    setURL(newURL: string, title: string) {
        console.log('setURL called with "' + newURL + "'");
        const setStateFromURI = this.setStateFromURI.bind(this, newURL);
        const request_posts = this.request_posts.bind(this);
        this.setState(function (old_state) {
            const changes = setStateFromURI(old_state);
            const state = Object.assign(old_state, changes);
            request_posts(state.page_user, state.aspect, state.category, []);
            // eslint-disable-next-line no-restricted-globals
            history.pushState(state, title, newURL);
            console.log(changes);
            return changes;
        });
    }

    setCategoryWatchDog(nc: string, our_count: number) {
        if (our_count === this.state.setCategoryCallCount) {
            console.log("setting new location");
            const order =
                this.state.order === "" ? "trending" : this.state.order;
            if (nc === "") {
                // // eslint-disable-next-line no-restricted-globals
                // document.location.href = "/trending/";
                // eslint-disable-next-line no-restricted-globals
                history.pushState(
                    this.state,
                    "Steemfiles - " + order + " posts",
                    "/" + order
                );
                this.setState(this.setStateFromURI.bind(this, "/" + order));
            } else {
                // eslint-disable-next-line no-restricted-globals
                history.pushState(
                    this.state,
                    "Steemfiles - trending posts",
                    "/" + order + "/" + nc
                );
                this.setState(
                    this.setStateFromURI.bind(this, "/" + order + "/" + nc)
                );
            }
            this.request_posts("", nc, nc, []);
        } else {
            // a new timeout event was fired while this routine was waiting to run,
            // just die and let the last timeout expire and that is when the new category will get set.
        }
    }

    setCategory(e): void {
        const new_category = e.target.value.replace(/[@ ]/g, "");
        let current_count = this.state.setCategoryCallCount + 1;

        this.setState({ setCategoryCallCount: current_count });
        setTimeout(
            this.setCategoryWatchDog.bind(this, new_category, current_count),
            3500
        );
    }

    setOrder(e): void {
        const new_order = e.target.value;
        if (this.state.category) {
            // eslint-disable-next-line no-restricted-globals
            history.pushState(
                this.state,
                new_order + " posts in " + this.state.category,
                "/" + new_order + "/" + this.state.category
            );
        } else {
            // eslint-disable-next-line no-restricted-globals
            history.pushState(
                this.state,
                new_order + " posts",
                "/" + new_order
            );
        }
        this.setState({
            order: new_order,
            aspect: "",
            showNotSupported: false,
            showSinglePage: false,
            showPageList: true,
            posts: null,
        });
        this.request_posts("", "", this.state.category, []);
    }

    setPageUser(e: ChangeEvent | MouseEvent | string) {
        let new_page_user;
        try {
            new_page_user = (e as ChangeEvent<HTMLInputElement>).target.value;
        } catch (err) {
            new_page_user = e;
        }
        new_page_user = new_page_user.replace(/[@ ]/g, "");
        if (
            this.state.category === "" &&
            ["permissions", "", "introduction", "files"].includes(
                this.state.aspect
            )
        ) {
            if (new_page_user === "") {
                this.setState(function (old_state) {
                    let changes = { page_user: "", aspect: old_state.aspect };
                    let new_state = Object.assign(
                        Object.assign({}, old_state),
                        changes
                    );
                    if (old_state.aspect === "permissions") {
                        changes["aspect"] =
                            "introduction";
                        changes["posts"] = [];
                        // eslint-disable-next-line no-restricted-globals
                        history.pushState(
                            {...old_state, ...changes},
                            "Introduction",
                            "/introduction"
                        );
                    } else if (old_state.aspect === "files") {
                        changes["aspect"] = "files";
                        changes["posts"] = [];
                        // eslint-disable-next-line no-restricted-globals
                        history.pushState({...old_state, ...changes}, "Files", "/files");
                    }
                    return changes;
                });
            } else
                this.setState(
                    function (new_page_user, old_state) {
                        const changes = { page_user: new_page_user };
                        const new_state = Object.assign(
                            Object.assign({}, old_state),
                            changes
                        );
                        // eslint-disable-next-line no-restricted-globals
                        history.pushState(
                            new_state,
                            old_state.aspect + "'s " + old_state.aspect,
                            "/@" + new_page_user + "/" + old_state.aspect
                        );
                        return changes;
                    }.bind(this, new_page_user)
                );

            return;
        }

        const request_posts = this.request_posts.bind(this);

        this.setState(
            function (new_page_user, old_state) {
                const changes = { page_user: new_page_user, posts: null };
                const new_state = Object.assign(
                    Object.assign({}, old_state),
                    changes
                );
                if (old_state.category === "" || old_state.aspect === "") {
                    // eslint-disable-next-line no-restricted-globals
                    history.pushState(
                        new_state,
                        new_page_user +
                            "'s " +
                            old_state.aspect +
                            " -- Steemfiles",
                        "/@" + new_page_user + "/" + old_state.aspect
                    );
                    request_posts(new_page_user, old_state.aspect, "", []);
                } else {
                    changes["category"] = "";
                    changes["aspect"] = "";
                    // eslint-disable-next-line no-restricted-globals
                    history.pushState(
                        new_state,
                        new_page_user + " -- Steemfiles ",
                        "/@" + new_page_user
                    );
                    request_posts(new_page_user, "", "", []);
                }
                return changes;
            }.bind(this, new_page_user)
        );
    }

    toggleShowPageUserMenu(oldState) {
        return { showPageUserMenu: !oldState.showPageUserMenu };
    }

    pageUserButtonClicked(e) {
        this.setState(this.toggleShowPageUserMenu.bind(this));
    }

    setError(message) {
      this.setState({error: message});
    }

    render() {
        if (this.state.order === "error") return this.render404();
        const {error} = this.state;
        try {
          // This scheme will work on steem or hive like sites other than Steemfiles.
          const not_only_steemfiles =
              !this.state.showTicker && !this.state.showNotSupported;
          let blockchains;
          return (
              <div className="App">
                  <nav
                      ref={this.navigationBarRef}
                      style={{ flexDirection: "row" }}
                      id="navbarspan"
                  >
                      <span onDoubleClick={this.toggleDebug}>
                          Steemfiles{" "}
                          {this.state.loading.length > 8
                              ? "..." + this.state.loading.length + "..."
                              : this.state.loading.map((x) => ".")}
                      </span>
                      {/*FilesEnabled && this.state.uploadsEnabled && <UploadButton onClick={this.showUpload} />*/}

                      {this.state.debug && (
                          <div style={{ position: "fixed", top: "160px" }}>
                              Developer Data: '{this.state.new_dev_data}'
                          </div>
                      )}

                      <select
                          defaultValue={this.state.order}
                          onChange={this.setOrder.bind(this)}
                      >
                          <option value="created">New</option>
                          <option value="trending">Trending</option>
                          <option value="hot">Hot</option>
                          <option value="active">Active</option>
                          <option value="payout">Payout</option>
                          <option value="muted">Muted</option>
                      </select>

                      <input
                          id="tagSearchInput"
                          type="text"
                          placeholder="tag name"
                          defaultValue={this.state.category}
                          onChange={this.setCategory.bind(this)}
                      />

                      {}

                      <div>
                          <button
                              key={this.state.page_user}
                              style={{ flexWrap: "wrap" }}
                              onClick={this.pageUserButtonClicked.bind(this)}
                          >
                              {this.state.page_profile_image && (
                                  <img
                                      style={{
                                          maxHeight: "3em",
                                          maxWidth: "3em",
                                      }}
                                      src={this.state.page_profile_image}
                                      alt=""
                                  />
                              )}{" "}
                              @
                              <input
                                  type="text"
                                  className="UsernameInput"
                                  defaultValue={this.state.page_user ?? ""}
                                  placeholder="username on Steem, Hive, and Whaleshares..."
                                  onChange={this.callInputChangeLater.bind(
                                      this,
                                      this.setPageUser
                                  )}
                              />
                              <br />
                          </button>
                          {this.state.showPageUserMenu &&
                              this.state.page_user !== "" && (
                                  <div id="PageUserMenu">
                                      {!["blog"].includes(this.state.aspect) && (
                                          <button
                                              onClick={this.getBlog.bind(
                                                  this,
                                                  this.state.page_user
                                              )}
                                          >
                                              Blog
                                          </button>
                                      )}
                                      {!["permissions"].includes(
                                          this.state.aspect
                                      ) && (
                                          <button
                                              onClick={this.getPermissions.bind(
                                                  this,
                                                  this.state.page_user
                                              )}
                                          >
                                              Permissions
                                          </button>
                                      )}
                                      {this.state.aspect !== "feed" && (
                                          <button
                                              onClick={this.getFeed.bind(
                                                  this,
                                                  this.state.page_user
                                              )}
                                          >
                                              Feed
                                          </button>
                                      )}
                                  </div>
                              )}
                      </div>
                      <div style={{ flexWrap: "wrap" }}>
                          {(this.state.aspect !== "ticker") && (
                              <button onClick={this.setTickerState}>
                                  Ticker
                              </button>
                          )}
                          {(this.state.aspect !== "" ||
                              this.state.page_id !== null) && (
                              <button onClick={this.setIntroState}>
                                  Introduction Page
                              </button>
                          )}
                      </div>

                      <div ref={this.userPaneRef}>
                          <UserPane
                              alias={this.state.user_alias}
                              accounts={this.state.user_accounts}
                              space={this.state.user_space}
                              onLogout={this.handleLogout}
                          />
                      </div>
                      {this.state.debug && (
                          <div style={{ display: "flex" }}>
                              <div style={{ borderStyle: "solid" }}>
                                  following length:
                                  <span
                                      style={{
                                          backgroundColor: "red",
                                          color: "white",
                                      }}
                                  >
                                      {
                                          JSON.stringify(this.state.following)
                                              .length
                                      }
                                  </span>
                              </div>

                              <div style={{ borderStyle: "solid" }}>
                                  aspect:{" "}
                                  <span style={{ backgroundColor: "cyan" }}>
                                      {JSON.stringify(this.state.aspect)}
                                  </span>
                              </div>
                              <div style={{ borderStyle: "solid" }}>
                                  page user:{" "}
                                  <span style={{ backgroundColor: "cyan" }}>
                                      {JSON.stringify(this.state.page_user)}
                                  </span>
                              </div>
                              <div style={{ borderStyle: "solid" }}>
                                  category:{" "}
                                  <span style={{ backgroundColor: "cyan" }}>
                                      {JSON.stringify(this.state.category)}
                                  </span>
                              </div>
                              <div style={{ borderStyle: "solid" }}>
                                  order:{" "}
                                  <span style={{ backgroundColor: "cyan" }}>
                                      {JSON.stringify(this.state.order)}
                                  </span>
                              </div>
                              <div style={{ borderStyle: "solid" }}>
                                  loading:{" "}
                                  <span style={{ backgroundColor: "cyan" }}>
                                      {JSON.stringify(this.state.loading)}
                                  </span>
                              </div>
                              <div style={{ borderStyle: "solid" }}>
                                  posts:{" "}
                                  <span style={{ backgroundColor: "cyan" }}>
                                      {this.state.posts === null
                                          ? "null"
                                          : this.state.posts.map((x) => "x ")}
                                  </span>
                              </div>
                          </div>
                      )}
                      <SteemUserButton
                          key={JSON.stringify(this.state.user_accounts)}
                          className={"nav"}
                          blockchainName={"steem"}
                          onAliasClick={this.showUserPane}
                          alias={this.state.user_alias}
                          accounts={this.state.user_accounts}
                          space={this.state.user_space}
                          onLogin={this.handleLogin}
                      ></SteemUserButton>
                  </nav>
                  <div style={{ height: "90px", backgroundColor: 'skyblue' }}>

                  </div>
                  <div className="App-header">
                      {new Date().getMinutes() % 2 === 0 ? (
                          <RedBubbleAd />
                      ) : (
                          <CryptoBeggingAd />
                      )}
                      <span style={{color: "red"}}> {error} </span>
                      {this.renderMain()}
                  </div>
              </div>
          );
        } catch (e) {
          return <div>{e.message}</div>;
        }
    }
}

export default App;
