import React from "react";
import Markdown from "react-remarkable";

function CompletePostItemDisplayComponent(props: any) {
    return (
        <ul>
            <li key="post_id">post_id : {props.post_id}</li>
            <li key="author">author : {props.author}</li>
            <li key="permlink">permlink : {props.permlink}</li>
            <li key="category">category : {props.category}</li>
            <li key="title">title : {props.title}</li>
            <li key="body">
                body : {<Markdown source={props.body}></Markdown>}
            </li>
            <li key="json_metadata">json_metadata : {props.json_metadata}</li>
            <li key="created">created : {props.created}</li>
            <li key="last_update">last_update : {props.last_update}</li>
            <li key="depth">depth : {props.depth}</li>
            <li key="children">children : {props.children}</li>
            <li key="net_rshares">net_rshares : {props.net_rshares}</li>
            <li key="last_payout">last_payout : {props.last_payout}</li>
            <li key="cashout_time">cashout_time : {props.cashout_time}</li>
            <li key="total_payout_value">
                total_payout_value : {props.total_payout_value}
            </li>
            <li key="curator_payout_value">
                curator_payout_value : {props.curator_payout_value}
            </li>
            <li key="pending_payout_value">
                pending_payout_value : {props.pending_payout_value}
            </li>
            <li key="promoted">promoted : {props.promoted}</li>
            <li key="replies">replies : {props.replies}</li>
            <li key="body_length">body_length : {props.body_length}</li>
            <li key="active_votes">
                active_votes :
                <ol>
                    {props.active_votes.map((x) => (
                        <li>
                            (voter:{x.voter}, rshares:{x.rshares}, percent:
                            {x.percent}, reputation:{x.reputation})
                        </li>
                    ))}
                </ol>
            </li>
            <li key="author_reputation">
                author_reputation : {props.author_reputation}
            </li>
            <li key="parent_author">parent_author : {props.parent_author}</li>
            <li key="parent_permlink">
                parent_permlink : {props.parent_permlink}
            </li>
            <li key="url">url : {props.url}</li>
            <li key="root_title">root_title : {props.root_title}</li>
            <li key="beneficiaries">
                beneficiaries :
                <ol>
                    {props.beneficiaries.map((x) => (
                        <li key="{x.account}">
                            {x.account}: {x.weight}
                        </li>
                    ))}
                </ol>
            </li>
            <li key="max_accepted_payout">
                max_accepted_payout : {props.max_accepted_payout}
            </li>
            <li>percent_steem_dollars : {props.percent_steem_dollars}</li>
        </ul>
    );
}

export default CompletePostItemDisplayComponent;
