import React from "react";
import { Opinion } from "./Opinion";
interface Props {
    //  both of these arrays are don't have @blockchain parts
    follows: null | Array<string> | { [id: string]: Array<string> };
    active_votes: Array<Opinion>;
    steem_username: null | string;
    show_self_percent: boolean;
}

// assign this to console.log instead if you want logging
const log = function (a) {};

export function opinionComponent(props: Props): string {
    const votes = props.active_votes;
    if (!props.steem_username)
        return "Login to see how those you follow voted.";
    //log(op);
    log(props);
    if (!props.follows) {
        return "Loading following...";
    }
    let you_voted: boolean = false;

    let active_votes: Array<Opinion> = [];
    let output = "";

    const following:
        | Array<string>
        | { [id: string]: Array<string> } = props.follows as
        | Array<string>
        | { [id: string]: Array<string> };

    for (const vote of props.active_votes) {
        const voterVector: Array<string> = vote.voter.split(/@/);
        const voter: string = voterVector[0];

        if (props.show_self_percent && voter == props.steem_username)
            output = `You voted ${vote.percent / 100}%.  `;

        if (following.length != undefined) {
            // must be an array
            if ((props.follows as Array<string>).includes(voter)) {
                active_votes.push(vote);
            }
            // it's a hash
        } else if ((props.follows as { [id: string]: Array<string> })[voter]) {
            active_votes.push(vote);
        }
    } // for

    log(active_votes);
    const op: Array<Opinion> = active_votes;
    const n: Array<Opinion> = op.filter((v) => v.percent < 0);
    const p: Array<Opinion> = op.filter((v) => v.percent > 0);
    log(op);

    try {
        if (op.length === 0)
            return output + "None of who you follow voted on this.  ";
        if (op.length === 1) {
            let v = op[0];
            return (
                output +
                v.voter +
                " " +
                (v.percent > 0 ? "upvoted" : "downvoted") +
                " this " +
                v.percent / 100 +
                "%."
            );
        }
        if (op.length < 4) {
            if (p.length) {
                if (p.length === 1)
                    output += opinionComponent({
                        follows: props.follows,
                        steem_username: props.steem_username,
                        active_votes: p,
                        show_self_percent: props.show_self_percent,
                    });
                else {
                    output += p[0].voter;
                    for (let i = 1; i < p.length - 1; ++i) {
                        output += ", " + p[i].voter;
                    }
                    output +=
                        " and " + p[p.length - 1].voter + " upvoted this.  ";
                }
            }
            if (n.length) {
                if (n.length === 1) {
                    output +=
                        " and " +
                        opinionComponent({
                            follows: props.follows,
                            steem_username: props.steem_username,
                            active_votes: n,
                            show_self_percent: props.show_self_percent,
                        });
                } else {
                    if (p.length) {
                        output += " and ";
                    }
                    output += n[0].voter;
                    for (let i = 1; i < n.length - 1; ++i) {
                        output += ", " + n[i].voter;
                    }
                    output +=
                        " and " + n[n.length - 1].voter + " downvoted this.  ";
                }
            }
            return output;
        } // if op.length < 4

        return output + op.length + " of those you follow voted on this.  ";
    } catch (e) {
        log(e);
        return output + op.length + " of those you follow voted on this.  ";
    }
}

function OpinionComponent(props) {
    log(props);
    return <div>{opinionComponent(props)}</div>;
}

export default OpinionComponent;
