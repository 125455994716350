import React, { useState } from "react";
import "./FileComponent.css";

interface FileComponentProperties {
    id: number;
    name: string;
    extension: string;
    description: string;
    owner: string;
    mini_preview: string;
    price: string;
    currency: Array<string>;

    showDescription: boolean;
    setOwner: any;
}

function FileComponent(file: FileComponentProperties) {
    const [category, setCategory] = useState("file");
    return (
        <div className="FileComponent" key={file.id}>
            {file.name}
            {file.extension}
            {file.mini_preview && <img alt="" src={file.mini_preview} />}
            {file.owner ? (
                <span>
                    {" "}
                    by{" "}
                    <span
                        onClick={file.setOwner}
                        style={{ color: "blue", textDecoration: "underline" }}
                    >
                        @{file.owner}
                    </span>
                </span>
            ) : (
                <span> was uploaded by some user that had not logged in</span>
            )}
            {".  "}
            Price {file.price}{" "}
            {file.currency.length > 0 ? (
                <span>accepting {file.currency.join()}</span>
            ) : (
                "No payment methods for this file"
            )}
            {file.showDescription && (
                <div style={{ paddingLeft: "4em" }}>
                    {file.description}
                    {"  "}
                    This file is{" "}
                    {["file", "document", "music", "software"].includes(
                        category
                    )
                        ? "a "
                        : ["music", "software"].includes(category)
                        ? "a peice of music "
                        : "an "}
                    <select
                        id="category"
                        defaultValue={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <option key="file">file</option>
                        <option key="audio">audio</option>
                        <option key="document">document</option>
                        <option key="ebook">ebook</option>
                        <option key="image">image</option>
                        <option key="music">music</option>
                        <option key="software">software</option>
                        <option key="video">video</option>
                    </select>
                </div>
            )}
        </div>
    );
}

export default FileComponent;
