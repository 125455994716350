import React, { MouseEvent, ChangeEvent } from "react";
import "./permissions.css";

import steem from "steem";
import hive from "@hiveio/hive-js";
import blurt from "@blurtfoundation/blurtjs";
import whaleshares from "@whaleshares/wlsjs";
import {
    APIForBlockchain,
    keychainForBlockchain,
    supportedBlockchains,
} from "./blockchains";
import { Permission } from "./Permission";
import { PermissionSet } from "./PermissionSet";
import { PermissionsComponentState } from "./PermissionsComponentState";
import { AuthorizedAccountsComponent } from "./AuthorizedAccountsComponent";

const earlyFeature: boolean = false;
const UIupdateTest: boolean = false;
const enableKeyChains: boolean = true;
const updateFunctionsWork: boolean = true;
const showDebugInfo: boolean = false;
const enableWIFs: boolean = true;
const whalevaultSupported: boolean = false;
const blurtRPCworks: boolean = true;
const showKeyRole: boolean = true;
const reportCommonKeys: boolean = earlyFeature;
const createKeyFeature: boolean = earlyFeature;
const debug: boolean = false;
let once: boolean = true;

const whalevault_blockchain_support_list = [
    "whaleshares",
    "bitshares",
    "eos",
    "steem",
    "hive",
    "blurt",
    "smoke",
    "telos",
    "worbli",
    "golos",
    "peerplays",
    "scorum",
];

blurt.api.setOptions({ url: "https://rpc.blurt.world", useAppbaseApi: true });
blurt.config.set("address_prefix", "BLT");
blurt.config.set(
    "chain_id",
    "cd8d90f29ae273abec3eaa7731e25934c63eb654d55080caff2ebb7f5df6381f"
);
blurt.config.set("alternative_api_endpoints", [
    "https://rpc.blurt.world",
    "https://rpc.blurt.world",
]);

interface PermissionsComponentProps {
    username: string;
    accounts: Array<{ provider: string; username: string; role: string }>;
    setURL: (url: string, title: string) => void;
}

function NYI() {
    alert("Not yet implemented");
}

function titlecase(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function RecoverySection(props: {blockchain: string, setURL: (url: string, title:string)=>void, recovery_account: string}) {
    const {blockchain, recovery_account, setURL} = props;
    console.log({blockchain, recovery_account});
    return (
    <div>Recovery Account on {blockchain}:{" "}
      {
        (recovery_account && recovery_account.length !== 0) ?
          <span className="link" onClick={(ev) =>
            {
              props.setURL(
                `/@${recovery_account}/permissions`,
                `Permissions for @${recovery_account}`
                );
            }
          }>
            @{recovery_account}
          </span>
        : <i>not set</i>
      }
      {false && (
          <button onClick={NYI}>
              Change Recovery Account
          </button>
      )}
    </div>);
}


/*function lateInputChangeCall(old_value: string, f, e: ChangeEvent<Element>) {
	if (old_value === (e.target as HTMLInputElement).value)
		f(e);
}*/

export class PermissionsComponent extends React.Component<
    PermissionsComponentProps,
    PermissionsComponentState
> {
    constructor(props: PermissionsComponentProps) {
        super(props);

        let logged_in_role: { [id: string]: string } = {};
        for (const bc of supportedBlockchains()) {
            logged_in_role[bc.name] = "";
        }
        for (const account of this.props.accounts) {
            logged_in_role[account.provider] = account.role;
        }

        this.state = {
            raw_hive: "{}",
            raw_steem: "{}",
            raw_blurt: "{}",
            debug_info: "",
            whalevault: false,
            common_posting_keys: null,
            logged_in_role: logged_in_role,
            permissions: {},
            new_account_to_authorize: "",
            interval_ID: null,
            new_recovery_account: "",
            // uses[x] is null if we don't know if the this.props.username has an account on blockchain x.  It is false if it doesn't have an account on blockchain x and is true if it does have an account on blockchain x.
            uses: {
                hive: null,
                steem: null,
                golos: false,
                blurt: null,
                whaleshares: null,
            },
        };
        this.log = this.log.bind(this);

        // Low Level keychain
        this.someKeyChainNeedsLoading = this.someKeyChainNeedsLoading.bind(
            this
        );
        this.checkForBrowserExtensions = this.checkForBrowserExtensions.bind(
            this
        );
        this.makeUniversal = this.makeUniversal.bind(this);

        // updating
        // setUpdate gets bind on every call..
        this.isUpdating = this.isUpdating.bind(this);
        this.can = this.can.bind(this);
        // clearUpdating gets bind on every call

        // handleGetAccount...
        this.revoke = this.revoke.bind(this);
        this.addAccountAuthority = this.addAccountAuthority.bind(this);
        this.hasKey = this.hasKey.bind(this);
        this.isKeyTransferable = this.isKeyTransferable.bind(this);
        this.doesNotHaveKey = this.doesNotHaveKey.bind(this);
    }

    log(
        a,
        b: any = null,
        c: any = null,
        d: any = null,
        e: any = null,
        f: any = null,
        g: any = null,
        h: any = null
    ) {
        let out = this.state.debug_info + "";
        let list = [a, b, c, d, e, f, g, h];
        for (const x of list) {
            if (x === null) break;
            out += JSON.stringify(x, null, 2) + "\n";
        }
        this.setState({ debug_info: out });
    }

    NYI() {
        alert("Not yet implemented");
    }

    handleWhaleVaultResponse(blockchain, tx, x) {
        if (x.success) {
            this.log("After signing:", x);
            let stx = x.data.message;
            stx["signatures"] = [x.result];
            APIForBlockchain(blockchain).api.broadcastTransactionSynchronous(
                stx,
                function (err, result) {
                    if (err) {
                        //this.log('error:' + JSON.stringify(err));
                        alert("error:" + JSON.stringify(err));
                    } else alert("!" + JSON.stringify(result));
                }
            );
        } else {
            alert(x.message);
        }
        this.setState(this.clearUpdating.bind(this, blockchain));
    }

    sendWhaleVaultTX(blockchain, username, opdata, err, properties) {
        // Every key is treated as a posting key.  Don't worry it all works well this way.
        // The role parameter is just to help the server find the public key in the blockchain database
        // when it validates the transaction.
        if (err) {
            alert("Error:" + JSON.stringify(err));
            this.setState(this.clearUpdating.bind(this, blockchain));
            return;
        }
        //console.log('blockchain properties:', properties);
        const refBlockNum =
            (properties.last_irreversible_block_num - 1) & 0xffff;
        const ref_block_prefix = new Buffer(
            properties.head_block_id,
            "hex"
        ).readUInt32LE(4);
        const now = new Date().getTime();
        const later = new Date(now + 600 * 1000);
        let expiration = later.toISOString();
        expiration = expiration.replace(/[.].+Z$/, "Z");

        let tx = {
            ref_block_num: refBlockNum,
            ref_block_prefix: ref_block_prefix,
            expiration: expiration,
            operations: opdata,
            extensions: [],
        };
        let cun;
        if (blockchain === "steem") cun = "stm:" + username;
        else if (blockchain === "hive") cun = "hve:" + username;
        else if (blockchain === "blurt") cun = "blt:" + username;
        else cun = "wls:" + username;
        alert("near the end of sendWhaleVaultTX");

        const whalevault = window["whalevault"];
        if (debug) alert(steem.config.get("url"));
        this.log("Before signing:", tx);
        whalevault.requestSignBuffer(
            "steemfiles.com",
            cun,
            tx,
            "Active",
            "update authorities",
            "tx",
            this.handleWhaleVaultResponse.bind(this, blockchain, tx)
        );
        // this.handleAccountUpdate.bind(this)
        //window['whalevault'].signBuffer(cun, JSON.stringify(tx), 'Active', 'Change Permissions', 'raw', function (r) { alert(JSON.stringify(r)); });
    } // sendWhaleVaultTX

    // Keychain Low Level
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    keyChainAvailable(blockchain: string): boolean {
        //  tslint:disable-next-line  no-restricted-globals
        return !!(
            (this.state.whalevault &&
                whalevault_blockchain_support_list.includes(blockchain)) ||
            (blockchain === "steem" && window["steem_keychain"]) ||
            (blockchain === "hive" && window["hive_keychain"])
        );
    }

    someKeyChainNeedsLoading(): boolean {
        // whalevault works with all of them.
        if (!this.state.whalevault) {
            // whalevault is the only one coded for here that supports any of these three
            for (const blockchain of whalevault_blockchain_support_list) {
                if (["hive", "steem"].includes(blockchain)) continue;
                if (
                    blockchain in this.state.uses &&
                    this.state.uses[blockchain] !== false
                )
                    return true;
            } // for
        } // if

        return (
            (this.state.uses["steem"] !== false &&
                window["steem_keychain"] === undefined) ||
            (this.state.uses["hive"] !== false &&
                window["hive_keychain"] === undefined)
        );
    }

    setWhalevault() {
        hive.config.whalevault = window["whalevault"];
        blurt.config.whalevault = window["whalevault"];
        steem.config.whalevault = window["whalevault"];
        whaleshares.config.whalevault = window["whalevault"];

        this.setState({ whalevault: true });
    }

    checkForBrowserExtensions(): void {
        let changed: boolean = false;
        let p: { [id: string]: PermissionSet } = Object.assign(
            {},
            this.state.permissions
        );
        if (!enableKeyChains) return;

        if (
            whalevaultSupported &&
            !this.state.whalevault &&
            window["whalevault"]
        ) {
            // handshake...
            // @ts-ignore:
            if (window["whalevault"]) {
                let whalevault = window["whalevault"];
                whalevault.requestHandshake(
                    "steemfiles.com",
                    this.setWhalevault.bind(this)
                );
            }
        }

        if (window["steem_keychain"]) {
            // Steem Keychain or Hive Keychain extension installed...
            if (p["steem"] && !p["steem"].keychain) {
                p["steem"].keychain = true;
                changed = true;
            }
        }
        if (window["hive_keychain"]) {
            // Steem Keychain or Hive Keychain extension installed...
            if (p["hive"] && !p["hive"].keychain)
                changed = p["hive"].keychain = true;
        }
        if (changed) {
            let iid = this.state.interval_ID;
            if (!this.someKeyChainNeedsLoading()) {
                if (this.state.interval_ID !== null)
                    clearInterval(this.state.interval_ID);
                iid = null;
            }
            this.setState({ permissions: p, interval_ID: iid });
        }
    }

    // Update status
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setUpdating(
        blockchain: string,
        old_state: { permissions: { [id: string]: PermissionSet } }
    ) {
        let p = Object.assign({}, old_state.permissions);
        let pb = Object.assign({}, p[blockchain]);
        p[blockchain] = pb;
        pb.updating = true;
        return { permissions: p };
    }

    isUpdating(blockchain: string) {
        return this.state.permissions[blockchain].updating;
    }

    clearUpdating(
        blockchain: string,
        old_state: { permissions: { [id: string]: PermissionSet } }
    ) {
        let p = Object.assign({}, old_state.permissions);
        let pb = Object.assign({}, p[blockchain]);
        const debug_info =
            this.state.debug_info +
            "clearUpdating(" +
            blockchain +
            ") called\n";
        pb.updating = false;
        p[blockchain] = pb;

        return { permissions: p, debug_info: debug_info };
    }

    // General Event Handlers
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    handleGetAccount(blockchain: string, error, results) {
        let d: any = {};

        if (["hive", "steem", "blurt"].includes(blockchain))
            d["raw_" + blockchain] = JSON.stringify(
                { error: error, result: results },
                null,
                2
            );
        if (error) {
            d["uses"] = this.state.uses;
            d.uses[blockchain] = false;
        }
        this.setState(d);
        if (results && results.length) {
            let new_permissions: { [id: string]: PermissionSet } = {
                ...this.state.permissions,
            };
            const result = results[0];
            const memo_key: string = result["memo_key"];
            const posting: Permission = result["posting"];
            const active: Permission = result["active"];
            const owner: Permission = result["owner"];
            let default_wif: string = "";
            let stored_role: string | null = sessionStorage.getItem(
                blockchain + "-role"
            );
            if (["owner", "active"].includes(stored_role ?? "")) {
                const high_power_wif = sessionStorage.getItem(
                    blockchain + "-private-key"
                );
                if (high_power_wif) default_wif = high_power_wif as string;
            }
            let common_posting_keys: Array<Array<any>>;
            if (this.state.common_posting_keys === null) {
                // clone so we don't corrupt the datamembers elsewhere used.
                common_posting_keys = [
                    ...(posting.key_auths as Array<Array<string | number>>),
                ];
            } else {
                let merged_list: Array<any> = [];
                for (const common_key_weight_pair of this.state
                    .common_posting_keys) {
                    for (const this_bc_key_weight_pair of posting.key_auths) {
                        if (
                            this_bc_key_weight_pair[0] ===
                            common_key_weight_pair[0]
                        ) {
                            merged_list.push(
                                this_bc_key_weight_pair as Array<
                                    string | number
                                >
                            );
                        }
                    }
                }
                common_posting_keys = merged_list;
            }
            let p: PermissionSet = {
                id: result["id"],
                memo: memo_key,
                privateMemo: null,
                posting: posting,
                active: active,
                owner: owner,
                jsonMetadata: result.jsonMetadata,
                recovery_account: result["recovery_account"],
                updating: false,
                internal_error: false,
                authorizingWif: default_wif,
                keychain: false,
            };

            if (this.keyChainAvailable(blockchain)) {
                // Steem Keychain, Hive Keychain extension installed...
                if (enableKeyChains) p.keychain = true;
            }
            new_permissions[blockchain] = p;

            this.setState({
                permissions: new_permissions,
                common_posting_keys: common_posting_keys,
            });
        }
    }

    handleAccountUpdate(blockchain: string, error: any, result: any) {
        //alert("handleAccountUpdate() called...");
        // avoid modifying this.state in place.
        let p: { [id: string]: PermissionSet } = Object.assign(
            {},
            this.state.permissions
        );
        if (!updateFunctionsWork) {
            // eslint-disable-next-line no-restricted-globals
            document.location = document.location;
        }

        if (blockchain === "hive")
            this.setState({ raw_hive: JSON.stringify(result) });
        if (blockchain === "steem")
            this.setState({ raw_steem: JSON.stringify(result) });
        if (blockchain === "blurt")
            this.setState({ raw_blurt: JSON.stringify(result) });

        // PermissionSet
        p[blockchain] = JSON.parse(JSON.stringify(p[blockchain]));
        //new_state['raw_' + blockchain] = JSON.stringify({'result': result, 'error': error}, null, 4); //new_state['raw_steem'] = new_state['raw_hive'] = JSON.stringify;(
        if (error === null) {
            // when you are adding the stuff is in data.  when taking away it's in 'operations'
            // This is from a keychain event.
            if (result["success"] && "data" in result) {
                result = result["data"];
                if (result["type"] === "addAccountAuthority") {
                    p[blockchain][result.role].account_auths.push([
                        result["authorizedUsername"],
                        result["weight"],
                    ]);
                } else {
                    p[blockchain][result.role].account_auths = p[blockchain][
                        result.role
                    ].account_auths.filter(function (value) {
                        return value[0] !== result["authorizedUsername"];
                    });
                }
                // This is the case of a raw API interaction
            } else if (
                result["expired"] === false &&
                result["operations"][0][0] === "account_update"
            ) {
                try {
                    this.log(
                        result["operations"][0][1]["owner"],
                        this.state.permissions[blockchain]
                    );
                    result = result["operations"][0][1];
                    this.log("p.steem=", p[blockchain], "result=", result);
                    if (result["posting"]) {
                        p[blockchain].posting = result.posting;
                    } else if (result["active"]) {
                        p[blockchain]["active"] = result["active"];
                    } else if (result["owner"]) {
                        p[blockchain]["owner"] = result["owner"];
                    }
                } catch (e) {}
            } else {
                this.log("No");
            }
        } else {
            alert("Error: " + error.message);
            this.log("Error:", error);
        }
        this.setState(
            this.clearUpdating.bind(this, blockchain, { permissions: p })
        );
    }

    setAuthorizingWIF(blockchain: string, e: ChangeEvent) {
        let ps = Object.assign({}, this.state.permissions);
        let pb = ps[blockchain];
        pb.authorizingWif = (e.target as HTMLInputElement).value;
        this.setState({ permissions: ps });
    }

    // I used a string array here instead of just passing two more parameters because of a bind parameter limit in nodejs
    revokeButtonClicked(blockchain: string, a: Array<string>, e: MouseEvent) {
        //this.log("revoke button clicked");
        let Wif: string = "";
        const username: string = a[0];
        const feduciary: string = a[1];
        const role: string = a[2];
        const required_role: string = a[3];
        if (this.can(blockchain, required_role))
            Wif =
                sessionStorage.getItem(blockchain + "-private-key") ??
                this.state.permissions[blockchain].authorizingWif;
        else Wif = this.state.permissions[blockchain].authorizingWif;
        this.revoke(blockchain, Wif, username, feduciary, role);
    }

    revoke(
        blockchain: string,
        activeWif: string,
        username,
        feduciary: string,
        role: string
    ) {
        this.log("revoking " + role + " from " + feduciary);
        if (this.state.permissions[blockchain].keychain) {
            keychainForBlockchain(blockchain).requestRemoveAccountAuthority(
                this.props.username,
                feduciary,
                role,
                this.handleAccountUpdate.bind(this, blockchain, null)
            );
        } else {
            //this.log("calling bc.broadcast.removeAccountAuth...");
            let ps: PermissionSet = Object.assign(
                {},
                this.state.permissions[blockchain]
            );
            const jsonMetadata = ps.jsonMetadata || "{}";
            ps[role] /*: Permission*/ = Object.assign({}, ps[role]);
            ps[role].account_auths /* Array */ = ps[role].account_auths.filter(
                (x) => x[0] !== feduciary
            );
            this.log("about to call accountUpdate...", ps, activeWif);
            if (this.state.whalevault) {
                let op = [
                    "account_update",
                    {
                        account: username,
                        json_metadata: jsonMetadata,
                        memo_key: ps.memo,
                        [role]: ps[role],
                    },
                ];
                APIForBlockchain(blockchain).api.getDynamicGlobalProperties(
                    this.sendWhaleVaultTX.bind(this, blockchain, username, [op])
                );
            } else {
                APIForBlockchain(blockchain).broadcast.accountUpdate(
                    activeWif || null,
                    username,
                    role !== "owner" ? undefined : ps.owner,
                    role !== "active" ? undefined : ps.active,
                    role !== "posting" ? undefined : ps.posting,
                    ps.memo,
                    jsonMetadata,
                    this.handleAccountUpdate.bind(this, blockchain)
                );
            }
        }
        this.setState(this.setUpdating.bind(this, blockchain));
    }

    addAccountAuthority(
        blockchain: string,
        activeWif: string,
        username,
        feduciary: string,
        role: string
    ) {
        const notAValidUsername = steem.utils.validateAccountName(feduciary);
        if (notAValidUsername) {
            alert("Not a valid username");
            return;
        }

        if (feduciary === username) {
            alert("Account authority shouldn't be given to oneself");
            return;
        }

        // set updating... this makes user wait for these routines to finish
        this.setState(this.setUpdating.bind(this, blockchain));

        if (this.state.permissions[blockchain].keychain) {
            keychainForBlockchain(blockchain).requestAddAccountAuthority(
                username,
                feduciary,
                role,
                this.state.permissions[blockchain][role].weight_threshold,
                this.handleAccountUpdate.bind(this, blockchain, null)
            );
        } else {
            this.log("calling bc.broadcast.addAccountAuth...");
            //this.log("calling bc.broadcast.removeAccountAuth...");
            let ps: PermissionSet = Object.assign(
                {},
                this.state.permissions[blockchain]
            );
            const jsonMetadata = ps.jsonMetadata || "{}";
            ps[role] /*: Permission */ = Object.assign({}, ps[role]);
            ps[role].account_auths /* Array */ = ps[role].account_auths.filter(
                (x) => x[0] !== feduciary
            );
            ps[role].account_auths.push([feduciary, ps[role].weight_threshold]);
            /*this.log(ps, "about to call accountUpdate(***WIF**, " + username, ps.owner, ps.active, ps.posting, ps.memo, ps.jsonMetadata,"this.handleAccountUpdate.bind(this, blockchain))");*/

            if (activeWif === "" && !this.state.whalevault) {
                this.setState(this.clearUpdating.bind(this, blockchain));
                if (whalevaultSupported)
                    alert(
                        "This operation requires you enter your private key or use the Whalevault" +
                            (blockchain === "steem"
                                ? " or SteemKeychain"
                                : blockchain === "hive"
                                ? "or Hive Keychain"
                                : "") +
                            " extensions"
                    );
                else
                    alert(
                        "This operation requires you enter your private key" +
                            (blockchain === "steem"
                                ? " or use the SteemKeychain extension"
                                : blockchain === "hive"
                                ? "or use the Hive Keychain extension"
                                : "")
                    );
                return;
            }
            try {
                if (whalevaultSupported && this.state.whalevault) {
                    let op = [
                        "account_update",
                        {
                            account: username,
                            json_metadata: jsonMetadata,
                            memo_key: ps.memo,
                        },
                    ];
                    op[1][role] = ps[role];
                    APIForBlockchain(blockchain).api.getDynamicGlobalProperties(
                        this.sendWhaleVaultTX.bind(this, blockchain, username, [
                            op,
                        ])
                    );
                } else {
                    if (debug) alert(JSON.stringify({ activeWif }));
                    /*,
							username,
							((role === 'owner') ? ps.owner : undefined),
							((role === 'active') ? ps.active : undefined),
							((role === 'posting') ? ps.posting : undefined),
							ps.memo,
							jsonMetadata		}));*/
                    APIForBlockchain(blockchain).broadcast.accountUpdate(
                        activeWif,
                        username,
                        role === "owner" ? ps.owner : undefined,
                        role === "active" ? ps.active : undefined,
                        role === "posting" ? ps.posting : undefined,
                        ps.memo,
                        jsonMetadata,
                        this.handleAccountUpdate.bind(this, blockchain)
                    );
                }
                this.log("API call successful");
            } catch (e) {
                this.log(e);
            }
        }
    }

    authorizePriviledgeButtonClicked(
        blockchain: string,
        feduciary: string,
        role: string,
        required_role: string,
        e: MouseEvent
    ) {
        if (feduciary.length > 0 && feduciary[0] === "@")
            feduciary = feduciary.slice(1);
        let Wif: string = "";
        if (this.can(blockchain, required_role)) {
            Wif =
                sessionStorage.getItem(blockchain + "-private-key") ??
                this.state.permissions[blockchain].authorizingWif;
        } else Wif = this.state.permissions[blockchain].authorizingWif;
        if (debug)
            alert(
                "DEBUG: bc:" +
                    blockchain +
                    ", f:" +
                    feduciary +
                    ", r:" +
                    role +
                    ", required_role: " +
                    required_role +
                    ", Wif:" +
                    Wif
            );
        this.addAccountAuthority(
            blockchain,
            Wif,
            this.props.username,
            feduciary,
            role
        );
    }

    newAccountToAuthorizeChanged(blockchain: string, e: ChangeEvent) {
        this.setState({
            new_account_to_authorize: (e.target as HTMLInputElement).value,
        });
    }

    recoveryAccountChanged(blockchain: string, e: ChangeEvent) {
        this.setState({
            new_recovery_account: (e.target as HTMLInputElement).value,
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    componentDidMount() {
        steem.api.getAccounts(
            [this.props.username],
            this.handleGetAccount.bind(this, "steem")
        );
        hive.api.getAccounts(
            [this.props.username],
            this.handleGetAccount.bind(this, "hive")
        );
        blurt.api.getAccounts(
            [this.props.username],
            this.handleGetAccount.bind(this, "blurt")
        );
        whaleshares.api.getAccounts(
            [this.props.username],
            this.handleGetAccount.bind(this, "whaleshares")
        );
        if (this.someKeyChainNeedsLoading())
            this.setState({
                interval_ID: setInterval(
                    this.checkForBrowserExtensions.bind(this),
                    2000
                ),
            });
        if (UIupdateTest) {
            setTimeout(
                this.handleAccountUpdate.bind(this, "steem", null, {
                    success: true,
                    error: null,
                    result: {
                        id: "64d0f832e74b77377bdc9c3aa08ef024890c7488",
                        block_num: 48626182,
                        trx_num: 2,
                        expired: false,
                        ref_block_num: 63980,
                        ref_block_prefix: 2938248905,
                        expiration: "2020-11-15T16:47:42",
                        operations: [
                            [
                                "account_update",
                                {
                                    account: "leprechaun",
                                    posting: {
                                        weight_threshold: 1,
                                        account_auths: [
                                            ["lauralemons", 1],
                                            ["steemfiles", 1],
                                            ["steempeak.app", 1],
                                        ],
                                        key_auths: [
                                            [
                                                "STM5Vfwn16i1grwYBHJsruR55e6mp18GuvAgC8zyP9Pavzk8sEuj2",
                                                1,
                                            ],
                                        ],
                                    },
                                    memo_key:
                                        "STM61ivTiiKzzUiTdMr8g4AohZHtcfDESoExsoHJcbmuFyFvrD3Mx",
                                    json_metadata:
                                        '{"profile":{"about":"Coder","location":"Argentina","profile_image":"https://cdn.steemitimages.com/DQmQtT7QPTsMxhTRsxket4VfriSNCSiBs6tAVgwa5xupi32/ShawnFace-Current-for-Steem-2.png","dtube_pub":"xDD1kgKfpwrcFgPpsCXcBaJ9y7VtmrPg6rLpgQgnUjsQ","pinned":"zoom-out-world-global-temperature-has-been-falling","portfolio":"enabled"}}',
                                },
                            ],
                        ],
                        extensions: [],
                        signatures: [
                            "204d63e332b4c03d432dc8adcf714721a7799d6eade07eff9533122c463e13980631e0535d5d31f1d381bc3581b823bf0c56f7486ba99a10e8e42a2dd45c22a7f2",
                        ],
                    },
                    data: {
                        request_id: 1,
                        type: "addAccountAuthority",
                        username: "leprechaun",
                        authorizedUsername: "lauralemons",
                        role: "posting",
                        weight: 1,
                        method: "Active",
                        key: "active",
                    },
                    message:
                        "Succesfully gave posting authority to @lauralemons on account @leprechaun.",
                    request_id: 1,
                }),
                6000
            );
            setTimeout(
                this.handleAccountUpdate.bind(this, "steem", null, {
                    success: true,
                    error: null,
                    result: {
                        id: "01f745124ff163ff6b1f3bb45902dd717cbeb75a",
                        block_num: 48626649,
                        trx_num: 4,
                        expired: false,
                        ref_block_num: 64456,
                        ref_block_prefix: 3734337895,
                        expiration: "2020-11-15T17:11:36",
                        operations: [
                            [
                                "account_update",
                                {
                                    account: "leprechaun",
                                    posting: {
                                        weight_threshold: 1,
                                        account_auths: [
                                            ["steemfiles", 1],
                                            ["steempeak.app", 1],
                                        ],
                                        key_auths: [
                                            [
                                                "STM5Vfwn16i1grwYBHJsruR55e6mp18GuvAgC8zyP9Pavzk8sEuj2",
                                                1,
                                            ],
                                        ],
                                    },
                                    memo_key:
                                        "STM61ivTiiKzzUiTdMr8g4AohZHtcfDESoExsoHJcbmuFyFvrD3Mx",
                                    json_metadata:
                                        '{"profile":{"about":"Coder","location":"Argentina","profile_image":"https://cdn.steemitimages.com/DQmQtT7QPTsMxhTRsxket4VfriSNCSiBs6tAVgwa5xupi32/ShawnFace-Current-for-Steem-2.png","dtube_pub":"xDD1kgKfpwrcFgPpsCXcBaJ9y7VtmrPg6rLpgQgnUjsQ","pinned":"zoom-out-world-global-temperature-has-been-falling","portfolio":"enabled"}}',
                                },
                            ],
                        ],
                        extensions: [],
                        signatures: [
                            "1f59f9c45897b786f4f843a59ed4332b3cf4cb64f2cf0e3e32a5c0e98a532c0b8e1822cda97d190d26c2bed3aaba396069abc9e3a5eef01f9530c9a4828ce3f692",
                        ],
                    },
                    data: {
                        request_id: 1,
                        type: "removeAccountAuthority",
                        username: "leprechaun",
                        authorizedUsername: "lauralemons",
                        role: "posting",
                        method: "Active",
                        key: "active",
                    },
                    message:
                        "Succesfully removed posting authority from @lauralemons on account @leprechaun.",
                    request_id: 1,
                }),
                15000
            );
        }
    }

    hasKey(role, key, blockchain): boolean {
        return !this.doesNotHaveKey(role, key, blockchain);
    }

    // this key of this role doesn't exist on blockchain
    doesNotHaveKey(role, key, blockchain): boolean {
        try {
            if (!this.state.permissions[blockchain]) {
                return true;
            }

            const key_auths = this.state.permissions[blockchain][role]
                .key_auths;

            if (!key_auths && once) {
                alert(JSON.stringify(key_auths));
                once = false;
            }
            for (const kw of key_auths) {
                if (kw[0].slice(3) === key.slice(3)) {
                    return false;
                }
            } // for
        } catch (e) {
            if (once) {
                once = false;
                alert(e.message);
            }
            // This happens when blockchain not yet registered in this.state.permissions...
            return false;
        }
        return true;
    }

    addNewKeyAuthority(role, export_bc_pubilc_key, export_bc, e) {
        // permission set table PST
        let pst: { [id: string]: PermissionSet } = Object.assign(
            {},
            this.state.permissions
        );
        let private_key: string = "";
        if (!export_bc_pubilc_key && !export_bc) {
            // TO DO: generate keys
            throw new Error("Unsupported Parameters");
        } else if (export_bc_pubilc_key && export_bc) {
            if (
                sessionStorage.getItem(export_bc + "-public-key") ===
                export_bc_pubilc_key
            ) {
                private_key =
                    sessionStorage.getItem(export_bc + "-private-key") ?? "";
            }
        } else {
            throw new Error("Invalid parameters");
        }
        // TO DO: add the keys to the Account
        if (!private_key) {
            throw Error("Could not determine the private key");
        }
        let public_key: string;
        for (const loop_bc in pst) {
            if (loop_bc === export_bc) continue;

            public_key = APIForBlockchain(loop_bc).auth.wifToPublic(
                private_key
            );
            let loop_bc_does_not_have_key = true;
            let ps: PermissionSet = Object.assign({}, pst[loop_bc]);
            let key_auths: Array<Array<string | number>> = ps[role].key_auths;
            const jsonMetadata = ps.jsonMetadata || "{}";

            for (const key_auth of key_auths) {
                if (key_auth[0] === export_bc_pubilc_key) {
                    loop_bc_does_not_have_key = false;
                }
            }
            if (loop_bc_does_not_have_key) {
                key_auths.push([
                    public_key,
                    pst[loop_bc][role].weight_threshold,
                ]);
            }

            let activeWif: string | null;

            if (
                (sessionStorage.getItem(loop_bc + "-role") === "active" ||
                    sessionStorage.getItem(loop_bc + "-role") === "owner") &&
                (activeWif = sessionStorage.getItem(
                    loop_bc + "-private-key"
                )) !== null
            ) {
                // set updating... this makes user wait for these routines to finish
                this.setState(this.setUpdating.bind(this, loop_bc));

                APIForBlockchain(loop_bc).broadcast.accountUpdate(
                    activeWif as string,
                    this.props.username,
                    role !== "owner" ? undefined : ps.owner,
                    role !== "active" ? undefined : ps.active,
                    role !== "posting" ? undefined : ps.posting,
                    ps.memo,
                    jsonMetadata,
                    this.handleAccountUpdate.bind(this, loop_bc)
                );
            } else {
                alert(
                    "Logout and login again with a Wif key for this operation."
                );
            }
        } // end for

        // Return the key.
        return private_key;
    }

    makeUniversal(bc: string, role, public_key, private_key) {
        if (
            (!private_key && !sessionStorage.getItem(bc + "-private-key")) ||
            sessionStorage.getItem(bc + "-role") !== "posting"
        ) {
            alert("You must login with your private key for " + titlecase(bc));
            return;
        } // if
    }

    showKey(blockchain, common_role, kwp) {
        return kwp[0];
    }

    isKeyTransferable(export_bc_name, role) {
        for (const loop_bc_name in this.state.permissions) {
            const authenticating_role = sessionStorage.getItem(
                loop_bc_name + "-role"
            );
            if (loop_bc_name === export_bc_name) {
                if (authenticating_role !== role) return false;
            } else if (role === "posting" || role === "active") {
                if (
                    authenticating_role !== "active" &&
                    authenticating_role !== "owner"
                )
                    return false;
            } else if (role === "owner") {
                if (authenticating_role !== "owner") return false;
            } else {
                return false;
            }
        }
        return true;
    }

    // Returns true if the level of action can be done with logged in credentials
    //
    // Keychains are assumed to have 'active' permissions.  See SteemUserButton for details
    // on that.
    can(blockchain: string, role: string): boolean {
        if (
            !this.props.accounts.find(
                (account) =>
                    account.username === this.props.username &&
                    account.provider === blockchain
            )
        )
            return false;
        if (role === "owner") {
            return this.state.logged_in_role[blockchain] === "owner";
        } else if (role === "active") {
            return ["owner", "active"].includes(
                this.state.logged_in_role[blockchain]
            );
        } else if (role === "posting") {
            return ["owner", "active", "posting"].includes(
                this.state.logged_in_role[blockchain]
            );
        }
        return true;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    render() {
        const possible_roles = ["owner", "active", "posting", "memo", ""];
        let can_make_universal_key: boolean | null = null;
        let common_role: string = "owner";
        for (const bc of supportedBlockchains()) {
            if (this.state.logged_in_role[bc.name] === common_role) continue;
            try {
                const i1: number = possible_roles.indexOf(common_role);
                const i2: number = possible_roles.indexOf(
                    this.state.logged_in_role[bc.name]
                );
                let i_max: number = Math.max(i1, i2);
                if (i_max === -1) common_role = "";
                else common_role = possible_roles[i_max];
            } catch (e) {
                // value error
                common_role = "";
            }
        } // bc
        can_make_universal_key = ["owner", "active"].includes(common_role);
        return (
            <div key={JSON.stringify(this.props.accounts)}>
                <dialog key="dialog">
                    Write the owner key in order to change the recovery account
                    <input type="text" className="KeyInput" />
                </dialog>

                <article>
                    {debug && JSON.stringify(this.state.logged_in_role)}
                    <h1>Permissions and Keys</h1>
                    {this.props.username}

                    <h2>Don't throw your old keys away!</h2>
                    <p>
                        Even after you change your keys, which happens if you
                        change your master password, there are various reasons
                        to keep your old keys. The ability to decrypt memos on
                        old transactions require you to keep your old keys
                        stored somewhere. And your old keys might belong to some
                        blockchain fork you have not heard about yet.
                    </p>

                    <h2>Memo Keys</h2>
                    <p>
                        Memo key pairs are purely for encryption (with the
                        public key) and decryption (with the private key) of
                        memos of transactions. They actually have no special
                        meaning apart from this. and there is no facility to
                        allow others to view encrypted memos or allow others to
                        do so. After all, it only works by encrypting and
                        decrypting information rather than permissions. Inspite
                        of its low value, it should only be changed by changing
                        the master password.
                    </p>
                </article>

                <article>
                    <h2>Posting Permissions</h2>

                    <p>
                        Posting permissions allow users to post, comment, vote
                        or flag.
                    </p>
                    {Object.keys(this.state.permissions).map((blockchain) => (
                        <div key={blockchain} className={blockchain}>
                            {titlecase(blockchain)}
                            {showKeyRole &&
                                this.can(blockchain, "posting") &&
                                " (logged in with " +
                                    this.state.logged_in_role[blockchain] +
                                    "key)"}
                            {this.isUpdating(blockchain) && "updating..."}
                            <br />
                            {enableWIFs &&
                            (blockchain !== "blurt" || blurtRPCworks) &&
                            !this.isUpdating(blockchain) &&
                            !this.can(blockchain, "active") ? (
                                <label>
                                    Authorizing Active or Owner WIF
                                    {
                                        <input
                                            type="password"
                                            className="KeyInput"
                                            defaultValue={
                                                this.state.permissions[
                                                    blockchain
                                                ].authorizingWif
                                            }
                                            onChange={this.setAuthorizingWIF.bind(
                                                this,
                                                blockchain
                                            )}
                                            placeholder="active or owner key"
                                        />
                                    }
                                </label>
                            ) : (
                                blockchain === "blurt" && !blurtRPCworks && (
                                    <span>
                                        The Blurt permission changes are not
                                        supported
                                    </span>
                                )
                            )}
                            {whalevaultSupported &&
                                !this.state.whalevault &&
                                !enableWIFs &&
                                !this.isUpdating(blockchain) &&
                                !this.state.permissions[blockchain].keychain &&
                                "Install the Whalevault extension to modify these parameters"}
                            <div
                                className={
                                    this.state.permissions[blockchain].updating
                                        ? "gone"
                                        : "subitem"
                                }
                            >
                                {(enableWIFs ||
                                    this.can(blockchain, "active")) &&
                                    (blockchain !== "blurt" ||
                                        blurtRPCworks) && (
                                        <div>
                                            <label>
                                                Authorize Posting Priviledge on{" "}
                                                {blockchain} to:
                                                <input
                                                    type="text"
                                                    className="UsernameInput"
                                                    placeholder="username"
                                                    size={16}
                                                    defaultValue={
                                                        this.state
                                                            .new_account_to_authorize
                                                    }
                                                    onChange={this.newAccountToAuthorizeChanged.bind(
                                                        this,
                                                        blockchain
                                                    )}
                                                />
                                            </label>
                                            <button
                                                onClick={this.authorizePriviledgeButtonClicked.bind(
                                                    this,
                                                    blockchain,
                                                    this.state
                                                        .new_account_to_authorize,
                                                    "posting",
                                                    "active"
                                                )}
                                            >
                                                Authorize
                                            </button>
                                        </div>
                                    )}
                                <AuthorizedAccountsComponent
                                    username={this.props.username}
                                    permissionSet={
                                        this.state.permissions[blockchain]
                                    }
                                    blockchain={blockchain}
                                    aspect="posting"
                                    onRevoke={this.revokeButtonClicked.bind(
                                        this,
                                        blockchain
                                    )}
                                    setURL={this.props.setURL}
                                />
                            </div>
                        </div>
                    ))}
                    {reportCommonKeys && (
                        <article>
                            <h2>
                                Common Posting Key
                                {this.state.common_posting_keys &&
                                    this.state.common_posting_keys.length > 1 &&
                                    "s"}
                            </h2>
                            <p>
                                Common keys allow you to login to Steemfiles for
                                all of your blockchains in one single step. This
                                process will leave your existing keys in place.
                            </p>
                            {createKeyFeature &&
                                (can_make_universal_key || (
                                    <p>
                                        In order to generate a new posting key
                                        login with your active key for each
                                        blockchain.
                                    </p>
                                ))}
                            {this.state.common_posting_keys === null ||
                            this.state.common_posting_keys.length === 0 ? (
                                <p>
                                    There aren't any common keys.
                                    {createKeyFeature &&
                                        can_make_universal_key && (
                                            <button onClick={NYI}>
                                                Generate A New Common Posting
                                                Key
                                            </button>
                                        )}
                                </p>
                            ) : (
                                this.state.common_posting_keys.map((key) => (
                                    <p>{key}</p>
                                ))
                            )}
                        </article>
                    )}
                    <h2>Posting Keys</h2>
                    {["hive", "whaleshares", "steem", "blurt"]
                        .filter(
                            (x) =>
                                x !== "steem" &&
                                (sessionStorage.getItem(x + "-role") ===
                                    "active" ||
                                    sessionStorage.getItem(x + "-role") ===
                                        "owner")
                        )
                        .join(", ")}

                    {Object.keys(this.state.permissions).map((blockchain) => (
                        <div key={blockchain} className={blockchain}>
                            {titlecase(blockchain)}
                            {showKeyRole &&
                                this.can(blockchain, "posting") &&
                                " (logged in with " +
                                    this.state.logged_in_role[blockchain] +
                                    "key)"}
                            {this.isUpdating(blockchain) && "updating..."}
                            <br />

                            {this.state.permissions[
                                blockchain
                            ].posting.key_auths.map((keyweight) => (
                                <div key={keyweight[0]}>
                                    {keyweight[0]}
                                    {
                                        ""
                                        /* The button works well but it should be invisible when it would be a null-op
                                         */
                                    }
                                    {createKeyFeature &&
                                        (blockchain === "blurt" ||
                                            blurtRPCworks) &&
                                        [
                                            "hive",
                                            "whaleshares",
                                            "steem",
                                            "blurt",
                                        ].filter(
                                            (other_chain) =>
                                                other_chain !== blockchain &&
                                                (sessionStorage.getItem(
                                                    other_chain + "-role"
                                                ) === "active" ||
                                                    sessionStorage.getItem(
                                                        other_chain + "-role"
                                                    ) === "owner") &&
                                                this.doesNotHaveKey(
                                                    "posting",
                                                    keyweight[0],
                                                    other_chain
                                                )
                                        ).length !== 0 && (
                                            <button
                                                onClick={this.addNewKeyAuthority.bind(
                                                    this,
                                                    "posting",
                                                    keyweight[0],
                                                    blockchain
                                                )}
                                            >
                                                {"Give this key to " +
                                                    [
                                                        "hive",
                                                        "whaleshares",
                                                        "steem",
                                                        "blurt",
                                                    ]
                                                        .filter(
                                                            (other_chain) =>
                                                                other_chain !==
                                                                    blockchain &&
                                                                (sessionStorage.getItem(
                                                                    other_chain +
                                                                        "-role"
                                                                ) ===
                                                                    "active" ||
                                                                    sessionStorage.getItem(
                                                                        other_chain +
                                                                            "-role"
                                                                    ) ===
                                                                        "owner") &&
                                                                this.doesNotHaveKey(
                                                                    "posting",
                                                                    keyweight[0],
                                                                    other_chain
                                                                )
                                                        )
                                                        .join(", ")}
                                            </button>
                                        )}
                                </div>
                            ))}
                        </div>
                    ))}
                </article>
                <article>
                    <h2>Active Permissions</h2>
                    <p>
                        You probably shouldn't add any users to the permissions
                        of active or owner and if there any you ought to revoke
                        them.
                    </p>
                    <p>
                        Active permissions allow for transfers or{" "}
                        <b>stealing of your funds</b> and for changing, removing
                        or adding posting keys or granting posting permissions.
                    </p>
                    <p>
                        You could leave this with your child keeping your money
                        in savings or powered up. Then should something happen
                        to you, they can inherit your currency.
                    </p>
                    {Object.keys(this.state.permissions).map((blockchain) => (
                        <div
                            key={blockchain + "+active"}
                            className={blockchain}
                        >
                            {titlecase(blockchain)}
                            {showKeyRole &&
                                this.can(blockchain, "posting") &&
                                " (logged in with " +
                                    this.state.logged_in_role[blockchain] +
                                    "key)"}
                            {this.isUpdating(blockchain) && "updating..."}
                            <br />

                            {enableWIFs &&
                            (blockchain !== "blurt" || blurtRPCworks) &&
                            !this.can(blockchain, "active") ? (
                                <label>
                                    Authorizing WIF
                                    <input
                                        type="password"
                                        className="KeyInput"
                                        defaultValue={
                                            this.state.permissions[blockchain]
                                                .authorizingWif
                                        }
                                        onChange={this.setAuthorizingWIF.bind(
                                            this,
                                            blockchain
                                        )}
                                        placeholder="active or owner key"
                                    />
                                </label>
                            ) : (
                                blockchain === "blurt" && !blurtRPCworks && (
                                    <span>
                                        The Blurt permission changes are not
                                        supported
                                    </span>
                                )
                            )}
                            {whalevaultSupported &&
                                !this.state.whalevault &&
                                !enableWIFs &&
                                (blockchain !== "blurt" || blurtRPCworks) &&
                                !this.isUpdating(blockchain) &&
                                !this.state.permissions[blockchain].keychain &&
                                "Install the Whalevault extension to modify these parameters"}
                            <div
                                className={
                                    this.state.permissions[blockchain].updating
                                        ? "gone"
                                        : "subitem"
                                }
                            >
                                {(enableWIFs ||
                                    this.state.permissions[blockchain]
                                        .keychain) &&
                                    (blockchain !== "blurt" ||
                                        blurtRPCworks) && (
                                        <label>
                                            Authorize Active Priviledge on{" "}
                                            {blockchain} to:
                                            <input
                                                type="text"
                                                className="UsernameInput"
                                                placeholder="username"
                                                defaultValue={
                                                    this.state
                                                        .new_account_to_authorize
                                                }
                                                onChange={this.newAccountToAuthorizeChanged.bind(
                                                    this,
                                                    blockchain
                                                )}
                                            />
                                            {false &&
                                                "You only need an active key to give active permission"}
                                            <button
                                                onClick={this.authorizePriviledgeButtonClicked.bind(
                                                    this,
                                                    blockchain,
                                                    this.state
                                                        .new_account_to_authorize,
                                                    "active",
                                                    "active"
                                                )}
                                            >
                                                Authorize
                                            </button>
                                        </label>
                                    )}
                                <AuthorizedAccountsComponent
                                    username={this.props.username}
                                    permissionSet={
                                        this.state.permissions[blockchain]
                                    }
                                    blockchain={blockchain}
                                    aspect="active"
                                    onRevoke={this.revokeButtonClicked.bind(
                                        this,
                                        blockchain
                                    )}
                                    setURL={this.props.setURL}
                                />
                            </div>
                        </div>
                    ))}

                    <h2>Owner Permissions</h2>
                    <p>
                        Owner permissions allow for another user to recovery
                        your account but they could also{" "}
                        <b>steal it from you!</b>
                    </p>
                    <p>
                        You probably want to leave these without any permissions
                        and revoke if there are any.
                    </p>
                    <p>
                        The recovery account must be set and it allows another
                        user to help you to recovery your account <i>without</i>{" "}
                        giving them the ability to steal it from you
                    </p>
                    {Object.keys(this.state.permissions).map((blockchain) => (
                        <div key={blockchain + "+owner"} className={blockchain}>
                            {titlecase(blockchain)}
                            {showKeyRole &&
                                this.can(blockchain, "posting") &&
                                " (logged in with " +
                                    this.state.logged_in_role[blockchain] +
                                    "key)"}
                            {this.isUpdating(blockchain) && "updating..."}
                            <br />
                            <div
                                className={
                                    this.state.permissions[blockchain].updating
                                        ? "gone"
                                        : "subitem"
                                }
                            >
                                {enableWIFs &&
                                (blockchain !== "blurt" || blurtRPCworks) &&
                                !this.can(blockchain, "owner") ? (
                                    <label>
                                        Authorizing Owner WIF:
                                        <input
                                            type="password"
                                            defaultValue={
                                                this.state.permissions[
                                                    blockchain
                                                ].authorizingWif
                                            }
                                            className="KeyInput"
                                            onChange={this.setAuthorizingWIF.bind(
                                                this,
                                                blockchain
                                            )}
                                            placeholder="owner key"
                                        />
                                    </label>
                                ) : (
                                    blockchain === "blurt" && !blurtRPCworks && (
                                        <span>
                                            The Blurt permission changes are not
                                            supported
                                        </span>
                                    )
                                )}
                                <AuthorizedAccountsComponent
                                    username={this.props.username}
                                    permissionSet={
                                        this.state.permissions[blockchain]
                                    }
                                    blockchain={blockchain}
                                    aspect="owner"
                                    onRevoke={this.revokeButtonClicked.bind(
                                        this,
                                        blockchain
                                    )}
                                    setURL={this.props.setURL}
                                />
                            </div>
                            <br />
                            <RecoverySection
                              blockchain={blockchain}
                              recovery_account={this.state.permissions[blockchain].recovery_account}
                              setURL={this.props.setURL}
                            />
                        </div>
                    ))}

                    <h2>Master Password</h2>
                    <p>
                        Master passwords is used to derive all four keys.
                        Setting a new password puts a set of new keys in place.
                    </p>

                    {/*Object.keys(this.state.permissions).map(blockchain =>(<div className={blockchain}>
				{blockchain} <label>Authorizing Owner WIF: <input type='password' defaultValue={this.state.permissions[blockchain].authorizingWif} onChange={this.setAuthorizingWIF.bind(this,blockchain)} placeholder='owner key' /></label>
				{this.state.permissions[blockchain].updating && <span>updating</span>}
				<div  className={this.state.permissions[blockchain].updating ? 'gone' : 'subitem'}>
					<label>New Master Password: <input type='text' size={50} defaultValue='' /></label>
					<button onClick={NYI}>Change Master Password (and associated keys)</button>

				</div>
			</div>))*/}

                    <hr />
                    <hr />
                    {showDebugInfo && (
                        <div id="devdiv">
                            Debug Info Starts
                            <br />
                            <div className="steem">
                                Steem
                                <br /> {this.state.raw_steem}
                            </div>
                            <div className="hive">
                                Hive <br /> {this.state.raw_hive}{" "}
                            </div>
                            <pre>{this.state.debug_info}</pre>
                        </div>
                    )}
                </article>
            </div>
        );
    }
}
export default PermissionsComponent;
