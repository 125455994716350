// adds thousands separators
// assumption: American style: commas for separators, and period as the decimal point.
function addCommas(n_p: string | number): string {
    let n: string = n_p + "";
    const non_digit_search = (n.search(/[^.0-9,]/) + n.length + 1) % (n.length + 1);
    const n_search = (n.indexOf(".") + non_digit_search + 1) % (non_digit_search + 1);

    const dp = n_search;
    let i = dp + 4;
    while (i < non_digit_search) {
        n = n.slice(0, i) + "," + n.slice(i);
        i += 4;
    }
    i = dp - 3;
    while (i > 0) {
        n = n.slice(0, i) + "," + n.slice(i);
        i -= 3;
    }
    return n;
}

export default addCommas;
