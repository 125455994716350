import React from "react";
import "./App.css";
import addCommas from "./addCommas";
import AgeWidget from "./AgeWidget";

const backgroundColor = undefined;
const color = undefined;

interface currency_descriptor {
    short_name: string;
    name: string;
    last_update: number;
    rate_btc: string;
}

interface AppState {
    last_update: number;
    currencies: object;
    interval_ID: NodeJS.Timeout | null;
    error: null | string;
    new_dev_data: string;
}

class Ticker extends React.Component<{}, AppState> {
    private debug = false;

    constructor(props: any) {
        super(props);
        const currencies = {
            USD: {
                short_name: "USD",
                name: "US Dollar",
                last_update: 1585256014,
                rate_btc: null,
            },

            BTC: {
                short_name: "BTC",
                name: "Bitcoin (Segwit)",
                last_update: 1585256014,
                rate_btc: "1.00000000000000",
            },
        };

        this.state = {
            last_update: 1585256014,
            currencies: currencies,
            new_dev_data: "initial",
            interval_ID: null,
            error: null,
        };

        this.currency_pair = this.currency_pair.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        setInterval(this.fetchData, 120 * 1000);
    }

    handleFetch([
        btc_sbd,
        btc_eth,
        btc_dash,
        btc_steem,
        btc_hive,
        usd_btc,
        btc_hbd,
        btc_tusd,
        btc_dai,
    ]: Array<any>): boolean {
        //		console.log(usd_btc, btc_steem,btc_sbd);

        console.log("Handling fetch...");
        this.setState(function (old_state: any) {
            const last_update: number = Math.round(new Date().getTime() / 1000);
            let c = old_state.currencies;

            c["USD"] = {
                short_name: "USD",
                name: "US Dollar",
                last_update: last_update,
                rate_btc: 1 / usd_btc["result"].Last + "",
            };

            if (btc_eth && btc_eth["result"])
                c["ETH"] = {
                    short_name: "ETH",
                    name: "Ether",
                    last_update: last_update,
                    rate_btc: btc_eth["result"].Last + "",
                };

            if (btc_dash && btc_dash["result"])
                c["DASH"] = {
                    short_name: "Dash",
                    name: "Dash",
                    last_update: last_update,
                    rate_btc: btc_dash["result"].Last + "",
                };

            if (btc_steem && btc_steem["result"])
                c["STEEM"] = {
                    short_name: "STEEM",
                    name: "Steem",
                    last_update: last_update,
                    rate_btc: btc_steem["result"].Last + "",
                };

            if (btc_hive && btc_hive["result"])
                c["HIVE"] = {
                    short_name: "HIVE",
                    name: "Hive",
                    last_update: last_update,
                    rate_btc: btc_hive["result"].Last + "",
                };

            if (btc_sbd && btc_sbd["result"])
                c["SBD"] = {
                    short_name: "SBD",
                    name: "Steem Dollar",
                    last_update: last_update,
                    rate_btc: btc_sbd["result"].Last + "",
                };

            if (btc_hbd && btc_hbd["result"])
                c["HBD"] = {
                    short_name: "HBD",
                    name: "Hive Dollar",
                    last_update: last_update,
                    rate_btc: btc_hbd["result"].Last + "",
                };

            if (btc_tusd && btc_tusd["result"])
                c["TUSD"] = {
                    short_name: "TUSD",
                    name: "True USD",
                    last_update: last_update,
                    rate_btc: btc_tusd["result"].Last + "",
                };

            if (btc_dai && btc_dai["result"])
                c["DAI"] = {
                    short_name: "DAI",
                    name: "DAI",
                    last_update: last_update,
                    rate_btc: btc_dai["result"].Last + "",
                };

            return { currencies: c, last_update: last_update + 0 };
        }); // setState
        return true;
    }

    fetchData() {
        const timestamp = Math.round(new Date().getTime() / 1000);
        Promise.all([
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-SBD&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-ETH&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-DASH&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-STEEM&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-HIVE&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=USD-BTC&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-HBD&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-TUSD&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
            fetch(
                "/cgi-bin/bittrexgetticker.cgi?market=BTC-DAI&timestamp=" +
                    timestamp
            ).then(
                (res) => res.json(),
                function (error) {
                    console.log(error);
                    return false;
                }
            ),
        ])

            .then(this.handleFetch) // then
            .catch((e) => {
                console.log(e);
                this.setState({ error: e.message });
            });
    }

    componentDidMount() {
        setTimeout(this.fetchData, 0);
        this.setState({ interval_ID: setInterval(this.fetchData, 4000) });
    }

    componentWillUnmount() {
        if (this.state.interval_ID != null)
            clearInterval(this.state.interval_ID);
    }

    currency_pair(value_of: string, in_what: string): number | null {
        const currencies: object = this.state.currencies;
        if (value_of === in_what) return 1;
        if (!(value_of in currencies)) {
            if (in_what in currencies && value_of === "BTC") {
                const reciprocal: number | null = this.currency_pair(
                    in_what,
                    value_of
                );
                if (reciprocal === null) return null;
                return 1 / reciprocal;
            }
            throw new Error(value_of + " not in currencies");
        }
        const value_of_data: currency_descriptor = currencies[value_of];
        const value_of_in_btc: number | null =
            value_of_data.rate_btc !== null
                ? parseFloat(value_of_data.rate_btc)
                : null;
        if (in_what === "BTC") {
            return value_of_in_btc;
        }
        const what_in_data: currency_descriptor = currencies[in_what];
        const what_in_in_btc: number | null =
            what_in_data.rate_btc === null
                ? null
                : parseFloat(what_in_data.rate_btc);

        if (true) {
            //console.log(value_of, in_what);
            //console.log(what_in_data);
            //console.log(what_in_data.rate_btc);
            //console.log(value_of_in_btc);
        }
        const answer =
            what_in_in_btc === null || value_of_in_btc === null
                ? null
                : value_of_in_btc / what_in_in_btc;
        //console.log(answer);
        return answer;
    }

    renderPair(
        textBefore: string,
        currency_value: string,
        currency_in: string,
        precision: number,
        textAfter: string
    ) {
        try {
            const raw_rate = this.currency_pair(currency_value, currency_in);
            if (raw_rate === null) return "loading...";
            return (
                textBefore + addCommas(raw_rate.toFixed(precision)) + textAfter
            );
        } catch (e) {
            console.log(
                "Error rendering:",
                textBefore,
                currency_value,
                currency_in,
                precision,
                textAfter,
                ":",
                e.message
            );
            for (let key in e) {
                console.log(key, "->", e[key]);
            }
        }
        return "";
    }

    render() {
        var now = new Date();
        // in seconds.
        var time_difference = Math.floor(
            (now.getTime() - 1000 * this.state.last_update) / 1000
        );
        if (time_difference < 0) time_difference = 0;
        return (
            <div style={{ backgroundColor: backgroundColor, color: color }}>
                {this.state.error}
                <h1>Bitcoin (BTC)</h1>
                <h2>
                    {this.renderPair("1 BTC = ", "BTC", "USD", 0, " USD")}
                    <br />
                    {this.renderPair("1 USD = ", "USD", "BTC", 8, " BTC")}
                    <br />
                </h2>
                <h1>Ether (ETH)</h1>
                <hr />
                {this.renderPair("1 ETHER = ", "ETH", "USD", 0, " USD")}
                <br />
                {this.renderPair("1 Ether   = ", "ETH", "BTC", 3, " BTC")}
                <br />
                <h1>Stable Coins</h1>
                <hr />
                <table>
                    <thead>
                        <tr>
                            <th>Hive Dollar</th>
                            <th>Steem Dollar</th>
                            <th>True USD</th>
                            <th>Dai</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {this.renderPair("", "HBD", "USD", 5, " USD")}
                            </td>
                            <td>
                                {this.renderPair("", "SBD", "USD", 5, " USD")}
                            </td>
                            <td>
                                {this.renderPair("", "TUSD", "USD", 5, " USD")}
                            </td>
                            <td>
                                {this.renderPair("", "DAI", "USD", 5, " USD")}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                Last Update:{" "}
                {this.state.last_update === 1585256014 ? (
                    "not yet"
                ) : (
                    <AgeWidget
                        key={this.state.last_update}
                        timestamp={this.state.last_update}
                        precise={true}
                    />
                )}
            </div>
        );
    }
}

export default Ticker;
