import React from "react";

const color = undefined;
const backgroundColor = undefined;

interface AgeWidgetProps {
    // timestamp should be seconds of the unix epoch.
    timestamp: number | Date | string;
    precise?: boolean;
}

interface AgeWidgetState {
    startTime: number;
    age: number;
}

function plural(age: number | string): string {
    age = age + "";
    if (age === "1") return "";
    return "s";
}

// The number of seconds such that this value and above will not be expressed in Hours.
const expressedInHoursLeastUpperBound = 72 * 3600;
// The minimal number of seconds such that the time is expressed in hours.
const expressedInHoursGreatestLowerBound = 90 * 60;

function secondsToString(age: number, precise: boolean): string {
    if (age < 0) {
        const seconds: string = (-age).toFixed(0);
        return (
            seconds +
            " second" +
            (Math.floor(age) === 1 ? "" : plural(seconds)) +
            " from now"
        );
    } else if (age < 90) {
        const seconds: string = age.toFixed(0);
        return (
            seconds +
            " second" +
            (Math.floor(age) === 1 ? "" : plural(seconds)) +
            " ago"
        );
    } else if (age < expressedInHoursGreatestLowerBound) {
        const minutes = (age / 60).toFixed(0);
        if (precise) {
            return (
                minutes +
                " minute" +
                plural(minutes) +
                " and " +
                secondsToString(age % 60, precise)
            );
        }
        return minutes + " minute" + plural(minutes) + " ago";
    } else if (age < expressedInHoursLeastUpperBound) {
        const hours = (age / 3600).toFixed(0);
        return hours + " hour" + plural(hours) + " ago";
    } else if (age < 42 * 86400) {
        const days = (age / (3600 * 24)).toFixed(0);
        return days + " days ago";
    } else if (age < 8 * 604800) {
        const weeks = (age / 604800).toFixed(0);
        return weeks + " weeks ago";
    } else if (age < 2 * 31536000) {
        const months = (age / 2592000).toFixed(0);
        return months + " months ago";
    } else {
        const y = age / 31536000;
        return y + " years ago";
    }
}

class AgeWidget extends React.Component<AgeWidgetProps, AgeWidgetState> {
    // Typescript doesn't see the initilization of timer in the constructor,
    // as it is supposed to, so a '!' must be put in there below:
    private timer!: null | ReturnType<typeof setInterval>;

    getAge(startTime: number) {
        //console.log("calling setAge()");
        let now = new Date();
        const age = Math.round(now.getTime() / 1000 - startTime); // in seconds
        return age;
    }

    setAge() {
        const age = this.getAge(this.state.startTime);
        if (age !== this.state.age) this.setState({ age: age });
    }

    is_precise() {
        try {
            return this.props.precise;
        } catch (e) {
            return false;
        }
    }

    render() {
        //console.log("rendering...");
        let age: number = this.state.age;
        if (isNaN(age)) {
            return <span />;
        }
        return (
            <span style={{ backgroundColor: backgroundColor, color: color }}>
                {isNaN(age) ||
                    secondsToString(
                        age,
                        this.is_precise() === undefined
                            ? false
                            : this.is_precise() === false
                            ? false
                            : true
                    )}
            </span>
        );
    }

    setHourlyTimer() {
        const setAge = this.setAge.bind(this);
        this.timer = setInterval(setAge, 3600 * 1000);
    }

    constructor(props: AgeWidgetProps) {
        super(props);

        let startTime: number;
        try {
            if ((props.timestamp as string).length) {
                // a string
                const s = props.timestamp as string;
                const startTimeMilliseconds: number = Date.parse(
                    s.endsWith("Z") ? s : s + "Z"
                );
                const offsetTimeMilliseconds: number = Date.parse(
                    "1970-01-01T00:00:00Z"
                );
                startTime =
                    (startTimeMilliseconds - offsetTimeMilliseconds) / 1000;
            } else {
                throw new Error("Not a string");
            }
        } catch (e) {
            startTime = this.props.timestamp as number;
        }
        this.state = {
            age: this.getAge(startTime) /* in seconds */,
            startTime /* Unix epoch time (seconds) */,
        };
        const setAge = this.setAge.bind(this);
        const setHourlyTimer = this.setHourlyTimer.bind(this);

        if (this.state.age > expressedInHoursGreatestLowerBound) {
            this.timer = null;
            // when we get to the next half hour, set the age and call setAge every hour.
            this.timer = setTimeout(function () {
                setAge();
                setHourlyTimer();
            }, (1800 - (this.state.age % 1800)) * 1000 + 1000);
        } else {
            const frequency = 300;
            this.timer = setInterval(setAge, frequency);
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }
}
export default AgeWidget;
