// The component displays a Steem/Hive/Golos/Whaleshares profile image of a
// username and is also a relative link to that profile.
//
// <UserSummary username=**username* *optionally* image_url=*url of image profile*>
//
//
// Example:
//
// <UserSummary username='leprechaun'>Leprechaun's Profile</UserSummary>
//
//

import React from "react";
import steem from "steem";

interface UserSummaryProps {
    username: string;
    image_url?: string;
}

interface UserSummaryState {
    image_url: string;
}

class UserSummary extends React.Component<UserSummaryProps, UserSummaryState> {
    // maps the username of the user to the URL of the image
    static name_to_image: { [id: string]: string } = {};
    // Should be an array as apposed to a dictionary because you may
    // have multiple instances with the same username
    static callbacks: Array<() => void> = [];
    // number of user instances that have not been mounted
    static components_left_to_mount: number = 0;

    setImage() {
        if (UserSummary.name_to_image[this.props.username]) {
            this.setState({
                image_url: UserSummary.name_to_image[this.props.username],
            });
            delete UserSummary.name_to_image[this.props.username];
            UserSummary.callbacks = UserSummary.callbacks.filter(
                (x) => x !== this.setImage
            );
        }
    }

    constructor(props) {
        super(props);
        this.setImage = this.setImage.bind(this);

        if (this.props.image_url !== undefined) {
            this.state = {
                image_url: this.props.image_url,
            };
        } else {
            this.state = {
                image_url: "",
            };
            UserSummary.name_to_image[this.props.username] = "";
            UserSummary.callbacks.push(this.setImage);
        }

        // This constructor should get called for every instance of
        // UserSummary before componentDidMount is called for any of them.
        // (I hope)
        UserSummary.components_left_to_mount += 1;
        console.log(
            "Constructor called.",
            UserSummary.components_left_to_mount,
            " components exist."
        );
    }

    static learnAboutAccounts() {
        let names: Array<string> = [];
        for (const name in UserSummary.name_to_image) {
            names.push(name);
        }
        if (names.length > 0) {
            console.log("Will learn about", names.length, "accounts");
            steem.api.getAccounts(names, UserSummary.handleGetAccount);
        }
    }

    static handleGetAccount(error, accounts): void {
        if (error) return;
        for (const result of accounts) {
            const username = result.name;
            try {
                const user_data = JSON.parse(result.json_metadata);
                const profile = user_data.profile;
                console.log(
                    "Setting",
                    username,
                    " image to",
                    profile.profile_image
                );
                // Set the image for the next callback
                UserSummary.name_to_image[username] = profile.profile_image;
            } catch (e) {
                // cannot set image for this user.  Maybe the user never set the image
            }
        } // for
        for (const cb of UserSummary.callbacks) {
            cb();
        }
    }

    componentDidMount() {
        UserSummary.components_left_to_mount -= 1;
        if (UserSummary.components_left_to_mount === 0) {
            // last instance has mounted.  That means they also have all been created.
            UserSummary.learnAboutAccounts();
        }
    }

    render() {
        return (
            <a href={"/@" + this.props.username}>
                {this.state.image_url !== "" && (
                    <img
                        style={{ height: "20vh" }}
                        src={this.state.image_url}
                        alt=""
                    />
                )}
                {this.props.children}
                {!this.props.children && "@" + this.props.username}
            </a>
        );
    }
}

export default UserSummary;
