import React from "react";
import steem from "steem";
import {Form} from 'react-bootstrap';
import {
    titleCase,
    APIForBlockchain,
    supportedBlockchains,
    keychainForBlockchain,
} from "./blockchains";
import "./permissions.css";
import steem_logo from "./steem.png";
import hive_logo from "./hive.png";
import blurt_logo from "./blurt.png";
import whaleshares_logo from "./whaleshares.png";
import account from "./account";

const debug = true;

const levelColorMap = {
    owner: "gold",
    active: "silver",
    posting: "green",
    memo: "black",
    null: "black",
};

interface KeyHash {
    posting: string;
    postingPubkey: string;
    active: string;
    activePubkey: string;
    owner: string;
    ownerPubkey: string;
    memo: string;
    memoPubkey: string;
}

const keyHashEmpty = {
    posting: "",
    postingPubkey: "",
    active: "",
    activePubkey: "",
    owner: "",
    ownerPubkey: "",
    memo: "",
    memoPubkey: "",
};

interface KeyChainResponse {
    success: boolean;
    error: null | string;
    result: string | null;
    data: {
        request_id: number;
        type: any;
        username: string;
        domain: string;
        message: string;
        method: string;
        key: string;
    };
    message: string;
    publicKey?: string;
}

interface WhalevaultHandshakeResponse {
    request_id: number;
    appid: string;
    name: string;
    version: string;
}

interface MixedKeyList{
    activePubkey_K1?: string;
    postingPubkey_K1?: string;
    memoPubkey_K1?: string;
    activePubkey?: string;
    postingPubkey?: string;
    memoPubkey?: string;
};

interface PostingKeyList extends MixedKeyList {
  postingPubkey: string;
}

interface MemoKeyList extends MixedKeyList {
  memoPubkey: string;
}

interface ActiveKeyList extends MixedKeyList {
  activePubkey: string;
}


interface WhalevaultPubkeysResponse {
    result: {
        [username: string]: {} | MixedKeyList
    };
    data: {
      request_id: string;
      type: "pubkeys";
      username: string;
      "addKeys\n": undefined|null;
      appid: string;
      domain: string;
    },
    message: string;
    request_id: number;
    success: boolean;
}

interface WhaleVaultResponse {
    success: boolean;
    error: string | null;
    result: string | null;
    data: {
        request_id: number;
        type: any;
        username: string;
        message: any;
        method: string;
        reason: string;
        sigType: string;
        appid: string;
        domain: string;
        optypes: Array<any>;
        auths: Array<any>;
        key?: string;
    };
    message: string;
    publicKey?: string;
    request_id: number;
}
interface Props {
  key?: string;
  className?: string;
  blockchainName: string;
  onAliasClick: (ev)=> void;
  alias: string | null;
  accounts: Array<account>;
  space: string;
  onLogin: (providers_p: string | string[], user: string, role: string, keyStorageLocation: 'sessionStorage' | 'keychain' | 'whalevault') => void;
}
/// HTMLInputElement | null
function notNull<T>(x : T | null) : x is T {
  return x !== null;
}

let loginTimeoutHandle;

class SteemUserButton extends React.Component<
    Props,
    {
        showLoginDialog: boolean;
        error: string;
        loggingIn: boolean;
        selectedBlockchain: string;
        enteredUsername: string;

         // null is not yet determined
        hasSteemKeychain: boolean | null;
        hasHiveKeychain: boolean | null;
        hasWhaleVault: boolean | null;

        keyStorageLocation: 'sessionStorage' | 'keychain' | 'whalevault';

        detectExtensionsTimerid: any,
        detectionExpiryTime: number,
    }
> {

    detectExtensions() {
      const {detectionExpiryTime, selectedBlockchain} =  this.state;
      if ((new Date()).getTime() > detectionExpiryTime) {
        const {detectExtensionsTimerid, hasSteemKeychain, hasHiveKeychain,
        hasWhaleVault} = this.state;
        clearInterval(detectExtensionsTimerid);
        console.log({detectExtensionsTimerid: null,
        hasSteemKeychain: hasSteemKeychain == true,
        hasHiveKeychain: hasHiveKeychain == true ,
        hasWhaleVault: hasWhaleVault == true
        });
        this.setState({detectExtensionsTimerid: null,
        hasSteemKeychain: hasSteemKeychain == true,
        hasHiveKeychain: hasHiveKeychain == true ,
        hasWhaleVault: hasWhaleVault == true,
        keyStorageLocation: (hasSteemKeychain && selectedBlockchain === 'steem') ? 'keychain' :
        (hasHiveKeychain && selectedBlockchain === 'hive') ? 'keychain' :
        hasWhaleVault ? 'whalevault' :
        'sessionStorage'
        });
        return;
      }
      let hasSteemKeychain: boolean | null = this.state.hasSteemKeychain;
      let hasHiveKeychain: boolean | null = this.state.hasHiveKeychain;
      let hasWhaleVault: boolean | null = this.state.hasWhaleVault;
      if (window['hive_keychain']) {
        hasHiveKeychain = true;
      }
      if (window['steem_keychain']) {
        hasSteemKeychain = true;
      }
      const whaleVault : {requestHandshake: (x,r)=>void}|undefined = window['whale_vault'] || window['whalevault'];
      if (whaleVault) {
        hasWhaleVault = true;
      }

      if (hasWhaleVault && hasSteemKeychain && hasHiveKeychain) {
          const {detectExtensionsTimerid, hasSteemKeychain, hasHiveKeychain,
          hasWhaleVault} = this.state;
          clearInterval(detectExtensionsTimerid);
          this.setState({detectExtensionsTimerid: null,
            hasSteemKeychain, hasHiveKeychain,  hasWhaleVault
            });
          return;
      }

      //console.log({hasSteemKeychain, hasHiveKeychain, hasWhaleVault});
      this.setState({hasSteemKeychain, hasHiveKeychain, hasWhaleVault
          });
    }

    constructor(props) {
        super(props);
        this.toggleShow = this.toggleShow.bind(this);
        this.notLoggedInto = this.notLoggedInto.bind(this);
        this.blockchainSelected = this.blockchainSelected.bind(this);
        const notLoggedIntoYet =
            supportedBlockchains()
                .map((x) => x.name)
                .find(this.notLoggedInto) || "";
        this.availableBlockchains = supportedBlockchains()
            .map((x) => x.name)
            .filter(this.notLoggedInto);
        let showPasswordField : boolean = true;
        let hasSteemKeychain: boolean | null = null;
        let hasHiveKeychain: boolean | null = null;
        let hasWhaleVault: boolean | null = null;
        if (window['hive_keychain'] && (hasHiveKeychain = true) && notLoggedIntoYet === 'hive') {
          showPasswordField = false;
        }
        if (window['steem_keychain'] && (hasSteemKeychain = true) && notLoggedIntoYet === 'steem') {
          showPasswordField = false;
        }
        const whaleVault : {requestHandshake: (x,r)=>void}|undefined = window['whale_vault'] || window['whalevault'];
        if (whaleVault) {
          hasWhaleVault = true;
          showPasswordField = false;
        }

        const detectionExpiryTime : number = (new Date()).getTime() + 30*1000;

        this.state = {
            showLoginDialog: false,
            error: "",
            loggingIn: false,
            selectedBlockchain: notLoggedIntoYet,
            keyStorageLocation: (notLoggedIntoYet === 'hive' && hasHiveKeychain && 'keychain') ||
              (notLoggedIntoYet === 'steem' && hasHiveKeychain && 'keychain') ||
              (hasWhaleVault && 'whalevault') || 'sessionStorage',
            enteredUsername: 'leprechaun',
            hasHiveKeychain,
            hasSteemKeychain,
            hasWhaleVault,
            detectExtensionsTimerid: setInterval(this.detectExtensions.bind(this), 1500),
            detectionExpiryTime
        };


    }

    componentWillUnmount() {
      const {detectExtensionsTimerid} = this.state;
      if (detectExtensionsTimerid != null)
        clearInterval(detectExtensionsTimerid);
    }

    /* constant through life of this control instance */
    private availableBlockchains: Array<string>;

    expire() {
        this.setState({ error: "Timeout logging in", loggingIn: false });
    }

    loggedInto(blockchain) {
        return !this.notLoggedInto.bind(this)(blockchain);
    }

    notLoggedInto(blockchain) {
        return (
            this.props.accounts.find((x) => x.provider === blockchain) ===
            undefined
        );
    }

    toggleShow() {
        //alert('toggling');
        this.setState({ showLoginDialog: !this.state.showLoginDialog });
    }

    blockHeaderHandler(blockchain, key_table, tx, err, block) {
        //console.log('block:',block);
        //console.log('headblockid', headBlockId);

        if (this === undefined) {
            clearInterval(loginTimeoutHandle);
            alert(
                JSON.stringify({ error: "internal error", loggingIn: false })
            );
            return;
        }
        if (err) {
            clearInterval(loginTimeoutHandle);
            this.setState({ error: JSON.stringify(err), loggingIn: false });
            return;
        }
        try {
            const headBlockId = block.head_block_id || block.previous;

            tx["ref_block_prefix"] = new Buffer(
                headBlockId,
                "hex"
            ).readUInt32LE(4);

            const opdata = tx.operations[0][1];
            const user = opdata.required_posting_auths[0];
            const json_str = opdata.json;
            const json_obj = JSON.parse(json_str);
            const role = json_obj["role"];
            const stx = APIForBlockchain(blockchain).auth.signTransaction(tx, {
                [role]: key_table,
            });
            console.log("setting new steem-login-transaction");
            sessionStorage.setItem(
                blockchain + "-login-transaction",
                JSON.stringify(stx)
            );
        } catch (e) {
            console.log("signing error with transaction:", tx);
            console.log("error:", e);
            this.setState({ error: e.message, loggingIn: false });
        }
    } // blockHeaderHandler

    globalPropertiesHandler(blockchain, key_table, opdata, err, properties) {
        // Every key is treated as a posting key.  Don't worry it all works well this way.
        // The role parameter is just to help the server find the public key in the blockchain database
        // when it validates the transaction.
        if (err) {
            clearInterval(loginTimeoutHandle);
            this.setState({ error: JSON.stringify(err), loggingIn: false });
            return;
        }
        console.log("blockchain properties:", properties);
        const chainDate = new Date(properties.time + "Z");
        const refBlockNum =
            (properties.last_irreversible_block_num - 1) & 0xffff;

        let tx = {
            ref_block_num: refBlockNum,
            ref_block_prefix: "",
            expiration: new Date(chainDate.getTime() + 600 * 1000),
            operations: [["custom_json", opdata]],
            extensions: [],
        };
        APIForBlockchain(blockchain).api.getBlockHeader(
            properties.last_irreversible_block_num,
            this.blockHeaderHandler.bind(this, blockchain, key_table, tx)
        );
    } // globalPropertiesHandler

    updateSteemLoginTransaction(
        blockchain,
        user,
        role,
        key_table /* any memo, posting, active or owner key for user */,
        steem_public_key
    ) {
        const opdata = {
            id: "website_login",
            required_auths: [],
            required_posting_auths: [user],
            json: JSON.stringify({
                site_name: "steemfiles",
                role: role,
                public_key: steem_public_key,
            }),
        };
        if (key_table)
            APIForBlockchain(blockchain).api.getDynamicGlobalProperties(
                this.globalPropertiesHandler.bind(
                    this,
                    blockchain,
                    key_table,
                    opdata
                )
            );
        else {
        }
    }

    handleWhalevaultPubkeysResponse(response: WhalevaultPubkeysResponse) : Promise<void> {
      const {result, data, message, request_id, success} = response;
      try {
        if (debug) {
          console.log(this.props);
          console.log(response);
        }
        if (!success) {
          // Not good.
          throw { message };
        }
        for (const username in result) {
          const keyList = result[username] as MixedKeyList;
          const public_key = keyList.activePubkey || keyList.postingPubkey || keyList.memoPubkey;
          if (!public_key) {
            console.log(keyList);
            throw {'message': 'No keys registered for this user on blockchain' };
          }
          const role = keyList.activePubkey
                  ? "active"
                  : keyList.postingPubkey
                  ? "posting"
                  : keyList.memoPubkey
                  ? "memo"
                  : "";
          const [blockchain_username_prefix, bare_username] = username.split(/:/);
          if (blockchain_username_prefix === undefined) {
            throw { message: 'invalid whalevault response' };
          }
          console.log("Calling onLogin here");
          // !@ts-ignore
          const blockchainInformation = supportedBlockchains().find( bc => bc.username_prefix === blockchain_username_prefix );
          if (!blockchainInformation) {
            throw {message: 'invalid whalevault response'};
          }
          const blockchain = blockchainInformation.name;
          this.props.onLogin(blockchain, bare_username, role, 'whalevault');
        }
        this.setState({error: '', loggingIn: false,
            showLoginDialog: false});
      } catch (e) {
        console.log(e);
        throw e;
      }
      const p = new Promise<void>( (resolve, reject)=> { resolve(); });
      return p;
    }


    async validateSteemUserCB(
        blockchain,
        user,
        key: KeyHash | null,
        err,
        result: any | undefined
    ) {
        if (err) {
            clearInterval(loginTimeoutHandle);
            try {
                alert(err.message);
                this.setState({ error: err.message, loggingIn: false });
            } catch (e) {
                this.setState({ error: JSON.stringify(err), loggingIn: false });
            }
            return;
        }
        if (!result) {
            clearInterval(loginTimeoutHandle);
            this.setState({
                error:
                    "Error validatin Steem User: no error but result is undefined",
                loggingIn: false
            });
            return;
        }

        if (!this.state.loggingIn) {
            // come back later...
            setTimeout(
                this.validateSteemUserCB.bind(this),
                50,
                blockchain,
                user,
                key,
                err,
                result
            );
            return;
        }


        let info = (result as Array<any>)[0];
        let key_color: string | null = null;
        let steem_public_key: string | null = null;
        let role: string = "";
        if (key) {
            console.log("Logged in with key for blockchain:", blockchain);
            if (err) {
                clearInterval(loginTimeoutHandle);
                try {
                    this.setState({ error: err.message, loggingIn: false });
                } catch (e) {
                    if (debug) throw e;
                    this.setState({ error: "unknown error", loggingIn: false });
                }
                return;
            }

            // Right now a blank password invokes Hive Keychain but things are not all implemented
            if (key.memo === "") {
                this.setState({
                    error: "WIF or password cannot be left empty",
                    loggingIn: false,
                });
                return;
            }

            // sometimes PubKey members will have valid pubkeys otherwise ''

            for (const level of ["owner", "active", "posting"]) {
                for (const auth of info[level]["key_auths"]) {
                    // auth is {steem_public_key, weight]

                    steem_public_key = auth[0];
                    const weight = auth[1];
                    if (
                        key[level + "Pubkey"] === ""
                            ? // blank PubKey member.  We must call this
                              APIForBlockchain(blockchain).auth.wifIsValid(
                                  key[level],
                                  steem_public_key
                              )
                            : // set PubKey member just compare.
                              key[level + "Pubkey"] === steem_public_key
                    ) {
                        key_color = levelColorMap[(role = level)];
                        break;
                    }
                } // for

                if (role !== "") break;
            } // for

            if (
                role === "" &&
                (key["memoPubkey"] !== ""
                    ? key["memoPubkey"] === info.memo_key
                    : APIForBlockchain(blockchain).auth.wifIsValid(
                          key.memo,
                          info.memo_key
                      ))
            ) {
                key_color = "black";
                steem_public_key = info.memo_key;
                role = "memo";
            }

            if (role === "") {
                clearInterval(loginTimeoutHandle);
                if (key.memoPubkey === "")
                    this.setState({
                        error:
                            "The private key you supplied doesn't match the public key for this user",
                        loggingIn: false,
                    });
                else
                    this.setState({
                        error: "Incorrect master password",
                        loggingIn: false,
                    });
            }
        } else if (result !== undefined) {
          /*
            const wr = result as WhalevaultPubkeysResponse;
            const {success, data, message} = wr;
            const userToKeyList = wr.result;
            if (debug) {
                alert("wr.data is " + JSON.stringify(wr.data));
                alert(
                    "wr.result[username] is " +
                        JSON.stringify(wr.result[wr.data.username as string])
                );
            }

            const un: string = data.username as string;
            steem_public_key =
                userToKeyList[un].activePubkey ||
                userToKeyList[un].postingPubkey ||
                userToKeyList[un].memoPubkey;
            role = userToKeyList[un].activePubkey
                ? "active"
                : userToKeyList[un].postingPubkey
                ? "posting"
                : userToKeyList[un].memoPubkey
                ? "memo"
                : "";
            let user : string = '';
            const splitname = un.split(/:/);
            if (splitname.length > 1) {
                user = splitname[1];
            } else {
                user = un;
            }
            if (success) {
                console.log("Calling onLogin here");
                const notLoggedIntoYet =
                    supportedBlockchains()
                        .map((x) => x.name)
                        .find((x) => (x != blockchain)) || "";
                if (notLoggedIntoYet === '') {
                  this.setState({error: '', loggingIn: false,
                  showLoginDialog: false});

                } else {
                  this.setState({error: '', loggingIn: false,
                      selectedBlockchain: notLoggedIntoYet,
                      showLoginDialog: false});
                }
                this.props.onLogin(blockchain, user, role);
            } else {
                this.setState({ error: wr.message, loggingIn: false, });
            }
            */return;
        }

        if (role === "" && key !== null) {
            this.setState({
                error: `Invalid WIF or master password. (${key.posting})`,
                loggingIn: false,
            });
            return;
        }
        const onLogin = this.props.onLogin;

        if (key && role && key[role]) {
            let blockchain_vector: Array<string> = [];
            sessionStorage.setItem(blockchain + "-private-key", key[role]);
            // update immediately and only once
            try {
                this.updateSteemLoginTransaction.bind(this)(
                    blockchain,
                    user,
                    role,
                    key[role],
                    steem_public_key
                );

                // here notify once to the component that this user has logged in.
                // The private key stays in this component.
                const x = this.props.accounts.find(
                    (x) => x.provider === blockchain
                );
                clearInterval(loginTimeoutHandle);
                if (!x) {
                    for (const other_blockchain of supportedBlockchains()) {
                        if (
                            true ||
                            this.props.accounts.find(
                                (a) => a.provider !== other_blockchain.name
                            )
                        ) {
                            const keytail = (steem_public_key as string).slice(
                                3
                            );
                            let result: Array<{
                                active: any;
                                posting: any;
                                owner: any;
                            }> = await APIForBlockchain(
                                other_blockchain.name
                            ).api.lookupAccountNamesAsync([user]);
                            const info = result[0];
                            for (const auth of info[role].key_auths) {
                                const pubKey = auth[0];
                                const weight = auth[1];
                                if (pubKey.slice(3) === keytail) {
                                    console.log("Keys match!");
                                    // add these keys as well to session storage
                                    blockchain_vector.push(
                                        other_blockchain.name
                                    );
                                    // update key information.
                                    sessionStorage.setItem(
                                        other_blockchain.name + "-id",
                                        user
                                    );
                                    sessionStorage.setItem(
                                        other_blockchain.name + "-public-key",
                                        pubKey
                                    );
                                    sessionStorage.setItem(
                                        other_blockchain.name + "-role",
                                        role
                                    );
                                    sessionStorage.setItem(
                                        other_blockchain.name + "-private-key",
                                        key[role]
                                    );
                                }
                            } // for
                        } // if
                    } // for
                    console.log("Calling onLogin here");
                    this.props.onLogin(blockchain_vector, user, role, 'sessionStorage');
                } else {
                    this.setState({
                        error: "already logged in via " + blockchain,
                        loggingIn: false,
                    });
                }
            } catch (e) {
                this.setState({
                    error: "internal exception: " + e.message,
                    loggingIn: false,
                });
            }
        } else {
            sessionStorage.removeItem(blockchain + "-private-key");
        }
        // all has gone well.  This control will get reinitialized so don't setState at all!
    }

    doCancel() {
        //alert('cancelling');
        this.setState({ error: "", showLoginDialog: false });
    }

    handleSignedMessage(blockchain: string, x: KeyChainResponse) {
        if (debug) console.log(JSON.stringify(x));
        console.log({x});
        clearInterval(loginTimeoutHandle);
        if (x.success) {
            // update key information.
            sessionStorage.setItem(blockchain + "-id", x.data.username);
            if (x.publicKey) {
              sessionStorage.setItem(blockchain + "-public-key", x.publicKey);
            }
            sessionStorage.setItem(blockchain + "-role", x.data.key);
            console.log({result: x.result, message: x.message});
            this.props.onLogin(blockchain, x.data.username, "active", 'keychain');
        } else {
            this.setState({ error: x.message, loggingIn: false });
        }
    }

    doLogin() {
        try {
            const {hasHiveKeychain, hasSteemKeychain} = this.state;
            const {enteredUsername, selectedBlockchain} = this.state;
            const {keyStorageLocation} = this.state;
            let user = enteredUsername;
            let blockchain = selectedBlockchain;
            let password_el = document.getElementById(
                "key"
            ) as HTMLInputElement | null;
            let usernameError = APIForBlockchain(
                selectedBlockchain
            ).utils.validateAccountName(enteredUsername);
            if (usernameError) {
                this.setState({ error: usernameError, loggingIn: false });
                return;
            }

            if (keyStorageLocation === 'whalevault') {
                // Handle Extension login
                let whalevault;
                console.log('here');

                //  tslint:disable-next-line  no-restricted-globals
                if (whalevault = (window["whalevault"] || window["whale_vault"])) {

                    let prefix = blockchain + ":";
                    if (blockchain === "whaleshares")
                      prefix = "wls:";
                    else if (blockchain === "blurt")
                      prefix = "blt:";
                    else if (blockchain === 'steem')
                      prefix = 'stm:';
                    else if (blockchain === "hive")
                      prefix = 'hiv:';
                    // are the prefixes for steem and hive the same steem and hive?
                    this.setState({
                        error: "Not fully implemented.  Use WIF.",
                        loggingIn: true,
                    });
                    whalevault.promiseRequestPubKeys(
                        "steemfiles.com",
                        prefix + user
                    )
                    .then(this.handleWhalevaultPubkeysResponse.bind( this ))
                    .catch((e) => {
                        const {message} = e;
                        if ( message === 'No keys registered for this user on blockchain') {
                          this.setState({ loggingIn: false, error: 'Whalevault extension loaded but account keys have not been loaded into the extension' });
                        } else {
                          this.setState({ loggingIn: false, error: message });
                        }
                    });
                } else {
                  throw { message: 'Whalevault selected but extension not yet loaded' };
                }
            } else if (keyStorageLocation === 'keychain') {
                if (
                    (blockchain === "steem" && window["steem_keychain"]) ||
                    (blockchain === "hive" && window["hive_keychain"])
                ) {
                    keychainForBlockchain(blockchain).requestSignBuffer(
                        user,
                        new Date() + "",
                        "Posting",
                        this.handleSignedMessage.bind(this, blockchain)
                    );
                } else {
                  throw { 'message': 'Keychain not available for this selected blockchain'};
                }
            } else if (keyStorageLocation === 'sessionStorage' && notNull<HTMLInputElement>(password_el)) {
              let private_key = password_el.value;

              loginTimeoutHandle = setTimeout(this.expire.bind(this), 2400);

              let private_key_hash: KeyHash = {
                  posting: private_key,
                  postingPubkey: "",
                  active: private_key,
                  activePubkey: "",
                  owner: private_key,
                  ownerPubkey: "",
                  memo: private_key,
                  memoPubkey: "",
              };
              let isValidWif = APIForBlockchain(blockchain).auth.isWif(
                  private_key
              );

              if (!isValidWif) {
                  // Could it be the master password?
                  const derived_keys = APIForBlockchain(
                      blockchain
                  ).auth.getPrivateKeys(user, private_key, [
                      "memo",
                      "posting",
                      "active",
                      "owner",
                  ]);

                  private_key_hash = derived_keys;
              }
              this.setState({ loggingIn: true, selectedBlockchain: blockchain });
              APIForBlockchain(blockchain).api.lookupAccountNames(
                  [user],
                  this.validateSteemUserCB.bind(
                      this,
                      blockchain,
                      user,
                      private_key_hash
                  )
              );
            }
        } catch (e) {
            alert("exception:" + e.message);
            this.setState({
                error:
                    "blockchain: " +
                    JSON.stringify(this.state.selectedBlockchain) +
                    ", " +
                    e.message,
                loggingIn: false,
            });
        }

        //APIForBlockchain(this.state.blockchain_form).api.getDynamicGlobalProperties(
        // 	  this.globalPropertiesHandler.bind(this, 'memo', user));
    }

    // If the string passed has no @, or the part after teh @ is not a blockchain name, then return the current selected blockchain
    // If the string passed has an @ the later part is returned should it be a possible blockchain name
    getProviderFromComposite(composite) {
        let provider: string;
        let temp: Array<string> = composite.split("@");
        if (
            temp.length > 1 &&
            this.availableBlockchains.includes((provider = temp[1])) &&
            this.state.selectedBlockchain !== provider
        ) {
            return provider;
        } else {
            return this.state.selectedBlockchain;
        }
    }

    usernameChanged(e) {
        const new_username = e.target.value.split(/@/)[0];
        const new_provider = this.getProviderFromComposite.bind(this)(
            new_username
        );
        if (new_username !== this.state.enteredUsername || new_provider !== this.state.selectedBlockchain) {
            this.setState({ enteredUsername: new_username, selectedBlockchain: new_provider });
        }
    }

    blockchainSelected(e) {
        // Rather than putting username into react's state, using this low level interface
        // makes typing smooth.
        let username_el = document.getElementById(
            "username"
        ) as HTMLInputElement;
        let blockchainFromSelectInput = e.target.value;
        let username = username_el.value;
        let temp = username.split("@");
        let bareUsername = temp[0];
        let blockchainFromTextInput = this.getProviderFromComposite.bind(this)(
            username
        );
        if (blockchainFromTextInput !== blockchainFromSelectInput) {
            username_el.value = bareUsername + "@" + blockchainFromSelectInput;
        }
        const {hasSteemKeychain, hasHiveKeychain, hasWhaleVault} = this.state;
        this.setState({ selectedBlockchain: blockchainFromSelectInput,
            keyStorageLocation:
            ((hasSteemKeychain && blockchainFromSelectInput === 'steem')
              || (hasHiveKeychain && blockchainFromSelectInput === 'hive')) ?
                    'keychain'
                        :
                  hasWhaleVault ?
                     'whalevault'
                         :
                      'sessionStorage'

        });


    }

    componentDidMount() {
        let whalevault;
        //  tslint:disable-next-line  no-restricted-globals
        if (!!(whalevault = window["whalevault"])) {
            whalevault.requestHandshake("steemfiles.com", function (r) {});
        }
    }

    setLoginMethod(m : 'sessionStorage' | 'keychain' | 'whalevault') {
      this.setState({keyStorageLocation: m});
    }

    render() {
      const {hasSteemKeychain, hasHiveKeychain, hasWhaleVault, loggingIn} = this.state;
        if (this.state.loggingIn) {
            return <span>Logging in...</span>;
        } else if (this.state.showLoginDialog) {
          const {keyStorageLocation, selectedBlockchain, enteredUsername} = this.state;
            return (

              <dialog open>
                <div>
                        <label>Username</label>
                        <input
                            id="username"
                            defaultValue={enteredUsername!='' ? enteredUsername+'@'+selectedBlockchain : ''}
                            className="UsernameInput"
                            onChange={this.usernameChanged.bind(this)}
                            onClick={this.usernameChanged.bind(this)}
                            type="text"
                        ></input>
                </div>

                {enteredUsername !== '' && <div>
             <div>
                        <label>blockchain</label>
                        {this.availableBlockchains.length > 1 ? (
                            <select
                                key={this.state.selectedBlockchain}
                                id="blockchain"
                                onChange={this.blockchainSelected}
                                defaultValue={this.state.selectedBlockchain}
                            >
                                {this.availableBlockchains.map((blockchain) => (
                                    <option key={blockchain} value={blockchain}>
                                        {titleCase(blockchain)}
                                    </option>
                                ))}
                            </select>
                        ) : this.availableBlockchains.length === 1 ? (
                            " " + this.availableBlockchains[0]
                        ) : (
                            ""
                        )}
                </div>


               <div style={{'display': 'flex'}}>
                {((selectedBlockchain === 'hive' && hasHiveKeychain !== false) ||
                  (selectedBlockchain === 'steem' && hasSteemKeychain !== false)) &&
                  <Form.Check type="radio" name="loginMethod"
                  value="keychain"
                  onClick={this.setLoginMethod.bind(this, 'keychain')}
                  defaultChecked={keyStorageLocation === 'keychain'}
                  label={"KeyChain Extension " + (((selectedBlockchain === 'hive' && hasHiveKeychain === null) ||
                  (selectedBlockchain === 'steem' && hasSteemKeychain === null)) ? ' (detecting...)' : '')}
                  />
                }

                {(hasWhaleVault !== false) && <Form.Check type="radio" name="loginMethod"
                  value="whalevault"
                  onClick={this.setLoginMethod.bind(this, 'whalevault')}
                  defaultChecked={keyStorageLocation === 'whalevault'}
                  label={`Whalevault Extension ` + (hasWhaleVault === null ? '(detecting...)' : '')}
                />}



                <Form.Check  type="radio" name="loginMethod"
                  value="password"
                  defaultChecked={keyStorageLocation === 'sessionStorage'}
                  onClick={this.setLoginMethod.bind(this, 'sessionStorage')}
                  label='Password or key'

                />
              </div>

              {keyStorageLocation === 'sessionStorage' ?  <div>
              <label>Posting/Social Key</label>
              <input
                  id="key"
                  className="KeyInput"
                  type="password"
              ></input>
              </div> : <div />
              }


                <div>

                        <button onClick={this.doCancel.bind(this)}>
                            Cancel
                        </button>
                        <button onClick={this.doLogin.bind(this)}>
                             Login
                        </button>
                </div>

                    {this.props.children}


                    <span className="required">{this.state.error}</span>
                  </div>
                }</dialog>


            );
        } else {
            return this.props.accounts.length < 4 ? (
                <button onClick={this.toggleShow.bind(this)}>
                    Login
                    {this.availableBlockchains.includes("steem") && (
                        <img
                            className="charlogo"
                            alt="steem"
                            src={steem_logo}
                        />
                    )}
                    {this.availableBlockchains.includes("hive") && (
                        <img className="charlogo" alt="hive" src={hive_logo} />
                    )}
                    {this.availableBlockchains.includes("blurt") && (
                        <img
                            className="charlogo"
                            alt="blurt"
                            src={blurt_logo}
                        />
                    )}
                    {this.availableBlockchains.includes("whaleshares") && (
                        <img
                            className="charlogo"
                            alt="whaleshares"
                            src={whaleshares_logo}
                        />
                    )}
                </button>
            ) : (
                <div>{this.props.children}</div>
            );
        }
    } // render
} // class

export default SteemUserButton;
