import React from "react";
import "./App.css";
import "./RedBubbleAd.css";

interface ProductType {
    image: string;
    location: string;
}
interface RedBubbleAdState {
    products: Array<ProductType>;
}

export class RedBubbleAd extends React.Component<any, RedBubbleAdState> {
    constructor(props) {
        super(props);

        let all_products: Array<ProductType> = [];
        let selected_products: Set<ProductType> = new Set();
        let image_ids: Array<string> = [];
        const image_max = 7;
        let image_count: number = 0;

        all_products = [
            {
                location:
                    "https://www.redbubble.com/i/coasters/Not-all-bitches-are-dogs-by-expatriot/50188895.E5I3N",
                image:
                    "https://ih1.redbubble.net/image.1302343847.8895/ur,coaster_pack_4_flatlay,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Not-all-bitches-are-dogs-by-expatriot/50188895.EJUG5",
                image:
                    "https://ih1.redbubble.net/image.1302344143.8895/st,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Not-all-bitches-are-dogs-by-expatriot/50188895.W3OIY",
                image:
                    "https://ih1.redbubble.net/image.1302343925.8895/mug,tall,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.NL9AC",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,slim_fit_t_shirt,two_model,fafafa:ca443f4786,front,square_three_quarter,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-pillow/Not-all-bitches-are-dogs-by-expatriot/50188895.5X2YF",
                image:
                    "https://ih1.redbubble.net/image.1302344004.8895/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/poster/Not-all-bitches-are-dogs-by-expatriot/50188895.LVTDI",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/poster,840x830,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.PFN84",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,fitted_scoop,x2000,fafafa:ca443f4786,front-c,160,143,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Not-all-bitches-are-dogs-by-expatriot/50188895.5Y5V7",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/mp,840x830,matte,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.UGYPM",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,active_tshirt,mens,101010:01c5ca27c6,front,square_three_quarter,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.TR8D9",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,raglan,x1950,white_black,front-c,160,90,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.WFLAH",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,classic_tee,two_models,fafafa:ca443f4786,front,square_three_quarter,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.1YYVU",
                image:
                    "https://ih1.redbubble.net/image.1302343962.8895/gptr,1400x,front,black-c,188,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Not-all-bitches-are-dogs-by-expatriot/50188895.GQV8B",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/Not-all-bitches-are-dogs-by-expatriot/50188895.NUC7A",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,lightweight_sweatshirt,mens,heather_grey_lightweight_raglan_sweatshirt,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/long-sleeve-t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.6I7YY",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,longsleeve,x2000,fafafa:ca443f4786,front-c,190,60,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.RY32L",
                image:
                    "https://ih1.redbubble.net/image.1302344105.8895/ssrco,long_t_shirt,mens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.D681C",
                image:
                    "https://ih1.redbubble.net/image.1302344105.8895/ssrco,mens_premium_t_shirt,mens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Not-all-bitches-are-dogs-by-expatriot/50188895.O16E3",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,mhoodie,mens,oatmeal_heather,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/Not-all-bitches-are-dogs-by-expatriot/50188895.73735",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,sweatshirt,x1850,oatmeal_heather,front-c,105,45,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/Not-all-bitches-are-dogs-by-expatriot/50188895.N283C",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,racerback,womens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/Not-all-bitches-are-dogs-by-expatriot/50188895.6AQD3",
                image:
                    "https://ih1.redbubble.net/image.1302343911.8895/ctkr,x2200,front,black-c,250,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/Not-all-bitches-are-dogs-by-expatriot/50188895.E1H7L",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,tank,x1850,fafafa:ca443f4786,front-c,95,35,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.R05UB",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,triblend_tee,x2150,red_triblend,front-c,242,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.IZGJF",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,vneck,x1900,fafafa:ca443f4786,front-c,160,70,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Not-all-bitches-are-dogs-by-expatriot/50188895.R76QN",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ssrco,mhoodiez,mens,oatmeal_heather,front,square_three_quarter,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/Not-all-bitches-are-dogs-by-expatriot/50188895.V4WQ8",
                image:
                    "https://ih1.redbubble.net/image.1302344014.8895/aldr,x1700,front-c,60,103,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/Not-all-bitches-are-dogs-by-expatriot/50188895.B7P0O",
                image:
                    "https://ih1.redbubble.net/image.1302343931.8895/ssrco,chiffon_top,womens,black,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.8PZ5B",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,womens_tshirt,x1900,fafafa:ca443f4786,front-c,140,125,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.UIV3X",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,fitted_v_neck,x1950,fafafa:ca443f4786,front-c,150,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/Not-all-bitches-are-dogs-by-expatriot/50188895.TGIUM",
                image:
                    "https://ih1.redbubble.net/image.1302344003.8895/gtdr,x1800,front,black-c,130,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.07KN2",
                image:
                    "https://ih1.redbubble.net/image.1302344105.8895/rco,womens_premium_t_shirt,womens,x1770,fafafa:ca443f4786,front-c,170,40,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Not-all-bitches-are-dogs-by-expatriot/50188895.XK1BC",
                image:
                    "https://ih1.redbubble.net/image.1302343983.8895/ra,relaxed_fit,x2000,fafafa:ca443f4786,front-c,170,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Not-all-bitches-are-dogs-by-expatriot/50188895.JCQM3",
                image:
                    "https://ih1.redbubble.net/image.1302344143.8895/st,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-skin/Not-all-bitches-are-dogs-by-expatriot/50188895.6EA4Y",
                image:
                    "https://ih1.redbubble.net/image.1302343920.8895/pd,x750,macbook_air_13-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-case/Not-all-bitches-are-dogs-by-expatriot/50188895.2U5KG",
                image:
                    "https://ih1.redbubble.net/image.1302343920.8895/ls,13inch,x1000-c,90,0,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Not-all-bitches-are-dogs-by-expatriot/50188895.O9UDB",
                image:
                    "https://ih1.redbubble.net/image.1302344143.8895/tst,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-skin/Not-all-bitches-are-dogs-by-expatriot/50188895.MHP6F",
                image:
                    "https://ih1.redbubble.net/image.1302343939.8895/mwo,x1000,ipad_2_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-case/Not-all-bitches-are-dogs-by-expatriot/50188895.MNKGF",
                image:
                    "https://ih1.redbubble.net/image.1302343939.8895/mwo,x1000,ipad_2_snap-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-skin/Not-all-bitches-are-dogs-by-expatriot/50188895.62NBT",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/mwo,x1000,iphone_6_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Not-all-bitches-are-dogs-by-expatriot/50188895.C0UE4",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/icr,iphone_12_snap,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Not-all-bitches-are-dogs-by-expatriot/50188895.PGM2E",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/icr,iphone_12_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Not-all-bitches-are-dogs-by-expatriot/50188895.23I0V",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/icr,iphone_12_tough,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Not-all-bitches-are-dogs-by-expatriot/50188895.TALA6",
                image:
                    "https://ih1.redbubble.net/image.1302343953.8895/wallet,1000x,iphone_6s_wallet-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-skin/Not-all-bitches-are-dogs-by-expatriot/50188895.NOJ2U",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/mwo,1000x,samsung_galaxy_s5_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Not-all-bitches-are-dogs-by-expatriot/50188895.Q464T",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/icr,samsung_galaxy_s20_snap,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Not-all-bitches-are-dogs-by-expatriot/50188895.B10ML",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/icr,samsung_galaxy_s20_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Not-all-bitches-are-dogs-by-expatriot/50188895.S4BK2",
                image:
                    "https://ih1.redbubble.net/image.1302343909.8895/icr,samsung_galaxy_s20_tough,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-board-print/Not-all-bitches-are-dogs-by-expatriot/50188895.8DB3C",
                image:
                    "https://ih1.redbubble.net/image.1302343989.8895/gbra,10x8,900x900.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-print/Not-all-bitches-are-dogs-by-expatriot/50188895.1G4ZT",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/aps,840x830,small,transparent-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Not-all-bitches-are-dogs-by-expatriot/50188895.56DNM",
                image:
                    "https://ih1.redbubble.net/image.1302344006.8895/ur,mounted_print_canvas_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/framed-print/Not-all-bitches-are-dogs-by-expatriot/50188895.AJ1A3",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/fp,840x830,black,off_white,box20,s,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/metal-print/Not-all-bitches-are-dogs-by-expatriot/50188895.0JXQP",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/mp,840x860,gloss,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/photographic-print/Not-all-bitches-are-dogs-by-expatriot/50188895.6Q0TX",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/pp,840x830-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/Not-all-bitches-are-dogs-by-expatriot/50188895.V7PMD",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/paperpc,450x,w,f8f8f8-pad,600x600,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tapestry/Not-all-bitches-are-dogs-by-expatriot/50188895.PCFHB",
                image:
                    "https://ih1.redbubble.net/image.1302344061.8895/tapestry,1200x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/wood-print/Not-all-bitches-are-dogs-by-expatriot/50188895.EZ4MB",
                image:
                    "https://ih1.redbubble.net/image.1302344006.8895/ur,mounted_print_wood_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/acrylic-block/Not-all-bitches-are-dogs-by-expatriot/50188895.P5BX3",
                image:
                    "https://ih1.redbubble.net/image.1302343847.8895/abf,4x4,x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/clock/Not-all-bitches-are-dogs-by-expatriot/50188895.CN2BJ",
                image:
                    "https://ih1.redbubble.net/image.1302343966.8895/clkf,bamboo,white,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/comforter/Not-all-bitches-are-dogs-by-expatriot/50188895.8IC4O",
                image:
                    "https://ih1.redbubble.net/image.1302344093.8895/ur,comforter_top_king,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/duvet-cover/Not-all-bitches-are-dogs-by-expatriot/50188895.XWIXB",
                image:
                    "https://ih1.redbubble.net/image.1302344093.8895/dc,1000x1000,twin,bed.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/floor-pillow/Not-all-bitches-are-dogs-by-expatriot/50188895.TMTL5",
                image:
                    "https://ih1.redbubble.net/image.1302344011.8895/throwpillow,36x36,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/shower-curtain/Not-all-bitches-are-dogs-by-expatriot/50188895.YH6LW",
                image:
                    "https://ih1.redbubble.net/image.1302344093.8895/ur,shower_curtain_closed,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/Not-all-bitches-are-dogs-by-expatriot/50188895.16D0B",
                image:
                    "https://ih1.redbubble.net/image.1302344061.8895/ur,blanket_medium_bed,square,x600.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/drawstring-bag/Not-all-bitches-are-dogs-by-expatriot/50188895.YT4RK",
                image:
                    "https://ih1.redbubble.net/image.1302343927.8895/drawstring_bag,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Not-all-bitches-are-dogs-by-expatriot/50188895.PJQVX",
                image:
                    "https://ih1.redbubble.net/image.1302344004.8895/tb,1400x1400,small-c,1,330,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/backpack/Not-all-bitches-are-dogs-by-expatriot/50188895.K1KHE",
                image:
                    "https://ih1.redbubble.net/image.1302343982.8895/ur,backpack_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Not-all-bitches-are-dogs-by-expatriot/50188895.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1302343925.8895/mug,standard,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Not-all-bitches-are-dogs-by-expatriot/50188895.P1QBH",
                image:
                    "https://ih1.redbubble.net/image.1302343976.8895/ssrco,tote,cotton,canvas_creme,flatlay,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pin/Not-all-bitches-are-dogs-by-expatriot/50188895.NP9QY",
                image:
                    "https://ih1.redbubble.net/image.1302343879.8895/ur,pin_large_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/scarf/Not-all-bitches-are-dogs-by-expatriot/50188895.B15PI",
                image:
                    "https://ih1.redbubble.net/image.1302344012.8895/scarf,x1050-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/socks/Not-all-bitches-are-dogs-by-expatriot/50188895.9HZ1B",
                image:
                    "https://ih1.redbubble.net/image.1302344076.8895/ur,socks_flatlay_medium,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Not-all-bitches-are-dogs-by-expatriot/50188895.V33QC",
                image:
                    "https://ih1.redbubble.net/image.1302343950.8895/mug,travel,x1000,center-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/water-bottle/Not-all-bitches-are-dogs-by-expatriot/50188895.Y6CDO",
                image:
                    "https://ih1.redbubble.net/image.1302343993.8895/ur,water_bottle_metal_lid_on,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pouch/Not-all-bitches-are-dogs-by-expatriot/50188895.440R3",
                image:
                    "https://ih1.redbubble.net/image.1302343920.8895/pr,150x100,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/greeting-card/Not-all-bitches-are-dogs-by-expatriot/50188895.5MT14",
                image:
                    "https://ih1.redbubble.net/image.1302344058.8895/papergc,500x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/Not-all-bitches-are-dogs-by-expatriot/50188895.RXH2R",
                image:
                    "https://ih1.redbubble.net/image.1302343918.8895/hj,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/Not-all-bitches-are-dogs-by-expatriot/50188895.WX3NH",
                image:
                    "https://ih1.redbubble.net/image.1302343893.8895/sn,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/magnet/Not-all-bitches-are-dogs-by-expatriot/50188895.TBCTK",
                image:
                    "https://ih1.redbubble.net/image.1435488586.3804/flat,128x128,075,t.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/I-hate-it-when-this-happens-Minecraft-by-expatriot/52948565.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1435724063.8565/mug,standard,x334,right-pad,600x600,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/fun-fact-math-purple-and-blue-by-expatriot/47632902.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1186535209.2902/mug,standard,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Each-of-us-is-a-minority-of-one-by-expatriot/49450095.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1269579691.0095/mug,standard,x334,right-pad,600x600,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizza-Print-by-expatriot/52943804.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1435488382.3804/mug,standard,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizzaola-2-by-expatriot/52076469.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1392536016.6469/mug,standard,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizzaola-1-by-expatriot/52075657.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1392495628.5657/mug,standard,x334,right-pad,600x600,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/wet-beach-0251-by-expatriot/52078163.9Q0AD",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Desert-Scene-by-expatriot/52074454.9Q0AD",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Beach-Sea-and-Mountain-by-expatriot/52077900.9Q0AD",
                image:
                    "https://ih1.redbubble.net/image.1392608256.7900/mug,standard,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Pizza-Print-by-expatriot/52943804.EJUG5",
                image:
                    "https://ih1.redbubble.net/image.1435489934.3804/st,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.NL9AC",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ssrco,slim_fit_t_shirt,two_model,fafafa:ca443f4786,front,square_three_quarter,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.1YYVU",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/Pizza-Print-by-expatriot/52943804.V4WQ8",
                image:
                    "https://ih1.redbubble.net/image.1435489070.3804/aldr,x1700,front-c,60,103,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Pizza-Print-by-expatriot/52943804.PJQVX",
                image:
                    "https://ih1.redbubble.net/image.1435488416.3804/tb,1400x1400,small-c,1,330,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.UGYPM",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.TR8D9",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.WFLAH",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Pizza-Print-by-expatriot/52943804.GQV8B",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/Pizza-Print-by-expatriot/52943804.NUC7A",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/long-sleeve-t-shirt/Pizza-Print-by-expatriot/52943804.6I7YY",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.RY32L",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.D681C",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Pizza-Print-by-expatriot/52943804.O16E3",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/Pizza-Print-by-expatriot/52943804.73735",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/Pizza-Print-by-expatriot/52943804.N283C",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ssrco,racerback,womens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/Pizza-Print-by-expatriot/52943804.6AQD3",
                image:
                    "https://ih1.redbubble.net/image.1435488438.3804/ctkr,x2200,front,black-c,250,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/Pizza-Print-by-expatriot/52943804.E1H7L",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,tank,x1850,fafafa:ca443f4786,front-c,95,35,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.R05UB",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,triblend_tee,x2150,red_triblend,front-c,242,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.IZGJF",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,vneck,x1900,fafafa:ca443f4786,front-c,160,70,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Pizza-Print-by-expatriot/52943804.R76QN",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ssrco,mhoodiez,mens,oatmeal_heather,front,square_three_quarter,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/Pizza-Print-by-expatriot/52943804.B7P0O",
                image:
                    "https://ih1.redbubble.net/image.1435488465.3804/ssrco,chiffon_top,womens,black,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.PFN84",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,fitted_scoop,x2000,fafafa:ca443f4786,front-c,160,143,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.8PZ5B",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,womens_tshirt,x1900,fafafa:ca443f4786,front-c,140,125,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.UIV3X",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,fitted_v_neck,x1950,fafafa:ca443f4786,front-c,150,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/Pizza-Print-by-expatriot/52943804.TGIUM",
                image:
                    "https://ih1.redbubble.net/image.1435488786.3804/gtdr,x1800,front,black-c,130,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.07KN2",
                image:
                    "https://ih1.redbubble.net/image.1435489996.3804/rco,womens_premium_t_shirt,womens,x1770,fafafa:ca443f4786,front-c,170,40,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizza-Print-by-expatriot/52943804.XK1BC",
                image:
                    "https://ih1.redbubble.net/image.1435488972.3804/ra,relaxed_fit,x2000,fafafa:ca443f4786,front-c,170,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Pizza-Print-by-expatriot/52943804.JCQM3",
                image:
                    "https://ih1.redbubble.net/image.1435489934.3804/st,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-skin/Pizza-Print-by-expatriot/52943804.6EA4Y",
                image:
                    "https://ih1.redbubble.net/image.1435488481.3804/pd,x750,macbook_air_13-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-case/Pizza-Print-by-expatriot/52943804.2U5KG",
                image:
                    "https://ih1.redbubble.net/image.1435488481.3804/ls,13inch,x1000-c,90,0,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Pizza-Print-by-expatriot/52943804.O9UDB",
                image:
                    "https://ih1.redbubble.net/image.1435489934.3804/tst,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-skin/Pizza-Print-by-expatriot/52943804.MHP6F",
                image:
                    "https://ih1.redbubble.net/image.1435488320.3804/mwo,x1000,ipad_2_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-case/Pizza-Print-by-expatriot/52943804.MNKGF",
                image:
                    "https://ih1.redbubble.net/image.1435488320.3804/mwo,x1000,ipad_2_snap-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-skin/Pizza-Print-by-expatriot/52943804.62NBT",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/mwo,x1000,iphone_6_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizza-Print-by-expatriot/52943804.C0UE4",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/icr,iphone_12_snap,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizza-Print-by-expatriot/52943804.PGM2E",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/icr,iphone_12_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizza-Print-by-expatriot/52943804.23I0V",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/icr,iphone_12_tough,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizza-Print-by-expatriot/52943804.TALA6",
                image:
                    "https://ih1.redbubble.net/image.1435488357.3804/wallet,1000x,iphone_6s_wallet-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-skin/Pizza-Print-by-expatriot/52943804.NOJ2U",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/mwo,1000x,samsung_galaxy_s5_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Pizza-Print-by-expatriot/52943804.Q464T",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/icr,samsung_galaxy_s20_snap,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Pizza-Print-by-expatriot/52943804.B10ML",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/icr,samsung_galaxy_s20_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Pizza-Print-by-expatriot/52943804.S4BK2",
                image:
                    "https://ih1.redbubble.net/image.1435488503.3804/icr,samsung_galaxy_s20_tough,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-board-print/Pizza-Print-by-expatriot/52943804.8DB3C",
                image:
                    "https://ih1.redbubble.net/image.1435488544.3804/gbra,10x8,900x900.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-print/Pizza-Print-by-expatriot/52943804.1G4ZT",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/aps,840x830,small,transparent-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Pizza-Print-by-expatriot/52943804.56DNM",
                image:
                    "https://ih1.redbubble.net/image.1435488571.3804/ur,mounted_print_canvas_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Pizza-Print-by-expatriot/52943804.5Y5V7",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/mp,840x830,matte,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/framed-print/Pizza-Print-by-expatriot/52943804.AJ1A3",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/fp,840x830,black,off_white,box20,s,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/metal-print/Pizza-Print-by-expatriot/52943804.0JXQP",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/mp,840x860,gloss,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/photographic-print/Pizza-Print-by-expatriot/52943804.6Q0TX",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/pp,840x830-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/Pizza-Print-by-expatriot/52943804.V7PMD",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/paperpc,750x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/poster/Pizza-Print-by-expatriot/52943804.LVTDI",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/poster,840x830,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tapestry/Pizza-Print-by-expatriot/52943804.PCFHB",
                image:
                    "https://ih1.redbubble.net/image.1435488745.3804/tapestry,1200x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/wood-print/Pizza-Print-by-expatriot/52943804.EZ4MB",
                image:
                    "https://ih1.redbubble.net/image.1435488571.3804/ur,mounted_print_wood_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/acrylic-block/Pizza-Print-by-expatriot/52943804.P5BX3",
                image:
                    "https://ih1.redbubble.net/image.1435488421.3804/abf,4x4,x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/clock/Pizza-Print-by-expatriot/52943804.1X49C",
                image:
                    "https://ih1.redbubble.net/image.1435488486.3804/clkf,bamboo,black,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/coasters/Pizza-Print-by-expatriot/52943804.E5I3N",
                image:
                    "https://ih1.redbubble.net/image.1435488421.3804/ur,coaster_pack_4_flatlay,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/comforter/Pizza-Print-by-expatriot/52943804.8IC4O",
                image:
                    "https://ih1.redbubble.net/image.1435488731.3804/ur,comforter_top_king,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/duvet-cover/Pizza-Print-by-expatriot/52943804.XWIXB",
                image:
                    "https://ih1.redbubble.net/image.1435488731.3804/dc,1000x1000,twin,bed.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/floor-pillow/Pizza-Print-by-expatriot/52943804.TMTL5",
                image:
                    "https://ih1.redbubble.net/image.1435488568.3804/throwpillow,36x36,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/shower-curtain/Pizza-Print-by-expatriot/52943804.YH6LW",
                image:
                    "https://ih1.redbubble.net/image.1435488731.3804/ur,shower_curtain_closed,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/Pizza-Print-by-expatriot/52943804.16D0B",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-pillow/Pizza-Print-by-expatriot/52943804.5X2YF",
                image:
                    "https://ih1.redbubble.net/image.1435488416.3804/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/drawstring-bag/Pizza-Print-by-expatriot/52943804.YT4RK",
                image:
                    "https://ih1.redbubble.net/image.1435488447.3804/drawstring_bag,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/backpack/Pizza-Print-by-expatriot/52943804.K1KHE",
                image:
                    "https://ih1.redbubble.net/image.1435488516.3804/ur,backpack_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Pizza-Print-by-expatriot/52943804.P1QBH",
                image:
                    "https://ih1.redbubble.net/image.1435488859.3804/ssrco,tote,cotton,canvas_creme,flatlay,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mask/Pizza-Print-by-expatriot/52943804.9G0D8",
                image:
                    "https://ih1.redbubble.net/image.1435488404.3804/ur,mask_flatlay_front,product,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mask/Pizza-Print-by-expatriot/52943804.5J1F6",
                image:
                    "https://ih1.redbubble.net/image.1435488404.3804/ur,mask_flatlay_front,product,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mask/Pizza-Print-by-expatriot/52943804.F8Y4C",
                image:
                    "https://ih1.redbubble.net/image.1435488404.3804/ur,kids_mask_7x3_flatlay_front,product,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pin/Pizza-Print-by-expatriot/52943804.NP9QY",
                image:
                    "https://ih1.redbubble.net/image.1435488288.3804/ur,pin_large_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/scarf/Pizza-Print-by-expatriot/52943804.B15PI",
                image:
                    "https://ih1.redbubble.net/image.1435488586.3804/scarf,x1050-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/socks/Pizza-Print-by-expatriot/52943804.9HZ1B",
                image:
                    "https://ih1.redbubble.net/image.1435488643.3804/ur,socks_flatlay_medium,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizza-Print-by-expatriot/52943804.W3OIY",
                image:
                    "https://ih1.redbubble.net/image.1435488382.3804/mug,tall,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizza-Print-by-expatriot/52943804.V33QC",
                image:
                    "https://ih1.redbubble.net/image.1435488422.3804/mug,travel,x1000,center-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/water-bottle/Pizza-Print-by-expatriot/52943804.Y6CDO",
                image:
                    "https://ih1.redbubble.net/image.1435489125.3804/ur,water_bottle_metal_lid_on,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pouch/Pizza-Print-by-expatriot/52943804.440R3",
                image:
                    "https://ih1.redbubble.net/image.1435488481.3804/pr,150x100,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/greeting-card/Pizza-Print-by-expatriot/52943804.5MT14",
                image:
                    "https://ih1.redbubble.net/image.1435488621.3804/papergc,500x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/Pizza-Print-by-expatriot/52943804.RXH2R",
                image:
                    "https://ih1.redbubble.net/image.1435488334.3804/hj,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/Pizza-Print-by-expatriot/52943804.WX3NH",
                image:
                    "https://ih1.redbubble.net/image.1435488319.3804/sn,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/apron/Pizza-Print-by-expatriot/52943804.6ZXWR",
                image:
                    "https://ih1.redbubble.net/image.1435488532.3804/ur,apron_realistic_flatlay,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/magnet/Pizza-Print-by-expatriot/52943804.TBCTK",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/flat,128x128,075,f-pad,128x128,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/greeting-card/Beach-Sea-and-Mountain-by-expatriot/52077900.5MT14",
                image:
                    "https://ih1.redbubble.net/image.1435724242.8565/flat,128x128,075,f-pad,128x128,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/photographic-print/Beach-Sea-and-Mountain-by-expatriot/52077900.6Q0TX",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/pp,840x830-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-skin/Beach-Sea-and-Mountain-by-expatriot/52077900.6EA4Y",
                image:
                    "https://ih1.redbubble.net/image.1392608301.7900/pd,x750,macbook_air_13-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-case/Beach-Sea-and-Mountain-by-expatriot/52077900.2U5KG",
                image:
                    "https://ih1.redbubble.net/image.1392608301.7900/ls,13inch,x1000-c,90,0,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-skin/Beach-Sea-and-Mountain-by-expatriot/52077900.MHP6F",
                image:
                    "https://ih1.redbubble.net/image.1392608302.7900/mwo,x1000,ipad_2_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-case/Beach-Sea-and-Mountain-by-expatriot/52077900.MNKGF",
                image:
                    "https://ih1.redbubble.net/image.1392608302.7900/mwo,x1000,ipad_2_snap-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-skin/Beach-Sea-and-Mountain-by-expatriot/52077900.62NBT",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/mwo,x1000,iphone_6_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Beach-Sea-and-Mountain-by-expatriot/52077900.C0UE4",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/icr,iphone_12_snap,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Beach-Sea-and-Mountain-by-expatriot/52077900.PGM2E",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/icr,iphone_12_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Beach-Sea-and-Mountain-by-expatriot/52077900.23I0V",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/icr,iphone_12_tough,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Beach-Sea-and-Mountain-by-expatriot/52077900.TALA6",
                image:
                    "https://ih1.redbubble.net/image.1392608279.7900/wallet,1000x,iphone_6s_wallet-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-skin/Beach-Sea-and-Mountain-by-expatriot/52077900.NOJ2U",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/mwo,1000x,samsung_galaxy_s5_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Beach-Sea-and-Mountain-by-expatriot/52077900.Q464T",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/icr,samsung_galaxy_s20_snap,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Beach-Sea-and-Mountain-by-expatriot/52077900.B10ML",
                image:
                    "https://ih1.redbubble.net/image.1392608284.7900/icr,samsung_galaxy_s20_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-board-print/Beach-Sea-and-Mountain-by-expatriot/52077900.ZL3U1",
                image:
                    "https://ih1.redbubble.net/image.1392608287.7900/gbra,7x5,900x900.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-print/Beach-Sea-and-Mountain-by-expatriot/52077900.1G4ZT",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/aps,840x830,small,transparent-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Beach-Sea-and-Mountain-by-expatriot/52077900.5Y5V7",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/mp,840x830,matte,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/framed-print/Beach-Sea-and-Mountain-by-expatriot/52077900.AJ1A3",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/fp,840x830,black,off_white,box20,s,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/metal-print/Beach-Sea-and-Mountain-by-expatriot/52077900.0JXQP",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/mp,840x860,gloss,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/Beach-Sea-and-Mountain-by-expatriot/52077900.V7PMD",
                image:
                    "https://ih1.redbubble.net/image.1392608277.7900/paperpc,750x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/acrylic-block/Beach-Sea-and-Mountain-by-expatriot/52077900.P5BX3",
                image:
                    "https://ih1.redbubble.net/image.1392608308.7900/abf,4x4,x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/coasters/Beach-Sea-and-Mountain-by-expatriot/52077900.E5I3N",
                image:
                    "https://ih1.redbubble.net/image.1392608308.7900/ur,coaster_pack_4_flatlay,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-pillow/Beach-Sea-and-Mountain-by-expatriot/52077900.5X2YF",
                image:
                    "https://ih1.redbubble.net/image.1392608311.7900/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Beach-Sea-and-Mountain-by-expatriot/52077900.PJQVX",
                image:
                    "https://ih1.redbubble.net/image.1392608311.7900/tb,1400x1400,small-c,1,330,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Beach-Sea-and-Mountain-by-expatriot/52077900.P1QBH",
                image:
                    "https://ih1.redbubble.net/image.1392608719.7900/ssrco,tote,cotton,canvas_creme,flatlay,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pin/Beach-Sea-and-Mountain-by-expatriot/52077900.NP9QY",
                image:
                    "https://ih1.redbubble.net/image.1392608234.7900/ur,pin_large_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Beach-Sea-and-Mountain-by-expatriot/52077900.W3OIY",
                image:
                    "https://ih1.redbubble.net/image.1392608256.7900/mug,tall,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Beach-Sea-and-Mountain-by-expatriot/52077900.V33QC",
                image:
                    "https://ih1.redbubble.net/image.1392608298.7900/mug,travel,x1000,center-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/water-bottle/Beach-Sea-and-Mountain-by-expatriot/52077900.Y6CDO",
                image:
                    "https://ih1.redbubble.net/image.1392608754.7900/ur,water_bottle_metal_lid_on,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pouch/Beach-Sea-and-Mountain-by-expatriot/52077900.440R3",
                image:
                    "https://ih1.redbubble.net/image.1392608301.7900/pr,150x100,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/maxwell-by-expatriot/51807840.V7PMD",
                image:
                    "https://ih1.redbubble.net/image.1378992739.7840/paperpc,450x,w,f8f8f8-pad,600x600,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/fun-fact-math-purple-and-blue-by-expatriot/47632902.V7PMD",
                image:
                    "https://ih1.redbubble.net/image.1186535110.2902/paperpc,750x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/I-hate-it-when-this-happens-Minecraft-by-expatriot/52948565.V7PMD",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/Pizzaola-2-by-expatriot/52076469.V7PMD",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/paperpc,750x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/postcard/Pizzaola-1-by-expatriot/52075657.V7PMD",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/duvet-cover/fun-fact-math-purple-and-blue-by-expatriot/47632902.XWIXB",
                image:
                    "https://ih1.redbubble.net/image.1186654981.2902/dc,1000x1000,twin,bed.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-pillow/fun-fact-math-purple-and-blue-by-expatriot/47632902.5X2YF",
                image:
                    "https://ih1.redbubble.net/image.1186535148.2902/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tapestry/fun-fact-math-purple-and-blue-by-expatriot/47632902.PCFHB",
                image:
                    "https://ih1.redbubble.net/image.1187052307.2902/tapestry,1200x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.NL9AC",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,slim_fit_t_shirt,two_model,fafafa:ca443f4786,front,square_three_quarter,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/fun-fact-math-purple-and-blue-by-expatriot/47632902.O16E3",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,mhoodie,mens,oatmeal_heather,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/fun-fact-math-purple-and-blue-by-expatriot/47632902.PJQVX",
                image:
                    "https://ih1.redbubble.net/image.1186535148.2902/tb,1400x1400,small-c,1,330,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.TR8D9",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,raglan,x1950,white_black,front-c,160,90,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.WFLAH",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,classic_tee,two_models,fafafa:ca443f4786,front,square_three_quarter,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/fun-fact-math-purple-and-blue-by-expatriot/47632902.GQV8B",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,lightweight_hoodie,mens,grey_lightweight_hoodie,front,square_three_quarter,x1000-bg,f8f8f8.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.NUC7A",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,lightweight_sweatshirt,mens,heather_grey_lightweight_raglan_sweatshirt,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/long-sleeve-t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.6I7YY",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,longsleeve,x2000,fafafa:ca443f4786,front-c,190,60,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.RY32L",
                image:
                    "https://ih1.redbubble.net/image.1187052276.2902/ssrco,long_t_shirt,mens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.D681C",
                image:
                    "https://ih1.redbubble.net/image.1187052276.2902/ssrco,mens_premium_t_shirt,mens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.73735",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,sweatshirt,x1850,oatmeal_heather,front-c,105,45,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/fun-fact-math-purple-and-blue-by-expatriot/47632902.N283C",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,racerback,womens,fafafa:ca443f4786,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/fun-fact-math-purple-and-blue-by-expatriot/47632902.6AQD3",
                image:
                    "https://ih1.redbubble.net/image.1186535135.2902/ctkr,x2200,front,black-c,250,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/fun-fact-math-purple-and-blue-by-expatriot/47632902.E1H7L",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,tank,x1850,fafafa:ca443f4786,front-c,95,35,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.R05UB",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,triblend_tee,x2150,red_triblend,front-c,242,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.IZGJF",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,vneck,x1900,fafafa:ca443f4786,front-c,160,70,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/fun-fact-math-purple-and-blue-by-expatriot/47632902.R76QN",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ssrco,mhoodiez,mens,oatmeal_heather,front,square_three_quarter,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/fun-fact-math-purple-and-blue-by-expatriot/47632902.V4WQ8",
                image:
                    "https://ih1.redbubble.net/image.1186654978.2902/aldr,x1700,front-c,60,103,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/fun-fact-math-purple-and-blue-by-expatriot/47632902.B7P0O",
                image:
                    "https://ih1.redbubble.net/image.1186535166.2902/ssrco,chiffon_top,womens,black,front,square_three_quarter,x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.PFN84",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,fitted_scoop,x2000,fafafa:ca443f4786,front-c,160,143,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.8PZ5B",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,womens_tshirt,x1900,fafafa:ca443f4786,front-c,140,125,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.UIV3X",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,fitted_v_neck,x1950,fafafa:ca443f4786,front-c,150,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/fun-fact-math-purple-and-blue-by-expatriot/47632902.TGIUM",
                image:
                    "https://ih1.redbubble.net/image.1186654975.2902/gtdr,x1800,front,black-c,130,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/leggings/fun-fact-math-purple-and-blue-by-expatriot/47632902.7DWO7",
                image:
                    "https://ih1.redbubble.net/image.1186654973.2902/ur,leggings_womens_front,square,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/skirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.MUCKM",
                image:
                    "https://ih1.redbubble.net/image.1186535143.2902/pencil_skirt,x1000,front-c,378,0,871,871-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.07KN2",
                image:
                    "https://ih1.redbubble.net/image.1187052276.2902/rco,womens_premium_t_shirt,womens,x1770,fafafa:ca443f4786,front-c,170,40,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/fun-fact-math-purple-and-blue-by-expatriot/47632902.XK1BC",
                image:
                    "https://ih1.redbubble.net/image.1187052130.2902/ra,relaxed_fit,x2000,fafafa:ca443f4786,front-c,170,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-skin/fun-fact-math-purple-and-blue-by-expatriot/47632902.6EA4Y",
                image:
                    "https://ih1.redbubble.net/image.1186654966.2902/pd,x750,macbook_air_13-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.2U5KG",
                image:
                    "https://ih1.redbubble.net/image.1186654966.2902/ls,13inch,x1000-c,90,0,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-skin/fun-fact-math-purple-and-blue-by-expatriot/47632902.MHP6F",
                image:
                    "https://ih1.redbubble.net/image.1186535118.2902/mwo,x1000,ipad_2_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.MNKGF",
                image:
                    "https://ih1.redbubble.net/image.1186535118.2902/mwo,x1000,ipad_2_snap-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-skin/fun-fact-math-purple-and-blue-by-expatriot/47632902.62NBT",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/mwo,x1000,iphone_6_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.C0UE4",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/icr,iphone_12_snap,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.PGM2E",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/icr,iphone_12_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.23I0V",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/icr,iphone_12_tough,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.TALA6",
                image:
                    "https://ih1.redbubble.net/image.1186535472.2902/wallet,1000x,iphone_6s_wallet-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-skin/fun-fact-math-purple-and-blue-by-expatriot/47632902.NOJ2U",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/mwo,1000x,samsung_galaxy_s5_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.Q464T",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/icr,samsung_galaxy_s20_snap,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.B10ML",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/icr,samsung_galaxy_s20_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/fun-fact-math-purple-and-blue-by-expatriot/47632902.S4BK2",
                image:
                    "https://ih1.redbubble.net/image.1186654912.2902/icr,samsung_galaxy_s20_tough,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-board-print/fun-fact-math-purple-and-blue-by-expatriot/47632902.5E8EA",
                image:
                    "https://ih1.redbubble.net/image.1186535112.2902/gbra,6x4,900x900.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/fun-fact-math-purple-and-blue-by-expatriot/47632902.56DNM",
                image:
                    "https://ih1.redbubble.net/image.1186655037.2902/ur,mounted_print_canvas_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/wood-print/fun-fact-math-purple-and-blue-by-expatriot/47632902.EZ4MB",
                image:
                    "https://ih1.redbubble.net/image.1186655037.2902/ur,mounted_print_wood_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/acrylic-block/fun-fact-math-purple-and-blue-by-expatriot/47632902.P5BX3",
                image:
                    "https://ih1.redbubble.net/image.1186535240.2902/abf,4x4,x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/bath-mat/fun-fact-math-purple-and-blue-by-expatriot/47632902.EVFTZ",
                image:
                    "https://ih1.redbubble.net/image.1186655014.2902/ur,bathmat_flatlay_small,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/coasters/fun-fact-math-purple-and-blue-by-expatriot/47632902.E5I3N",
                image:
                    "https://ih1.redbubble.net/image.1186535240.2902/ur,coaster_pack_4_flatlay,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/comforter/fun-fact-math-purple-and-blue-by-expatriot/47632902.8IC4O",
                image:
                    "https://ih1.redbubble.net/image.1186654981.2902/ur,comforter_top_king,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/floor-pillow/fun-fact-math-purple-and-blue-by-expatriot/47632902.TMTL5",
                image:
                    "https://ih1.redbubble.net/image.1186654961.2902/throwpillow,36x36,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/shower-curtain/fun-fact-math-purple-and-blue-by-expatriot/47632902.YH6LW",
                image:
                    "https://ih1.redbubble.net/image.1186654981.2902/ur,shower_curtain_closed,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/fun-fact-math-purple-and-blue-by-expatriot/47632902.16D0B",
                image:
                    "https://ih1.redbubble.net/image.1187052307.2902/ur,blanket_medium_bed,square,x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/drawstring-bag/fun-fact-math-purple-and-blue-by-expatriot/47632902.YT4RK",
                image:
                    "https://ih1.redbubble.net/image.1186654965.2902/drawstring_bag,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/fun-fact-math-purple-and-blue-by-expatriot/47632902.P1QBH",
                image:
                    "https://ih1.redbubble.net/image.1186654974.2902/ssrco,tote,cotton,canvas_creme,flatlay,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mask/fun-fact-math-purple-and-blue-by-expatriot/47632902.9G0D8",
                image:
                    "https://ih1.redbubble.net/image.1186535287.2902/ur,mask_flatlay_front,product,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mask/fun-fact-math-purple-and-blue-by-expatriot/47632902.5J1F6",
                image:
                    "https://ih1.redbubble.net/image.1186535287.2902/ur,mask_flatlay_front,product,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mask/fun-fact-math-purple-and-blue-by-expatriot/47632902.F8Y4C",
                image:
                    "https://ih1.redbubble.net/image.1186535287.2902/ur,kids_mask_7x3_flatlay_front,product,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pin/fun-fact-math-purple-and-blue-by-expatriot/47632902.NP9QY",
                image:
                    "https://ih1.redbubble.net/image.1186654901.2902/ur,pin_large_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/scarf/fun-fact-math-purple-and-blue-by-expatriot/47632902.B15PI",
                image:
                    "https://ih1.redbubble.net/image.1187052234.2902/scarf,x1050-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/socks/fun-fact-math-purple-and-blue-by-expatriot/47632902.9HZ1B",
                image:
                    "https://ih1.redbubble.net/image.1186655013.2902/ur,socks_flatlay_medium,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/fun-fact-math-purple-and-blue-by-expatriot/47632902.W3OIY",
                image:
                    "https://ih1.redbubble.net/image.1186535209.2902/mug,tall,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/fun-fact-math-purple-and-blue-by-expatriot/47632902.V33QC",
                image:
                    "https://ih1.redbubble.net/image.1186535211.2902/mug,travel,x1000,center-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/water-bottle/fun-fact-math-purple-and-blue-by-expatriot/47632902.Y6CDO",
                image:
                    "https://ih1.redbubble.net/image.1186654984.2902/ur,water_bottle_metal_lid_on,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pouch/fun-fact-math-purple-and-blue-by-expatriot/47632902.440R3",
                image:
                    "https://ih1.redbubble.net/image.1186654966.2902/pr,150x100,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/greeting-card/fun-fact-math-purple-and-blue-by-expatriot/47632902.5MT14",
                image:
                    "https://ih1.redbubble.net/image.1186535110.2902/papergc,500x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/fun-fact-math-purple-and-blue-by-expatriot/47632902.RXH2R",
                image:
                    "https://ih1.redbubble.net/image.1186654953.2902/hj,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/fun-fact-math-purple-and-blue-by-expatriot/47632902.WX3NH",
                image:
                    "https://ih1.redbubble.net/image.1392535796.6469/flat,128x128,075,f-pad,128x128,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/I-hate-it-when-this-happens-Minecraft-by-expatriot/52948565.16D0B",
                image:
                    "https://ih1.redbubble.net/image.1435724242.8565/ur,blanket_medium_bed,square,x600.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/maxwell-by-expatriot/51807840.16D0B",
                image:
                    "https://ih1.redbubble.net/image.1378992756.7840/ur,blanket_medium_bed,square,x600.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/Each-of-us-is-a-minority-of-one-by-expatriot/49450095.16D0B",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/Pizzaola-1-by-expatriot/52075657.16D0B",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/Pizzaola-2-by-expatriot/52076469.WX3NH",
                image:
                    "https://ih1.redbubble.net/image.1392535656.6469/sn,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Pizzaola-2-by-expatriot/52076469.EJUG5",
                image:
                    "https://ih1.redbubble.net/image.1392536172.6469/st,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.NL9AC",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Pizzaola-2-by-expatriot/52076469.PJQVX",
                image:
                    "https://ih1.redbubble.net/image.1392535684.6469/tb,1400x1400,small-c,1,330,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.WFLAH",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.UGYPM",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.TR8D9",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.1YYVU",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Pizzaola-2-by-expatriot/52076469.GQV8B",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/Pizzaola-2-by-expatriot/52076469.NUC7A",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/long-sleeve-t-shirt/Pizzaola-2-by-expatriot/52076469.6I7YY",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.RY32L",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.D681C",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Pizzaola-2-by-expatriot/52076469.O16E3",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/sweatshirt/Pizzaola-2-by-expatriot/52076469.73735",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/Pizzaola-2-by-expatriot/52076469.N283C",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/Pizzaola-2-by-expatriot/52076469.6AQD3",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/tank-top/Pizzaola-2-by-expatriot/52076469.E1H7L",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.R05UB",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.IZGJF",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/hoodie/Pizzaola-2-by-expatriot/52076469.R76QN",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/Pizzaola-2-by-expatriot/52076469.V4WQ8",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/top/Pizzaola-2-by-expatriot/52076469.B7P0O",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.PFN84",
                image: "",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.UIV3X",
                image:
                    "https://ih1.redbubble.net/image.1392536216.6469/ra,fitted_v_neck,x1950,fafafa:ca443f4786,front-c,150,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/dress/Pizzaola-2-by-expatriot/52076469.TGIUM",
                image:
                    "https://ih1.redbubble.net/image.1392535777.6469/gtdr,x1800,front,black-c,130,133,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.07KN2",
                image:
                    "https://ih1.redbubble.net/image.1392536076.6469/rco,womens_premium_t_shirt,womens,x1770,fafafa:ca443f4786,front-c,170,40,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/t-shirt/Pizzaola-2-by-expatriot/52076469.XK1BC",
                image:
                    "https://ih1.redbubble.net/image.1392536216.6469/ra,relaxed_fit,x2000,fafafa:ca443f4786,front-c,170,163,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Pizzaola-2-by-expatriot/52076469.JCQM3",
                image:
                    "https://ih1.redbubble.net/image.1392536172.6469/st,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-skin/Pizzaola-2-by-expatriot/52076469.6EA4Y",
                image:
                    "https://ih1.redbubble.net/image.1392535746.6469/pd,x750,macbook_air_13-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/laptop-case/Pizzaola-2-by-expatriot/52076469.2U5KG",
                image:
                    "https://ih1.redbubble.net/image.1392535746.6469/ls,13inch,x1000-c,90,0,1000,1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/sticker/Pizzaola-2-by-expatriot/52076469.O9UDB",
                image:
                    "https://ih1.redbubble.net/image.1392536172.6469/tst,small,845x845-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-skin/Pizzaola-2-by-expatriot/52076469.MHP6F",
                image:
                    "https://ih1.redbubble.net/image.1392535640.6469/mwo,x1000,ipad_2_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/ipad-case/Pizzaola-2-by-expatriot/52076469.MNKGF",
                image:
                    "https://ih1.redbubble.net/image.1392535640.6469/mwo,x1000,ipad_2_snap-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-skin/Pizzaola-2-by-expatriot/52076469.62NBT",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/mwo,x1000,iphone_6_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizzaola-2-by-expatriot/52076469.C0UE4",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/icr,iphone_12_snap,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizzaola-2-by-expatriot/52076469.PGM2E",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/icr,iphone_12_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizzaola-2-by-expatriot/52076469.23I0V",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/icr,iphone_12_tough,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/iphone-case/Pizzaola-2-by-expatriot/52076469.TALA6",
                image:
                    "https://ih1.redbubble.net/image.1392535546.6469/wallet,1000x,iphone_6s_wallet-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-skin/Pizzaola-2-by-expatriot/52076469.NOJ2U",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/mwo,1000x,samsung_galaxy_s5_skin-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Pizzaola-2-by-expatriot/52076469.Q464T",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/icr,samsung_galaxy_s20_snap,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Pizzaola-2-by-expatriot/52076469.B10ML",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/icr,samsung_galaxy_s20_soft,back,a,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/samsung-case/Pizzaola-2-by-expatriot/52076469.S4BK2",
                image:
                    "https://ih1.redbubble.net/image.1392535625.6469/icr,samsung_galaxy_s20_tough,back,a,x1000-pad,1000x1000,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-board-print/Pizzaola-2-by-expatriot/52076469.8DB3C",
                image:
                    "https://ih1.redbubble.net/image.1392535818.6469/gbra,10x8,900x900.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/art-print/Pizzaola-2-by-expatriot/52076469.1G4ZT",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/aps,840x830,small,transparent-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Pizzaola-2-by-expatriot/52076469.56DNM",
                image:
                    "https://ih1.redbubble.net/image.1392535847.6469/ur,mounted_print_canvas_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/canvas-print/Pizzaola-2-by-expatriot/52076469.5Y5V7",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/mp,840x830,matte,f8f8f8,t-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/framed-print/Pizzaola-2-by-expatriot/52076469.AJ1A3",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/fp,840x830,black,off_white,box20,s,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/photographic-print/Pizzaola-2-by-expatriot/52076469.6Q0TX",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/pp,840x830-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/poster/Pizzaola-2-by-expatriot/52076469.LVTDI",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/poster,840x830,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tapestry/Pizzaola-2-by-expatriot/52076469.PCFHB",
                image:
                    "https://ih1.redbubble.net/image.1392536024.6469/tapestry,1200x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/wood-print/Pizzaola-2-by-expatriot/52076469.EZ4MB",
                image:
                    "https://ih1.redbubble.net/image.1392535847.6469/ur,mounted_print_wood_portrait_small_front,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/acrylic-block/Pizzaola-2-by-expatriot/52076469.P5BX3",
                image:
                    "https://ih1.redbubble.net/image.1392535713.6469/abf,4x4,x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/clock/Pizzaola-2-by-expatriot/52076469.1X49C",
                image:
                    "https://ih1.redbubble.net/image.1392535606.6469/clkf,bamboo,black,1000x1000-bg,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/coasters/Pizzaola-2-by-expatriot/52076469.E5I3N",
                image:
                    "https://ih1.redbubble.net/image.1392535713.6469/ur,coaster_pack_4_flatlay,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/comforter/Pizzaola-2-by-expatriot/52076469.8IC4O",
                image:
                    "https://ih1.redbubble.net/image.1392535782.6469/ur,comforter_top_king,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/duvet-cover/Pizzaola-2-by-expatriot/52076469.XWIXB",
                image:
                    "https://ih1.redbubble.net/image.1392535782.6469/dc,1000x1000,twin,bed.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/floor-pillow/Pizzaola-2-by-expatriot/52076469.TMTL5",
                image:
                    "https://ih1.redbubble.net/image.1392535804.6469/throwpillow,36x36,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/shower-curtain/Pizzaola-2-by-expatriot/52076469.YH6LW",
                image:
                    "https://ih1.redbubble.net/image.1392535782.6469/ur,shower_curtain_closed,square,1000x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-blanket/Pizzaola-2-by-expatriot/52076469.16D0B",
                image:
                    "https://ih1.redbubble.net/image.1392536024.6469/ur,blanket_medium_bed,square,x1000.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/throw-pillow/Pizzaola-2-by-expatriot/52076469.5X2YF",
                image:
                    "https://ih1.redbubble.net/image.1392535684.6469/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/drawstring-bag/Pizzaola-2-by-expatriot/52076469.YT4RK",
                image:
                    "https://ih1.redbubble.net/image.1392535616.6469/drawstring_bag,x1000-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/backpack/Pizzaola-2-by-expatriot/52076469.K1KHE",
                image:
                    "https://ih1.redbubble.net/image.1392535687.6469/ur,backpack_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/tote-bag/Pizzaola-2-by-expatriot/52076469.P1QBH",
                image:
                    "https://ih1.redbubble.net/image.1392535988.6469/ssrco,tote,cotton,canvas_creme,flatlay,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pin/Pizzaola-2-by-expatriot/52076469.NP9QY",
                image:
                    "https://ih1.redbubble.net/image.1392535497.6469/ur,pin_large_front,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/scarf/Pizzaola-2-by-expatriot/52076469.B15PI",
                image:
                    "https://ih1.redbubble.net/image.1392535856.6469/scarf,x1050-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/socks/Pizzaola-2-by-expatriot/52076469.9HZ1B",
                image:
                    "https://ih1.redbubble.net/image.1392535891.6469/ur,socks_flatlay_medium,square,1000x1000-bg,f8f8f8.1.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizzaola-2-by-expatriot/52076469.W3OIY",
                image:
                    "https://ih1.redbubble.net/image.1392536016.6469/mug,tall,x1000,right-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/mug/Pizzaola-2-by-expatriot/52076469.V33QC",
                image:
                    "https://ih1.redbubble.net/image.1392535651.6469/mug,travel,x1000,center-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/water-bottle/Pizzaola-2-by-expatriot/52076469.Y6CDO",
                image:
                    "https://ih1.redbubble.net/image.1392535776.6469/ur,water_bottle_metal_lid_on,square,1000x1000.2.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/pouch/Pizzaola-2-by-expatriot/52076469.440R3",
                image:
                    "https://ih1.redbubble.net/image.1392535746.6469/pr,150x100,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/greeting-card/Pizzaola-2-by-expatriot/52076469.5MT14",
                image:
                    "https://ih1.redbubble.net/image.1392535843.6469/papergc,500x,w,f8f8f8-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/notebook/Pizzaola-2-by-expatriot/52076469.RXH2R",
                image:
                    "https://ih1.redbubble.net/image.1392535796.6469/hj,1000x-pad,1000x1000,f8f8f8.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/apron/Pizzaola-2-by-expatriot/52076469.6ZXWR",
                image:
                    "https://ih1.redbubble.net/image.1392535771.6469/ur,apron_realistic_flatlay,square,1000x1000.jpg",
            },
            {
                location:
                    "https://www.redbubble.com/i/magnet/Pizzaola-2-by-expatriot/52076469.TBCTK",
                image:
                    "https://ih1.redbubble.net/image.1392536172.6469/mo,small,flatlay,product_square,1000x1000.jpg",
            },
        ];

        for (let i = 0; selected_products.size < image_max; ++i) {
            const el =
                all_products[Math.trunc(Math.random() * all_products.length)];
            if (el.image) {
                selected_products.add(el);
            }
        }
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        selected_products.add(
            all_products[Math.trunc(Math.random() * all_products.length)]
        );
        /// convert to an array:
        let selected_products_array: Array<ProductType> = Array.from(
            selected_products
        );
        this.state = { products: selected_products_array };
    }

    render() {
        return (
            <div id="AdColumn">
                {this.state.products.map((x) => (
                    <div className="ProductBox" key={x.location}>
                        <a target="product" href={x.location}>
                            <img src={x.image} />
                        </a>
                    </div>
                ))}
            </div>
        );
    }
}

export default RedBubbleAd;
