import React, { MouseEvent, ChangeEvent } from "react";
import { PermissionSet } from "./PermissionSet";

export function AuthorizedAccountsComponent(props: {
    blockchain: string;
    username: string;
    permissionSet: PermissionSet;
    onRevoke: any;
    aspect: string;
    setURL: (url: string, title: string) => void;
}) {
    try {
        let authrole: string;
        if (props.aspect === "owner") {
            authrole = "owner";
        } else {
            authrole = "active";
        }
        return (
            <div>
                <div>
                    {props.permissionSet[props.aspect].account_auths.map(
                        (feduciary_weight_pair) => (
                            <div key={feduciary_weight_pair[0]}>
                                <span className="link" onClick={(ev) => {
                                     props.setURL(`/@${feduciary_weight_pair[0]}/permissions`, `Permissions for @${feduciary_weight_pair[0]}`);
                                                                        }} >@{feduciary_weight_pair[0]}</span>
                                <button
                                    onClick={props.onRevoke.bind(
                                        props.blockchain,
                                        [
                                            props.username,
                                            feduciary_weight_pair[0],
                                            props.aspect,
                                            authrole,
                                        ]
                                    )}
                                >
                                    Revoke Permission
                                </button>
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    } catch (e) {
        return <div>{JSON.stringify(props)}</div>;
    }
}

export default AuthorizedAccountsComponent;
