import React from "react";
import { post } from "./post";
import {
    APIForBlockchain,
    keychainForBlockchain,
    supportedBlockchains,
} from "./blockchains";
import steem from "steem";
import hive from "@hiveio/hive-js";
import blurt from "@blurtfoundation/blurtjs";
import whaleshares from "@whaleshares/wlsjs";

import "./App.css";
import "./PostComposition.css";
import account from "./account";
import util from "util";

function KeyChainRequestPostAsync(
    blockchain: string,
    key: string | null,
    parent_author: string,
    parent_permlink: string,
    post_author: string,
    post_permlink: string,
    title: string,
    body: string,
    JSONMetaData: string | object
) {
    return new Promise((resolve, reject) => {
        console.log("Promise made");

        let dontbother = false;
        const timeoutId = setTimeout(function () {
            console.log("promise timeout");
            dontbother = true;
            reject("promise timeout broken");
        }, 35000);

        if (typeof JSONMetaData == "object") {
            JSONMetaData = JSON.stringify(JSONMetaData);
        }

        console.log("arguments:", {
            key,
            parent_author,
            parent_permlink,
            post_author,
            post_permlink,
            title,
            body,
            JSONMetaData,
        });

        let keychain: any;
        if (blockchain === null) reject("blockchain not specified");
        else if ((keychain = window[blockchain + "_keychain"]))
            keychain.requestPost(
                post_author,
                title,
                body,
                parent_permlink,
                parent_author,
                JSONMetaData,
                /* permlink */ post_permlink,
                "", //'{"author": "leprechaun", "permlink": "' +
                //    post_permlink +
                //    '", "percent_steem_dollars": 10000, "percent_hbd": 10000, "max_accepted_payout": 1000, "allow_votes": true, "allow_curation_rewards": true, "extensions": {}}',
                function (arg) {
                    clearInterval(timeoutId);
                    if (dontbother) return;
                    if (arg["success"]) {
                        console.log("promise keeping");
                        resolve(arg["result"]);
                    } else {
                        console.log("promise breaking");
                        console.log(arg);
                        reject(arg["message"]);
                    }
                }
            );
        else reject(`${blockchain} Keychain not ready`);
    }); // Promsie
} // callback

function HiveKeyChainRequestPostAsync(
    key: string | null,
    parent_author: string,
    parent_permlink: string,
    post_author: string,
    post_permlink: string,
    title: string,
    body: string,
    JSONMetaData: string | object
) {
    return KeyChainRequestPostAsync(
        "hive",
        key,
        parent_author,
        parent_permlink,
        post_author,
        post_permlink,
        title,
        body,
        JSONMetaData
    );
}

function SteemKeyChainRequestPostAsync(
    key: string | null,
    parent_author: string,
    parent_permlink: string,
    post_author: string,
    post_permlink: string,
    title: string,
    body: string,
    JSONMetaData: string | object
) {
    return KeyChainRequestPostAsync(
        "steem",
        key,
        parent_author,
        parent_permlink,
        post_author,
        post_permlink,
        title,
        body,
        JSONMetaData
    );
}

function onLanguageDetected(langInfo) {
    for (const lang of langInfo.languages) {
        console.log("Language is: " + lang.language);
        console.log("Percentage is: " + lang.percentage);
    }
}

interface Props {
    parent_author: string;
    parent_permlink: string;
    blockchains: Array<string>;
    accounts: Array<account>;
    adoptParent: (id: post) => void;
}

interface State {
    commonChains: Set<string>;
    posting: Set<string>;
    error: string;
}

function intersection(setA, setB) {
    let _intersection = new Set();
    for (let elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
}

class PostCompositionComponent extends React.Component<Props, State> {
    private textAreaRef = React.createRef<HTMLTextAreaElement>();

    constructor(props) {
        super(props);
        const chains_logged_into = new Set<string>(
            this.props.accounts.map((a) => a.provider)
        );
        const chains_this_post_belongs_to = new Set<string>(
            this.props.blockchains
        );
        const common_chains: Set<string> = intersection(
            chains_logged_into,
            chains_this_post_belongs_to
        ) as Set<string>;

        this.state = {
            commonChains: common_chains,
            posting: new Set<string>([]),
            error: "",
        };
    }

    clearPostingFlag(blockchain: string, error_message: string | undefined) {
        let posting = new Set<string>(this.state.posting);
        posting.delete(blockchain);
        let other_state: any;
        if (error_message) {
            other_state = { posting: posting, error: error_message };
        } else {
            other_state = { posting: posting };
        }
        this.setState(other_state);
    }

    onPostButtonClick(e) {
        const parentAuthor = this.props.parent_author;
        const parentPermlink = this.props.parent_permlink;
        const postButton: HTMLButtonElement = e.target as HTMLButtonElement;
        const textArea: HTMLTextAreaElement | null = this.textAreaRef.current;

        const textEntered: string | null =
            textArea !== null ? textArea.value : "";
        const title = "";
        const metadata = { format: "markdown", App: "Steemfiles" };
        let post_attempt_count: number = 0;
        console.log(this.props.accounts);
        if (!this.props.accounts) {
            console.log("!Severe error: accounts property is falsey");
            return;
        }
        let not_yet_posted: boolean = true;
        const parentProps = this.props;
        const JSONMetaData = JSON.stringify(metadata);
        const adoptParent = this.props.adoptParent;
        let blockchains_to_post_to: Array<string> = [];
        let new_comment: any = null;
        let promises: Array<Promise<boolean>> = [];
        for (const account of this.props.accounts) {
            const {keyStorageLocation, provider} = account;
            const blockchain = provider;
            const permlink = APIForBlockchain(
                blockchain
            ).formatter.commentPermlink(parentAuthor, parentPermlink);
            const key: string | null = sessionStorage.getItem(
                blockchain + "-private-key"
            );
            const id: string = account.username;
            if (!id) {
                if (!id) console.log("no id for " + blockchain);
                if (!key && !id) alert("No key and no id for " + blockchain);
                else alert("No id for " + blockchain);
                return;
            }
            if (account.role === "memo") {
                // cannot post with the memo key;
                console.log("Cannot post with the memo key on " + blockchain);
                continue;
            }

            // make sure the parent exists on the provider chain.
            if (!this.props.blockchains.includes(provider)) continue;

            const created = new Date();
            const last_payout = new Date(1969, 12, 31, 23, 59, 59);

            const cashout_time = new Date(created).setTime(
                created.getTime() + 1000 * 60 * 60 * 24 * 7
            );
            blockchains_to_post_to.push(blockchain);
            new_comment = {
                active_votes: [],
                author_reputation: 344085733826512,
                author: account.username,
                blockchains: blockchains_to_post_to,
                body: textEntered,
                body_length: textEntered.length,
                cashout_time: cashout_time,
                children: 0,
                created: created,
                curator_payout_value: "0.000 SBD",

                json_metadata: JSONMetaData,
                last_payout,
                last_update: created,
                max_accepted_payout: "1000000.000 SBD",
                net_rshares: 10731873094383,
                parent_author: "",
                parent_permlink: "",
                pending_payout_value: "0.000 SBD",
                percent_steem_dollars: 10000,
                post_id: 89562771,
                promoted: "0.000 SBD",
                replies: [],
                root_title: "",
                title: "",
                total_payout_value: "0.000 SBD",

                permlink: permlink,
                url: permlink,
                beneficiaries: [],
            };

            const clearPostingFlag = this.clearPostingFlag.bind(
                this,
                blockchain
            );
            if (keyStorageLocation === 'whalevault') {
              console.log({"error":"whalevault commenting is not implemented", in_progress: false});
              return;
            }

            const commentAsync = (()=> {
                if (keyStorageLocation === 'sessionStorage') {
                 return APIForBlockchain(blockchain).broadcast.commentAsync;
                } else if (keyStorageLocation === 'keychain') {
                  return blockchain === "hive" ? HiveKeyChainRequestPostAsync
                  : blockchain === "steem" ? SteemKeyChainRequestPostAsync
                  : null;
                } else {// if (keyStorageLocation === 'whalevault') {
                  return null;
                }
            })(); // anonymous fn

            const setError = (error) => {
                this.setState({ error });
            };
            console.log(blockchain, commentAsync);
            if (commentAsync)
                promises.push(
                    commentAsync(
                        /*wif*/ key,
                        this.props.parent_author,
                        this.props.parent_permlink,
                        /*author*/ account.username,
                        permlink,
                        title,
                        /*body*/ textEntered,
                        JSONMetaData
                    )
                        .then(
                            (x) => {
                                clearPostingFlag("");
                                console.log("promise kept");
                                console.log(x);
                                return true;
                            },
                            function (error: string) {
                                clearPostingFlag(error);
                                console.log("promise broken", error);
                                return false;
                            }
                        )
                        .catch((x) => {
                            clearPostingFlag(JSON.stringify(x));
                            console.log("exception thrown", x);
                            return false;
                        })
                );
                else {
                  setError("whalevault commenting is not implemented");
                }
        } // for

        this.setState({ posting: new Set<string>(blockchains_to_post_to) });


        Promise.all(promises)
            .then(function (results: boolean[]) {
                console.log("all:", results);
                console.log(
                    "Was one true?:",
                    results.find((x) => x)
                );
                if (results.find((x) => x)) {
                    // clear this.
                    if (textArea) textArea.value = "";

                    adoptParent(new_comment);
                } else {
                  console.log("Couldn't comment");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {}

    render() {
        //console.log('common chains:', this.state.commonChains.size);
        const {commonChains} = this.state;
        const {accounts} = this.props;
        const enabled = (accounts.filter( account => (commonChains.has(account.provider) && account.keyStorageLocation !== 'whalevault'))).length !== 0;

        return (
            <div className="App">
                {this.state.posting.size === 0 ? (
                    <div>
                        <textarea
                            disabled={!enabled}
                            ref={this.textAreaRef}
                            spellCheck
                            wrap={"wrap"}
                            placeholder={enabled ? 'type your comment here' : 'login to the blockchains via posting key or keychain to comment'}
                        />

                        <button disabled={!enabled} onClick={this.onPostButtonClick.bind(this)}>
                            Post Reply to @{this.props.parent_author} on{" "}
                            {this.props.blockchains.join(", ")}
                        </button>
                    </div>
                ) : (
                    <div>
                        Posting to {Array.from(this.state.posting).join(", ")}
                        ...
                    </div>
                )}
            </div>
        );
    }
}

export default PostCompositionComponent;
